.material-builder-modal-content {
  display: flex;
  width: 100%;
  height: 73.4vh;
  overflow: hidden;
  flex-wrap: nowrap;
}

.material-builder-modal-sphere {
  width: 60%;
  flex: initial;
  border-right: 1px solid var(--lightBorderColor);
  position: relative;
  border-top: 1px solid var(--lightBorderColor);
}

.material-builder-modal-sphere canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  outline: none;
  cursor: default !important;
}

.material-builder-modal-interface {
  width: 40%;
  flex: initial;
  position: relative;
  height: 100%;
}

.material-builder-modal-interface:only-child {
  width: 100%;
}