.notifications-page ul {
  margin: 20px 0 0;
}

.notifications-page ul li:hover {
  cursor: default;
  background-color: inherit;
}

.notifications-page ul li .col h4 {
  color: var(--textColor);
}

.notifications-page ul li .primary-btn, .notifications-page ul li .outline-btn {
  padding: 6px 8px;
  font-size: .85rem;
  white-space: nowrap;
  margin: 0;
}

.notifications-page ul li .col:last-of-type {
  flex: 0.4 1;
}

.notifications-page ul li .col h5 {
  display: flex;
  gap: 4px;
  align-items: center;
}

.notifications-page ul li .col .has-notifications {
  background-color: var(--primaryColor);
  display: block;
  height: 6px !important;
  width: 6px !important;
  border-radius: 100%;
}