.plan-limit-component {
  background-color: var(--primaryColor);
  color: #fff;
}

.plan-limit-component-content {
  max-width: 1600px;
  margin: 0 auto;
  text-align: center;
  padding: 10px 0;
}

.plan-limit-component-content a {
  text-decoration: underline;
  font-weight: 500;
}