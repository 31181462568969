.toggle-material-search {
  position: relative;
}

.hidden-input-label {
  background: var(--backgroundColor);
  border-radius: 8px;
  padding: var(--quarterPadding);
  margin: var(--standardPadding) 0 0;
  display: inline-block;
}

.hidden-input-label:hover {
  cursor: pointer;
  opacity: 0.8;
}

.uploader-image-search {
  text-align: center;
  border: 1px dashed var(--brandPrimaryColor);
  padding: var(--doublePadding);
  border-radius: 10px;
}

.uploader-image-search input {
  display: none;
}

.uploader-image-search svg {
  display: inline;
}

.after-upload-image-container {
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: var(--standardPadding);
  justify-content: space-evenly;
  padding: 0 0 var(--standardPadding);
}

.after-upload-image-container .left {
  display: flex;
  border: 1px solid var(--lightBorderColor);
  border-radius: 8px;
  position: relative;
  flex: initial;
  width: 50%;
  padding: var(--halfPadding);
}

.after-upload-image-container .left img {
  width: 100%;
}

.after-upload-image-container .reset-upload-button {
  position: absolute;
  right: -7px;
  top: -7px;
  cursor: pointer;
}

.after-upload-image-container .right .hidden-input-label {
  margin: var(--quarterPadding) 0 0;
}