
.toggle-model-import .toggle-tab-bar {
    margin: 0 0 var(--halfPadding);
}

.toggle-content-list li {
    align-items: stretch;
    padding: calc(var(--quarterPadding)*1.5) var(--halfPadding);
}

.toggle-new-import {
    display: flex;
    flex-direction: column;
    gap: var(--halfPadding);
    margin: var(--standardPadding) 0 0;
}

.toggle-existing-import {
    margin: var(--standardPadding) 0 0;
}

.toggle-model-import.loading {
    pointer-events: none;
    opacity: .5;
}