.toggle-admin-list {
  background-color: var(--pageColor);
  border-radius: 6px;
}

.toggle-admin-list li {
  display: flex;
  gap: 5px;
  border-bottom: 1px solid var(--lightBorderColor);
  color: var(--lighterTextColor);
  align-items: center;
  flex: 1;
  border-radius: 6px;
}

.toggle-admin-list li.selected {
  border: 1px solid var(--primaryColor);
}

.toggle-admin-list li:hover {
  cursor: pointer;
  background-color: var(--lightBackgroundColor);
}

.toggle-admin-list li .col {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.toggle-admin-list li .col:nth-of-type(1) {
  flex: 1;
}

.toggle-admin-list li .col:nth-of-type(2) {
  flex: 1;
}

.toggle-admin-list li .col:nth-of-type(3) {
  flex: 0.5;
}

.toggle-admin-list li .col:nth-of-type(4) {
  flex: 0.25;
}

.toggle-admin-list li .col h4 {
  font-size: .9rem;
  font-weight: 400;
  color: var(--lighterTextColor);
}

.toggle-admin-list li .col h5 {
  font-size: .8rem;
  font-weight: 400;
  color: var(--lightestTextColor);
}

.admin-info-tag-set {
  display: flex;
  align-items: center;
  gap: 5px;
}

.admin-info-tag {
  font-size: .8rem;
  color: var(--textColor);
  background-color: var(--backgroundColor);
  border-radius: 12px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.admin-info-tag.invert {
  background-color: transparent;
  border: 1px solid var(--darkerBackgroundColor);
  color: var(--lighterTextColor);
}

.admin-info-tag.green {
  border: 1px solid rgb(0, 173, 95);
  background-color: rgb(168, 251, 192);
}

.admin-info-tag.red {
  border: 1px solid var(--deleteColor);
  background: #feefed;
  color: var(--deleteColor);
}

.toggle-admin-list-search {
  margin: 20px 0 0;
  background-color: var(--pageColor);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  padding: 15px 10px;
  border-bottom: 1px solid var(--lightBorderColor);
  display: flex;
  align-items: center;
  gap: 20px;
}

.toggle-admin-list-search input {
  background-color: var(--backgroundColor);
  font-size: .9rem;
  padding: 5px;
}

.toggle-admin-list-search button {
  border: 1px solid var(--deleteColor);
  border-radius: 6px;
  color: var(--deleteColor);
  font-size: .9rem;
  padding: 3px;
}