.toggle-admin-user-report-content {
  background-color: var(--pageColor);
  padding: 15px;
  border-radius: 6px;
  margin: 20px 0 0;
}

.toggle-admin-user-report-content-vis-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.toggle-admin-user-report-content-vis-set {
  width: 100%;
  height: 40vh;
  display: flex;
  gap: 15px;
}

.toggle-admin-user-report-content-vis {
  width: 50%;
  height: 100%;
  flex: 1;
  border: 1px solid var(--lightBorderColor);
  padding: 10px;
  border-radius: 6px;
  text-align: center;
}

.toggle-admin-user-report-content-vis h4 {
  margin: 0 0 10px;
}

.toggle-admin-chart-list {
  overflow: hidden;
  height: 100%;
  --_cols: max(1, var(--cols, 6)); 
	--_gap: var(--gap, 2px);
	--_min: var(--min, 50px);
	--_max: var(--max, 80px); 
  display: grid;
  gap: var(--_gap);
  grid-template-columns: repeat(auto-fill, minmax(max(var(--_min), calc((100% - var(--_gap) * (var(--_cols) - 1)) / var(--_cols))), 1fr));
}

.toggle-admin-chart-list li {
  text-align: left;
}

.toggle-admin-chart-list h6 {
  margin: 5px 0;
}

.toggle-admin-chart-list ul {
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin: 0 0 15px;
}

.toggle-admin-chart-list ul li {
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--lighterTextColor);
  font-size: .9rem;
}

.toggle-admin-chart-list ul li:last-child strong {
  background-color: var(--primaryColor);
  padding: 4px 8px;
  border-radius: 6px;
  color: #fff;
}

.toggle-admin-chart-list ul li i {
  font-size: .7rem;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  color: var(--primaryColor);
  display: inline-block;
  margin: 2px 0 0;
}

.toggle-admin-chart-list ul li span {
  color: var(--primaryColor);
  padding: 2px;
  display: inline-block;
}

.toggle-admin-chart-list ul li.success span {
  background-color: var(--greenColor);
  color: var(--textColor);
  border-radius: 4px;
}

.toggle-admin-chart-list ul li.error {
  color: var(--redColor);
}

.toggle-admin-chart-list ul li.error span {
  background-color: var(--redColor);
  color: #fff;
  border-radius: 4px;
}