.toggle-export-container .toggle-content-list {
  max-width: 300px;
  border: 1px solid var(--lightBorderColor);
  padding: var(--halfPadding);
  max-height: none;
  border-radius: 8px;
  margin: 0 0 var(--standardPadding);
}

.toggle-export-container .toggle-content-list li:first-of-type {
  font-weight: 600;
}