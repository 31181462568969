.toggle-tab-bar {
  display: flex;
  gap: var(--halfPadding);
  margin: var(--halfPadding) 0 var(--doublePadding) var(--doublePadding);
}

.toggle-tab-bar li {
  background-color: var(--lightBackgroundColor); 
  border-radius: 10px;
}

.toggle-tab-bar li.selected {
  background-color: var(--brandTertiaryColor);
}

.toggle-tab-bar li button {
  padding: var(--halfPadding);
  font-weight: 400;
}

.toggle-tab-bar li h6 {
  margin: 0;
  display: flex;
  gap: var(--quarterPadding);
  color: inherit;
  font-weight: inherit;
}

.toggle-tab-bar li:not(.selected):hover {
  background-color: var(--darkerBackgroundColor);
  color: var(--brandPrimaryColor);
}

.toggle-tab-bar li img {
  height: 1rem;
}

.toggle-tab-bar.simple {
  margin: 0 0 var(--doublePadding);
  gap: var(--standardPadding);
}

.toggle-tab-bar.simple li {
  background-color: transparent;
  position: relative;
}

.toggle-tab-bar.simple li button {
  color: var(--brandPrimaryColor);
  padding: calc(var(--quarterPadding)/2);
}

.toggle-tab-bar.simple li.selected button {
  color: var(--textColor);
  font-weight: 500;
}

.toggle-tab-bar.simple li:not(:last-child):after {
  content: "";
  background-color: var(--borderColor);
  height: 80%;
  width: 2px;
  position: absolute;
  right: calc(var(--halfPadding) * -1);
  top: 10%;
}