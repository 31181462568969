.toggle-header {
  width: 100%;
  background-color: var(--pageColor);
  padding: 0 var(--doublePadding);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: var(--headerHeight);
}

.toggle-header-left-container {
  display: flex;
  align-items: center;
}

.toggle-header-left-container img {
  height: calc(var(--headerHeight)/2);
}

.toggle-header-left-container img:hover {
  cursor: pointer;
  opacity: .8;
}

.toggle-header-right-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: var(--standardPadding);
}

.toggle-header-right-container .new-project-btn {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: capitalize;
  display: flex;
  gap: 0.3rem;
}

.toggle-header-right-container .global-search-btn {
  padding: var(--quarterPadding);
  border-color: transparent;
  color: var(--textColorDisabled);
  margin: 0;
  font-size: 1rem;
}

.toggle-header-right-container .global-search-btn:hover{
  color: var(--brandPrimaryColor);
}

.toggle-header-right-container .user-avatar {
  background-color: var(--brandPinkColor);
  border-radius: 100%;
  color: #fff;
  display: block;
  flex-shrink: 0;
  font-size: .9rem;
  height: 1.7rem;
  line-height: 1.7rem;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-transform: uppercase;
  width: 1.7rem;
  margin: var(--quarterPadding) 0;
}

.toggle-project-header-container {
  display: flex;
  align-items: center;
  gap: var(--halfPadding);
  flex: 1;
}

.toggle-project-header-dropdown {
  height: calc(var(--headerHeight)/2 + var(--quarterPadding)*2);
  display: flex;
  align-items: center;
  gap: var(--halfPadding);
  border: 1px solid transparent;
  border-radius: 10px;
  padding: var(--quarterPadding);
  margin: 0 0 0 calc(-1 * var(--quarterPadding));
}

.toggle-project-header-dropdown img {
  height: 100%;
}

.toggle-project-header-dropdown:hover {
  border-color: var(--brandPrimaryColor);
  box-shadow: 0 4px 6px rgba(1,1,1,.1);
  cursor: pointer;
}

.toggle-project-header-container .icon-arrow_forward {
  transform: scaleX(-1);
}

.toggle-project-header-container .dropdown-links-container {
  z-index: 988;
}

.toggle-project-header-right .project-save-state {
  font-size: 1.5rem;
  display: inline-block;
  margin: 0 var(--halfPadding) 0 auto;
}

.toggle-project-header-right .icon-cloud_done {
  color: var(--brandPrimaryColor);
}

.toggle-undo-redo-btns {
  display: flex;
  gap: var(--standardPadding);
  margin: 0 var(--doublePadding);
}

.toggle-undo-redo-btns button {
  font-size: 1.15rem;
}

.toggle-project-header-middle {
  justify-content: center;
}

.toggle-project-header-middle h5 {
  margin: 0;
  padding: var(--quarterPadding);
  border-radius: 8px;
}

.toggle-project-header-middle h5:hover {
  color: var(--brandSecondaryColor);
  background-color: var(--lightBackgroundColor);
}