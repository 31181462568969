.threedy-material-category-selector ul li {
  margin: 0;
}

.threedy-material-category-selector ul li:not(:last-child) {
  border-bottom: 1px solid var(--outlineColor);
}

.threedy-material-category-selector ul li:hover {
  background-color: var(--backgroundColor);
  border-color: var(--backgroundColor);
}

.threedy-material-category-selector ul li button {
  display: block;
  width: 100%;
  max-width: none;
  padding: 10px;
  margin: 0;
  color: var(--textColor);
}

.threedy-material-material-selector-search-sort {
  display: flex;
  margin: 20px 0 0;
  gap: 10px;
  flex-direction: column;
}

.threedy-material-material-selector-search {
  position: relative;
}

.threedy-material-material-selector-search input {
  width: 60%;
}

.threedy-material-material-selector-search .search-by-image-btn {
  padding: 5px;
  position: absolute;
  right: 3%;
  top: 20%;
  cursor: pointer;
}

.threedy-material-material-selector-search .search-by-image-btn svg path {
  fill: var(--textColor);
}

.threedy-material-material-selector-search button {
  width: 40%;
  white-space: nowrap;
}

.threedy-material-material-selector-header {
  position: relative;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

.threedy-material-material-selector-header button {
  /* color: var(--textColor); */
  color: var(--textColor);
  width: 40%;
  text-align: left;
}

.threedy-material-material-selector-header h4 {
  /* position: absolute;
  left: 55%;
  transform: translateX(-50%);
  top: 8px; */
  white-space: nowrap;
  color: var(--textColor);
  transform: translateX(-30%);
}

.threedy-material-material-selector input,
.threedy-material-material-selector-search input {
  border: 1px solid var(--outlineColor);
  width: 100%;
  padding: 7px 10px;
  color: var(--textColor);
}

.threedy-material-material-selector ul {
  max-height: 45vh;
  overflow: auto;
  margin: 20px 0 0;
  display: grid;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(100px, .5fr));
}

.threedy-material-material-selector .threedy-material-material-container.tile-size-Large ul li h5 {
  font-size: .9rem;
}

.threedy-material-material-selector .threedy-material-material-container.tile-size-Large ul li img {
  width: 100%;
}

.threedy-material-material-selector .threedy-material-material-container.tile-size-Small ul {
  grid-template-columns: repeat(auto-fit, minmax(60px, .33fr));
}

.threedy-material-material-selector .threedy-material-material-container.tile-size-Small ul li h5 {
  font-size: .8rem;
}

.threedy-material-material-selector ul li {
  border: 1px solid var(--outlineColor);
  border-radius: 6px;
  margin: 0;
  padding: 0;
  color: var(--textColor);
  position: relative;
}

.threedy-material-material-selector ul li .resolution-list {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: 500;
  display: flex;
  gap: 3px;
  font-size: 12px;
  color: var(--lightestTextColor);
  visibility: hidden;
  z-index: 9;
}

.threedy-material-material-selector ul li:hover>.resolution-list:not(.hide) {
  visibility: visible;
}

.threedy-material-material-selector ul li img {
  aspect-ratio: 1/1;
  width: 100%;
  margin: 0 auto;
  object-fit: cover;
}

.threedy-material-material-selector ul li h5 {
  text-align: center;
  margin: 0 0 5px;
  padding: 0 5px;
  width: 100%;
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.threedy-material-material-selector ul li .advanced-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: var(--backgroundColor);
  font-size: 1.25rem;
  color: var(--textColor);
  padding: 5px;
  border-radius: 6px;
  display: none;
}

.global-image-result {
  margin: 10px 3px 8px 3px;
  border: 1px solid var(--primaryColor);
  border-radius: 25px;
  padding: 6px 10px;
  display: flex;
  gap: 5px;
  align-items: baseline;
  letter-spacing: normal;
}

.global-image-result .global-image-count {
  flex: 1;
}

.global-image-result .global-image-text {
  color: var(--lighterTextColor);
}

.global-image-result .global-image-reset-btn {
  border: 1px solid var(--deleteColor);
  padding: 3px 10px;
  border-radius: 25px;
  color: var(--deleteColor);
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
}

.threedy-material-material-selector ul li .advanced-btn:hover {
  opacity: .8;
  background-color: var(--primaryColor);
}

.threedy-material-material-selector ul li:not(.selected):hover {
  cursor: pointer;
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.threedy-material-material-selector ul li:hover .advanced-btn {
  display: block;
}

.threedy-material-material-selector ul li.selected {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.threedy-material-material-selector ul li.disabled {
  opacity: .3;
  pointer-events: none;
}

.threedy-material-material-selector h6 {
  font-size: 1rem;
  text-align: center;
}

.threedy-material-material-container {
  position: relative;
  min-height: 10vh;
}

.configurator-builder .threedy-material-category-selector {
  max-height: 295px;
  overflow: auto;
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 0;
}

.configurator-builder .threedy-material-category-selector>ul,
.configurator-builder .threedy-material-material-container ul {
  max-height: none;
  margin: 0;
}

.configurator-builder .threedy-material-material-container {
  position: relative;
  min-height: 100px;
  max-height: 178px;
  overflow: auto;
  margin-top: 10px;
}

.configurator-builder .threedy-material-material-selector-search-sort {
  margin-top: 5px;
}

.selector-header-underline {
  border: none;
  min-height: .5px;
  background-color: black;
  margin-top: 0;
}

.threedy-material-material-container ul li.disabled {
  pointer-events: none;
  opacity: 1;
}