.configurator-builder {
  left: 25px !important;
  height: calc(100vh - 110px);
  max-height: none;
  background-color: transparent;
  width: 325px !important;
  overflow: hidden;
}

.configurator-builder .threedy-tab-bar {
  flex: initial;
  flex-shrink: 0;
}

.configurator-builder-flex-container {
  display: flex;
  gap: 20px;
  height: 100%;
  flex-direction: column;
}

.configurator-builder-flex {
  background-color: var(--pageColor);
  border-radius: 10px;
  width: 100%;
  overflow: hidden;
}

.configurator-builder-flex .threedy-lab-workspace-list-menu {
  padding: 10px 20px;
  flex: initial;
  flex-shrink: 0;
}

.configurator-builder-materials {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 65%;
}

.configurator-builder-materials h4 span {
  color: var(--primaryColor);
  /* display: inline-block; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 5px;
  font-weight: 400;
}

.configurator-builder-materials .threedy-material-category-selector {
  padding: 10px 5px 10px 25px;
  margin: 5px 5px 5px 0;
  max-height: none;
  flex: 4;
}

.configurator-builder-materials .threedy-material-material-selector {
  margin: 0;
  padding: 15px 15px 0;
  max-height: none;
  flex: 4;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.configurator-builder-materials .threedy-material-material-selector .threedy-material-material-selector-header {
  padding: 0;
}

.configurator-builder-materials .threedy-material-material-selector .selector-header-underline {
  display: none;
}

.configurator-builder-materials .threedy-material-material-selector-search-sort {
  margin: 0;
}
 
.configurator-builder-materials .threedy-material-material-selector .threedy-material-material-container {
  flex: 1;
  min-height: 0;
  max-height: none;
  margin: 0;
}

.configurator-builder-materials .threedy-material-material-selector .threedy-material-material-container ul {
  padding: 0 0 15px;
}

.configurator-builder-materials .threedy-color-picker {
  flex: initial;
  max-height: none;
  padding: 15px 15px 0;
  margin: 0;
  flex: 4;
  display: flex;
  flex-direction: column;
  position: relative;
}

.configurator-builder-materials .threedy-color-picker .sketch-picker {
  padding: 0 !important;
  width: 100% !important;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box !important;
  gap: 10px;
}

.configurator-builder-materials .threedy-color-picker .sketch-picker > div:first-of-type {
  flex: 4;
  padding: 0 !important;
  margin: 0 !important;
}

.configurator-builder-materials .threedy-color-picker .sketch-picker > div:nth-of-type(2) {
  flex: initial;
  flex-shrink: 0;
  padding: 0 !important;
  margin: 0 !important;
}

.configurator-builder-materials .threedy-color-picker .sketch-picker > div:nth-of-type(3) {
  flex: initial;
  flex-shrink: 0;
  padding: 0 !important;
  margin: 0 0 0 auto !important;
  width: 65% !important;
}

.configurator-builder-materials .show-swatch {
  bottom: 10px;
  width: 15%;
  height: 0;
  padding-bottom: 14%;
}

.configurator-builder-materials .add-option {
  flex: initial;
  margin: auto 0 0;
  padding: 15px;
  flex-shrink: 0;
  background-color: var(--pageColor);
  position: relative;
  z-index: 4;
}

.configurator-builder-material-settings {
  flex: initial;
  height: 32.2%;
  overflow: hidden;
}

.configurator-builder-material-settings .material-settings-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.configurator-builder-material-settings .material-settings-content {
  padding: 0 0 0 20px;
  margin: 5px 5px 5px 0;
  max-height: none;
  flex: 1;
}