.global-search-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.global-search-container .search-input-container {
    width: 30vw;
    border: 1px solid var(--lighterTextColor);
    padding: calc(var(--halfPadding) + var(--quarterPadding) - 0.2rem);
    position: relative;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
}

.global-search-container .search-input-container.glow {
    border: 1px solid var(--brandPrimaryColor);
    transition: 0.2s border ease-in;
}

.global-search-container .search-input-container.glow .icon-search, 
.global-search-container .search-input-container.glow .close-btn {
    color: var(--brandPrimaryColor) !important;
    transition: 0.2s color ease-in;
}

.global-search-container .icon-search {
    position: absolute;
    top: 7px;
    left: 8px;
    color: var(--textColorDisabled);
    font-size: 16px;
}

.global-search-container .close-btn {
    position: absolute;
    top: 0px;
    right: 3px;
    color: var(--textColorDisabled);
    padding: var(--quarterPadding);
    cursor: pointer;
}

.global-search-container .search-input-element {
    border: 0px solid transparent;
    bottom: 0;
    position: absolute;
    left: 30px;
    top: 0;
    right: 2%;
    color: var(--lighterTextColor);
    letter-spacing: normal;
    font-size: var(--halfPadding);
}

.search-results-container {
    position: absolute;
    top: 35px;
    width: 100%;
    padding: var(--quarterPadding);
    border-radius: 10px;
    background: var(--lightBackgroundColor);
    z-index: 9;
    max-height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid var(--brandLightColor);
}

.search-results-container.scrollbar-sm::-webkit-scrollbar-track {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.search-results-container ul {
    list-style-type: none;
    padding: var(--quarterPadding);
    margin: var(--quarterPadding);
}

.search-results-container ul li.category-heading {
    font-weight: 600;
    font-size: 13px;
}

.search-results-container ul li.category-heading:not(:first-child) {
    margin-top: 0.5rem;
}

.search-results-container ul li .link-btn {
    width: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--brandLightColor);
}

.search-results-container ul li.global-search-result {
    font-weight: 400;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    padding: var(--quarterPadding);
    margin-left: var(--standardPadding);
    margin-top: 3px;
    border: 1px solid var(--brandLightColor);
    border-radius: 0.5rem;
}

.search-results-container ul li.global-search-result:hover {
    background-color: var(--readOnlyBackgroundColor);
    border-radius: 4px;
    transition: 0.2s all ease;
}

.search-results-container ul li>a {
    display: inline;
}

.search-results-container .global-search-result .result-name {
    max-width: 15vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.search-results-container .global-search-result .result-link {
    display: flex;
    background: var(--brandPrimaryColor);
    border-radius: 10px;
}

.search-results-container .global-search-result .result-text {
    display: flex;
    flex-direction: column;
}

.search-results-container .global-search-result .result-text .result-breadcrumbs {
    font-size: calc(var(--halfPadding) - 0.1rem);
    letter-spacing: normal;
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
}

.search-results-container .global-search-result .result-text .result-breadcrumbs .chunk {
    color: var(--lightGrayedColor);
    max-width: 9vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.search-results-container .global-search-result:hover>.result-link .link-btn {
    padding-left: 12px;
    transition: 0.2s padding ease-out;
}