.toggle-splash header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 10px 3px;
  margin: 0 auto;
  align-items: center;
  gap: 20px;
  z-index: 2888;
  width: 100%;
  background-color: var(--lightBackgroundColor);
  border-bottom: 1px solid #333;
}

.toggle-splash .mobile-only {
  display: none;
}

.toggle-splash .desktop-only {
  display: block;
}

.toggle-splash footer {
  margin: 0;
  padding: 20px;
  display: flex;
  align-items: center;
  border-top: 1px solid var(--lightBorderColor);
  background: var(--lightBackgroundColor);
}

.toggle-splash footer div {
  margin: 0 auto;
  color: var(--lightestTextColor);
  font-weight: 500;
}

.toggle-splash footer div ul {
  display: flex;
  margin: 0 0 10px;
  gap: 20px;
  flex-direction: column;
  text-align: center;
}

.toggle-splash footer div ul a {
  font-size: 1.2rem;
  color: var(--textColor);
}

.toggle-splash footer img {
  height: 30px;
}

.toggle-splash header img {
  height: 30px;
  margin: 0 5px 0 0;
}  

.toggle-splash header ul {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
}

.toggle-splash header ul li {
  font-size: 1.1rem;
  font-weight: 500;
  flex-shrink: 0;
  margin: 0;
  padding: 0 24px;
}


.toggle-splash header ul li.header-logo {
  display: flex;
  flex-direction: row;
}

.toggle-splash header ul li.header-logo .header-toggle-public-beta {
  background-color: var(--lightPrimaryColor);
  color: var(--primaryColor);
  font-weight: bold;
  font-size: .9rem;
  padding: 3px 6px;
  border-radius: 6px;
}

.toggle-splash header ul li.sign-up {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

.toggle-splash header ul li.login {
  margin: 0 24px 0 0;
  padding: 0;
  font-size: 1rem;
}

.toggle-splash header ul li.investors {
  margin: 0 24px 0 0;
  padding: 5px 10px;
  font-size: 1rem;
  color: var(--primaryColor);
  font-weight: bold;
  border: 1px solid var(--primaryColor);
  border-radius: 20px;
}

.toggle-splash header ul li.ml-auto {
  margin: 0 24px 0 auto;
}

.toggle-splash {
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: auto;
  background-color: var(--backgroundColor);
}

.toggle-splash .splash-content {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.toggle-splash .first-splash-content {
  width: 200vw;
}

.toggle-splash section {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-splash .first-splash-content section:nth-child(2) {
  left: 100vw;
}

.toggle-splash section p, .toggle-splash section h1 {
  margin: 0;
  padding: 0;
}

.toggle-splash section.first {
  z-index: 2;
  background: var(--purpleColor);
  background: linear-gradient(to right, var(--purpleColor), transparent);
}

.toggle-splash section.first .car-model {
  position: absolute;
  top: 22vh;
  right: 4vw;
  width: 55vw;
  height: 35vw;
  overflow: hidden;
  z-index: 2;
}

.toggle-splash section.first .car-model img {
  width: 55vw;
  height: 35vw;
  position: absolute;
  top: 0;
  right: 0;
}

.toggle-splash section.first .rendered {
  width: 0;
  z-index: 9999;
}

.toggle-splash section h1 {
  font-size: 5rem;
  color: #fff;
  position: absolute;
  top: 25vh;
  left: 5vw;
  max-width: 700px;
}

.toggle-splash section h1 span {
  display: block;
}

.toggle-splash section p {
  font-size: 1.75rem;
  color: #fff;
  position: absolute;
  bottom: 10vh;
  left: 5vw;
  max-width: 500px;
  font-weight: 400;
}

.toggle-splash section.first .primary-btn {
  margin: 30px 0 0;
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 15px 30px;
  font-size: 1.25rem;
  text-align: center;
  display: block;
  width: 20vw;
}

.toggle-splash section.first .primary-btn span {
  position: relative;
  z-index: 2;
}

.toggle-splash section.first .primary-btn span.fill {
  background: #fff;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: var(--primaryColor);
}

.toggle-splash section.first .scroll-hint {
  position: absolute;
  bottom: 10vh;
  right: 10vw;
  background-color: black;
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 1.25rem;
  opacity: 0;
  z-index: 2;
  transform: scale(0.98) translateY(10px);
}

.toggle-splash section.first .circles {
  position: absolute;
  bottom: 3.5vw;
  right: 4vw;
  height: 20vw;
  filter: invert(0.6);
}

.toggle-splash section.second {
  z-index: 1;
  background: var(--primaryColor);
}

.toggle-splash .second-splash-content {
  height: 100vh;
}

.toggle-splash .section-list-container {
  background-color: var(--lightBackgroundColor);
  position: relative;
  z-index: 3;
  width: 100%;
}

.toggle-splash .second-splash-content ul {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 40vh;
  width: 100%;
  margin: 0 auto;
  padding: 10vh 5vw 0;
  gap: 20px;
}

.toggle-splash .second-splash-content ul li {
  text-align: center;
  flex: 1;
}

.toggle-splash .second-splash-content ul li img {
  max-width: 58%;
  max-height: 48vh;
}

.toggle-splash .second-splash-content ul li p {
  margin: 20px auto 0;
  font-size: 1.25rem;
}

.toggle-splash .third-splash-content {
  width: 300vw;
}

.toggle-splash section.third {
  transform: translateX(100%);
  opacity: 0;
  z-index: 1;
}

.toggle-splash section.light h1, 
.toggle-splash section.light p {
  color: var(--textColor);
}

.toggle-splash section.third h1, 
.toggle-splash section.fourth h1, 
.toggle-splash section.fifth h1, 
.toggle-splash section.six h1, 
.toggle-splash section.seven h1, 
.toggle-splash section.eight h1 {
  max-width: 500px;
  transform: translateX(100%);
  font-size: 4rem;
}

.toggle-splash section.third p, 
.toggle-splash section.fourth p, 
.toggle-splash section.fifth p, 
.toggle-splash section.six p, 
.toggle-splash section.seven p, 
.toggle-splash section.eight p {
  font-size: 1.45rem;
  top: 48vh;
}

.toggle-splash section.fourth h1 {
  transform: none;
}

.toggle-splash section.third ul {
  position: absolute;
  bottom: 20vh;
  right: 0;
  display: flex;
  gap: 10px;
}

.toggle-splash section.third ul img {
  height: 50px;
}

.toggle-splash section.third .load-btn {
  position: absolute;
  top: 20vh;
  right: 36vw;
  z-index: 2;
  font-size: 1.25rem;
  padding: 15px 40px;
  opacity: 0;
  font-size: 1.4rem;
}

.toggle-splash section.third .cursor {
  position: absolute;
  top: 25vh;
  right: 36vw;
  font-size: 2rem;
  opacity: 0;
  z-index: 3;
}

.toggle-splash section.third .cursor span {
  display: inline-block;
  transform: rotate(35deg);
}

.toggle-splash section.third .car-model {
  position: absolute;
  top: 16vh;
  right: 0;
  width: 55vw;
  height: 35vw;
  z-index: 1;
  transform: scale(0);
  opacity: 0;
  overflow: hidden;
}

.toggle-splash section.third .car-model img {
  width: 55vw;
  height: 35vw;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-splash section.third .car-model-mesh {
  opacity: 0;
  transform: none;
}

.toggle-splash section.third .car-model-shading {
  opacity: 1;
  transform: none;
  clip-path: polygon(100% 100%, 100% 0, 100% 0, 100% 100%);
  z-index: 4;
}

.toggle-splash section.third .car-model-rendered {
  opacity: 0;
  transform: none;
  z-index: 4;
}

.toggle-splash section.third .car-model-rendered-lighten {
  opacity: 0;
  transform: none;
  z-index: 5;
  filter: brightness(1.3);
}

.toggle-splash section.fourth {
  z-index: 3;
  transform: translateX(100vw);
}

.toggle-splash section.fourth ul {
  position: absolute;
  top: 20vh;
  right: 15vw;
  display: flex;
  gap: 10px;
}

.toggle-splash section.fourth ul img {
  height: 50px;
}

.toggle-splash section.fourth .color-picker {
  position: absolute;
  bottom: 20vh;
  right: 6vw;
}

.toggle-splash section.fourth .color-picker img {
  height: 200px;
}

.toggle-splash section.fourth .color-picker .color-picker-pointer {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 20px;
  width: 20px;
  border: 3px solid #fff;
  border-radius: 100%;
}

.toggle-splash section.fifth {
  z-index: 4;
  transform: translateX(200vw);
}

.toggle-splash section.fifth p {
  top: 50vh;
  transform: translateX(100%);
}

.toggle-splash section.fifth .slider {
  position: absolute;
  bottom: 25vh;
  right: 30vw;
  width: 250px;
  background-color: rgba(255,255,255,.8);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.toggle-splash section.fifth .slider-bar {
  background-color: #999;
  border-radius: 6px;
  height: 12px;
  position: relative;
}

.toggle-splash section.fifth .slider-bar span {
  position: absolute;
  top: -3px;
  left: calc(50% - 10px);
  height: 20px;
  width: 20px;
  display: inline-block;
  border-radius: 100%;
  background-color: var(--primaryColor);
}

.toggle-splash section.fifth .lighting {
  position: absolute;
  top: 18vh;
  right: 10vw;
  width: 15vw;
  opacity: .8;
}

.toggle-splash section.six {
  position: absolute;
}

.toggle-splash section.six h1 {
  transform: translateY(5vh);
}

.toggle-splash section.six p {
  top: 45vh;
  transform: translateY(10vh);
}

.toggle-splash section.six .ar-icon {
  position: absolute;
  top: 28vh;
  right: 42vw;
  height: 50px;
  transform: translateY(4vh);
}

.toggle-splash section.six .street {
  position: absolute;
  bottom: 38vh;
  right: 0;
  height: 700px;
  transform: translateY(40vh)
}

.toggle-splash .fourth-splash-content {
  height: 100vh;
  z-index: 4;
}

.toggle-splash section.seven {
  height: 100vh;
  transform: translateY(100vh);
  z-index: 5;
}

.toggle-splash section.seven h1 {
  transform: none;
}

.toggle-splash section.seven p {
  top: 50vh;
}

.toggle-splash section.seven .bubble {
  position: absolute;
  height: 50px;
  transform: scale(0);
  opacity: 0;
}

.toggle-splash section.seven .bubble-1 {
  top: 30vh;
  right: 30vw;
}

.toggle-splash section.seven .bubble-2 {
  top: 44vh;
  right: 10vw;
}

.toggle-splash section.seven .publish-btn {
  position: absolute;
  top: 34vh;
  right: 10vw;
  background-color: var(--primaryColor);
  color: #fff;
  display: inline-block;
  font-size: 1.4rem;
  transform: scale(0);
  opacity: 0;
  padding: 10px;
  border-radius: 6px;
}

.toggle-splash section.eight {
  height: 100vh;
  transform: translateY(200vh);
  z-index: 1;
}

.toggle-splash section.eight h1 {
  transform: none;
}

.toggle-splash section.eight p {
  top: 50vh;
}

.toggle-splash section.eight .ecomm {
  position: absolute;
  bottom: 39vh;
  right: 10vw;
  height: 450px;
  transform: translateY(70vh);
}
.sign-up-btn-container{
  text-align: center;
}
.circles-grid {
  opacity: .2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 85vw;
  transform: translateY(50%);
}

.fourth-splash-content .car-model-rendered {
  position: absolute;
  top: 16vh;
  right: 0;
  width: 55vw;
  height: 35vw;
  z-index: 2;
  filter: brightness(1.3);
}

.fourth-splash-content .car-model-rendered img {
  width: 55vw;
  height: 35vw;
  position: absolute;
  top: 0;
  left: 0;
}

.fourth-splash-content .primary-btn {
  position: absolute;
  bottom: 5vh;
  left: 50%;
  font-size: 1.4rem;
  z-index: 44;
  transform: translateX(-50%);
}

@media screen and (min-width: 700px) and (max-width:800px) and (orientation:landscape) {
  .toggle-splash section h1 {
    font-size: 2.7rem;
  }

  .toggle-splash section p {
    font-size: 1.1rem;
    max-width: 400px;
  }

  .toggle-splash section .primary-btn {
    font-size: 1.1rem !important;
    padding: 7px 20px !important;
  }

  .toggle-splash section.first .car-model {
    top: 55%;
    transform: translateY(-50%);
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 45vw;
    height: 29vw;
  }

  .toggle-splash section.first .circles {
    height: 26vmin;
    bottom: 30%;
    transform: translateY(50%);
  }

  .toggle-splash .second-splash-content ul li p {
    font-size: 1rem;
  }

  .toggle-splash section.third h1, .toggle-splash section.fourth h1, .toggle-splash section.fifth h1, .toggle-splash section.six h1, .toggle-splash section.seven h1, .toggle-splash section.eight h1 {
    font-size: 2.7rem;
    max-width: 350px;
  }

  .toggle-splash section.third p, .toggle-splash section.fourth p, .toggle-splash section.fifth p, .toggle-splash section.six p, .toggle-splash section.seven p, .toggle-splash section.eight p {
    font-size: 1.1rem;
    max-width: 300px;
  }

  .toggle-splash section.third .car-model, .toggle-splash section.fifth .car-model {
    top: 36vh;
    left: 50%;
  }

  .toggle-splash section.third ul {
    bottom: 10vh;
  }

  .toggle-splash section.third ul img {
    height: 35px;
  }

  .toggle-splash section.fourth ul {
    right: -35vw;
    top: 24vh;
  }

  .toggle-splash section.fourth ul img {
    height: 30px;
  }

  .fourth-splash-content .car-model-rendered {
    top: 50%;
    transform: translateY(-50%);
  }

  .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 45vw;
    height: 29vw;
  }

  .toggle-splash section.fourth .color-picker {
    bottom: 5vh;
    right: 1vw;
  }

  .toggle-splash section.fourth .color-picker img {
    height: 100px;
  }

  .toggle-splash section.fifth .slider {
    bottom: 5vh;
    width: 200px;
  }

  .toggle-splash section.six .street {
    bottom: 50%;
    transform: translateY(50%);
    height: 80vmin;
  }

  .toggle-splash section.six .ar-icon {
    top: 15vh;
    height: 30px;
  }

  .toggle-splash section.seven .bubble {
    height: 35px;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.1rem;
    top: 60%;
    right: 20vw;
  }

  .toggle-splash section.eight .ecomm {
    bottom: 60%;
    height: 40vmin;
    right: 8vw;
  }

  .toggle-splash section.eight .primary-btn {
    left: 50%;
    transform: translateX(-50%);
  }

  .toggle-splash footer div {
    transform: none;
  }

  .toggle-splash footer div ul {
    flex-direction: column;
    gap: 10px;
    text-align: center;
  }
}

@media screen and (min-width: 801px) and (max-width:1199px) and (orientation:landscape) {
  .toggle-splash section h1 {
    font-size: 4rem;
  }

  .toggle-splash section p {
    font-size: 1.75rem;
    max-width: 400px;
  }

  .toggle-splash section .primary-btn {
    font-size: 1.3rem !important;
    padding: 15px 20px !important;
  }

  .toggle-splash section.first .car-model {
    top: 45vh;
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 45vw;
    height: 29vw;
  }

  .toggle-splash section.first .circles {
    height: 16vw;
    bottom: 10vw;
  }

  .toggle-splash .second-splash-content ul li p {
    font-size: 1rem;
  }

  .toggle-splash section.third h1, .toggle-splash section.fourth h1, .toggle-splash section.fifth h1, .toggle-splash section.six h1, .toggle-splash section.seven h1, .toggle-splash section.eight h1 {
    font-size: 2.7rem;
    max-width: 350px;
  }

  .toggle-splash section.third p, .toggle-splash section.fourth p, .toggle-splash section.fifth p, .toggle-splash section.six p, .toggle-splash section.seven p, .toggle-splash section.eight p {
    font-size: 1.1rem;
    max-width: 300px;
  }

  .toggle-splash section.third .car-model, .toggle-splash section.fifth .car-model {
    top: 36vh;
    left: 50%;
  }

  .toggle-splash section.third ul {
    bottom: 10vh;
  }

  .toggle-splash section.fourth ul {
    right: -35vw;
    top: 24vh;
  }

  .toggle-splash section.fourth ul img {
    height: 35px;
  }

  .fourth-splash-content .car-model-rendered {
    top: 35vh;
  }

  .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 45vw;
    height: 29vw;
  }

  .toggle-splash section.fourth .color-picker {
    bottom: 5vh;
    right: 1vw;
  }

  .toggle-splash section.fourth .color-picker img {
    height: 130px;
  }

  .toggle-splash section.fifth .slider {
    bottom: 5vh;
    width: 200px;
  }

  .toggle-splash section.six .street {
    bottom: 46vh;
    height: 600px;
  }

  .toggle-splash section.six .ar-icon {
    top: 30vh;
    height: 30px;
  }

  .toggle-splash section.seven .bubble {
    height: 35px;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.4rem;
    right: 20vw;
    top: 66vh;
  }

  .toggle-splash section.eight .ecomm {
    height: 26vmax;
    bottom: auto;
    top: 4vh;
    right: 10vmin;
  }

  .toggle-splash section.eight h1 {
    top: 40vh;
  }

  .toggle-splash section.eight p {
    top: 60vh;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1279px) and (orientation:landscape) {
  .toggle-splash section.first .car-model {
    top: 35vh;
  }
  
  .toggle-splash section.third .car-model {
    top: 31vh;
  }

  .toggle-splash section.fourth ul {
    top: 30vh;
  }

  .toggle-splash section.fifth .lighting {
    top: 30vh;
  }

  .fourth-splash-content .car-model {
    top: 23vh;
  }

  .toggle-splash section.eight .ecomm {
    height: 350px;
    transform: translateY(55vh);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) and (orientation:landscape) {

  .toggle-splash section h1 {
    font-size: 4rem;
  }

  .toggle-splash section p {
    font-size: 1.5rem;
    max-width: 42vmax !important;
  }

  .toggle-splash section.first .circles {
    height: 27vmin;
    bottom: 20vmin;
  }

  .toggle-splash section.first .car-model {
    top: 29vmin;
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 75vmin;
    height: 47vmin;
  }

  .toggle-splash section.third .car-model {
    top: 35vh;
  }

  .fourth-splash-content .car-model-rendered {
    top: 50%;
    transform: translateY(-50%);
    right: 10vw;
  }

  .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 75vmin;
    height: 47vmin;
  }

  .toggle-splash section.third ul {
    bottom: 5vh;
  }

  .toggle-splash section.fifth .slider {
    bottom: 10vh;
  }

  .toggle-splash section.six .ar-icon {
    top: 15vh;
  }

  .toggle-splash section.six .street {
    height: 80vmin;
    bottom: 56vh;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.5rem;
    top: 70vh;
    right: 26vw;
  }

  .toggle-splash section.eight .ecomm {
    height: 48vmin;
    bottom: 45%;
    top: auto;
    right: 24vmin;
  }
}

@media screen and (min-width: 1366px) and (max-width: 1399px) and (orientation:landscape) {
  .toggle-splash section.first .circles {
    height: 33vmin;
    bottom: 30%;
    transform: translateY(50%);
  }

  .toggle-splash section.first .car-model {
    top: 55%;
    transform: translateY(-50%);
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 87vmin;
    height: 55vmin;
  }

  .toggle-splash section.third .car-model {
    top: 30vh;
    right: 5vw;
  }

  .toggle-splash section.third ul {
    bottom: 8vh;
  }

  .fourth-splash-content .car-model-rendered {
    top: 50%;
    transform: translateY(-50%);
    right: 5vw;
  }

  .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 87vmin;
    height: 55vmin;
  }

  .toggle-splash section.fifth .slider {
    bottom: 7vh;
  }

  .toggle-splash section.six .ar-icon {
    top: 15vh;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.5rem;
    top: 76vh;
    right: 26vw;
  }

  .toggle-splash section.eight .ecomm {
    height: 50vmin;
    bottom: 42%;
    right: 17vmin;
  }
}

@media screen and (min-width: 1400px) and (max-width: 1535px) and (orientation:landscape) {
  .toggle-splash section.first .circles {
    height: 27vmin;
    bottom: 14vmin;
  }

  .toggle-splash section.first .car-model {
    top: 33vmin;
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 80vmin;
    height: 50vmin;
  }

  .toggle-splash section.third .car-model {
    top: 35vh;
    right: 5vw;
  }

  .fourth-splash-content .car-model-rendered {
    top: 35vh;
    right: 5vw;
  }

  .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 80vmin;
    height: 50vmin;
  }

  .toggle-splash section.third ul {
    bottom: 8vh;
  }

  .toggle-splash section.fifth .slider {
    bottom: 10vh;
  }

  .toggle-splash section.six .ar-icon {
    top: 15vh;
  }

  .toggle-splash section.six .street {
    bottom: 44vh;
  }

  .toggle-splash section.eight .ecomm {
    height: 40vmin;
    bottom: 50%;
    right: 23vmin;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.5rem;
    top: 76vh;
    right: 30vw;
  }

  .toggle-splash section.eight h1 {
    top: 40vh;
  }

  .toggle-splash section.eight p {
    top: 60vh;
  }
}

@media screen and (min-width: 1536px) and (max-width: 1600px) and (orientation:landscape) {
  .toggle-splash section.first .circles {
    height: 30vmin;
    bottom: 28%;
    transform: translateY(50%);
    right: 7vw;
  }

  .toggle-splash section.first .car-model {
    top: 29vmin;
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 87vmin;
    height: 55vmin;
  }

  .toggle-splash section.third .car-model {
    top: 30vh;
    right: 5vw;
  }

  .toggle-splash section.third ul {
    bottom: 8vh;
  }

  .fourth-splash-content .car-model-rendered {
    top: 35vh;
    right: 5vw;
  }

  .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 87vmin;
    height: 55vmin;
  }

  .toggle-splash section.fifth .slider {
    bottom: 10vh;
  }

  .toggle-splash section.six .ar-icon {
    top: 15vh;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.5rem;
    top: 78vh;
    right: 26vw;
  }

  .toggle-splash section.eight .ecomm {
    height: 45vmin;
    bottom: 40%;
    right: 24vmin;
  }

  .toggle-splash section.eight h1 {
    top: 40vh;
  }

  .toggle-splash section.eight p {
    top: 60vh;
  }
}

@media screen and (min-width: 1920px) and (orientation:landscape) {
  .toggle-splash section h1 {
    font-size: 6rem;
    max-width: 800px;
  }

  .toggle-splash section p {
    font-size: 2.3rem;
    max-width: 600px;
  }

  .toggle-splash section .primary-btn {
    font-size: 1.75rem !important;
    padding: 20px 30px !important
  }

  .toggle-splash section.first .car-model {
    top: 57%;
    transform: translateY(-50%);
  }

  .toggle-splash section.first .circles {
    height: 28vmin;
    top: 72%;
    bottom: auto;
    transform: translateY(-50%);
  }

  .toggle-splash section.first .car-model, .toggle-splash section.first .car-model img, .toggle-splash section.third .car-model, .toggle-splash section.third .car-model img {
    width: 80vmin;
    height: 50vmin;
  }

  .toggle-splash section.third .car-model {
    top: 30vh;
    right: 5vw;
  }

  .toggle-splash .fourth-splash-content .car-model-rendered {
    top: 23vh;
    right: 5vw;
  }

  .toggle-splash .fourth-splash-content .car-model-rendered, .fourth-splash-content .car-model-rendered img {
    width: 80vmin;
    height: 50vmin;
  }

  .toggle-splash section.six .street {
    bottom: 54vh;
    height: 750px;
  }

  .toggle-splash section.seven .bubble {
    height: 60px;
  }

  .toggle-splash section.seven .publish-btn {
    font-size: 1.7rem;
    top: 77vh;
    right: 26vw;
  }

  .toggle-splash section.eight .ecomm {
    height: 50vmin;
    top: auto;
    bottom: 44%;
    right: 17vmin;
  }
}

/* Mobile Portrait */
@media screen and (max-width: 768px) and (orientation: portrait) {

  .toggle-splash .mobile-only {
    display: block;
  }

  .toggle-splash .desktop-only {
    display: none;
  }

  .toggle-splash header {
    gap: 10px;
  }

  .toggle-splash header img {
    height: 20px;
    margin: 0;
  } 

  .toggle-splash header ul li {
    font-size: 1rem;
    padding: 0 15px;
  }

  .toggle-splash header ul li.header-logo {
    display: flex;
    flex-direction: column;
    gap:3px;
  }

  .toggle-splash header ul li.sign-up {
    font-size: 1rem;
    margin: 0 15px 0 0;
  }

  .toggle-splash header ul li.sign-up .primary-btn{
    padding: 6px 9px;
  }

  .toggle-splash .sign-up-btn-white{
    background-color: #fff !important;
    color: #000 !important;
    padding: 12px 15px !important;
    width: 60vw !important;
  }

  .toggle-splash .sign-up-btn-white .fill{
    background-color: #fff !important;
  }

  .toggle-splash header ul li.login {
    margin: 0 15px 0 0;
    padding: 0;
    font-size: 1rem;
  }

  .toggle-splash .toggle-splash header ul li.investors {
    margin: 0 15px 0 auto;
    font-size: 1rem;
  }

  .toggle-splash .first-splash-content section:nth-child(2) {
    left: 0;
  }

  .toggle-splash .first-splash-content {
    width: 100vw;
    height: 100vh !important;
  }

  .toggle-splash section.first .car-model {
    position: absolute;
    top: 40vh;
    right: 3vw;
    width: 95vw;
    height: 60vw;
  }

  .toggle-splash section.first .car-model img {
    width: 95vw;
    height: 60vw;
    position: absolute;
    top: 0;
    right: 0;
  }

  .toggle-splash section h1 {
    font-size: 2.8rem;
    top: 30vmin !important;
    left: 0 !important;
    max-width: 100% !important;
    padding: 0 5vw !important;
    text-align: center !important;
  }

  .toggle-splash section p {
    font-size: 1.2rem;
    bottom: 10vmin !important;
    left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    text-align: center !important;
    padding: 0 10vw !important;
  }

  .toggle-splash section.first h1 {
    width: 100%;
  }

  .toggle-splash section.first .primary-btn {
    margin: 20px auto 0 !important;
    padding: 12px 40px !important;
    font-size: 1.1rem;
  }

  .toggle-splash section.first .primary-btn .fill {
    display: none;
  }

  .toggle-splash section.first .circles {
    position: absolute;
    right: 5vw !important;
    height: 34vw !important;
  }

  .toggle-splash .second-splash-content {
    height: auto !important;
  }

  .toggle-splash .second-splash-content ul {
    justify-content: flex-start;
    align-items: flex-start !important;
    height: auto !important;
    padding: 5vh 0 !important;
    gap: 10px !important;
  }

  .toggle-splash .second-splash-content ul li {
    text-align: center !important;
  }

  .toggle-splash .second-splash-content ul li p {
    font-size: 1rem;
  }

  .toggle-splash .second-splash-content ul li img {
    height: 19vw  !important;
    width: 70%  !important;
    max-width: none  !important;
  }

  .toggle-splash .third-splash-content {
    width: 100vw  !important;
    flex-wrap: wrap  !important;
    height: auto  !important;
    overflow: visible  !important;
  }

  .toggle-splash .third-splash-content .circles-grid {
    display: none;
  }

  .toggle-splash section.third {
    transform: none;
    opacity: 1;
    z-index: 1;
    position: relative;
    height: 100vh;
  }

  .toggle-splash section.third h1, 
  .toggle-splash section.fourth h1, 
  .toggle-splash section.fifth h1, 
  .toggle-splash section.six h1, 
  .toggle-splash section.seven h1, 
  .toggle-splash section.eight h1 {
    max-width: none;
    transform: none;
    font-size: 2.3rem;
    text-align: center;
    padding: 0 5vw  !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative;
    transform: none;
  }

  .toggle-splash section.third p, 
  .toggle-splash section.fourth p, 
  .toggle-splash section.fifth p, 
  .toggle-splash section.six p, 
  .toggle-splash section.seven p,
  .toggle-splash section.eight p {
    font-size: 1.3rem;
    margin: 7px 0 0  !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative;
    transform: none;
  }

  .toggle-splash .third-text, 
  .toggle-splash .fourth-text, 
  .toggle-splash .fifth-text, 
  .toggle-splash .six-text, 
  .toggle-splash .seven-text, 
  .toggle-splash .eight-text {
    position: absolute;
    bottom: 10vh !important;
    top: auto !important;
    right: auto !important;
    transform: none !important;
    width: 100% !important;
  }

  .toggle-splash section.third .load-btn {
    display: none;
  }

  .toggle-splash section.third ul {
    right: 50%  !important;
    bottom: 38vh  !important;
    transform: translateX(50%)  !important;
    top: auto !important;
    left: auto !important
  }

  .toggle-splash section.third ul li img {
    height: 30px;
  }

  .toggle-splash section.third .car-model-cad {
    opacity: 1;
    width: 120vw  !important;
    height: 76vw  !important;
    transform: none  !important;
    top: 15vh !important;
    left: -10vw !important;
    right: auto  !important
  }

  .toggle-splash section.third .car-model-cad img {
    width: 120vw !important;
    height: 76vw !important;
  }

  .toggle-splash section.fourth, 
  .toggle-splash section.fifth {
    position: relative;
    transform: none;
    height: 100vh;
  }

  .toggle-splash section.fourth ul {
    top: 12vh !important;
    right: 50% !important;
    transform: translateX(50%) !important;
    left: auto !important;
  }

  .toggle-splash section.fourth ul li img {
    height: 40px;
  }

  .toggle-splash section.fourth .color-picker {
    bottom: auto !important;
    top: 24vh !important;
    right: 12vw !important;
    left: auto  !important;
    transform: none  !important;
  }

  .toggle-splash section.fourth .color-picker .color-picker-pointer {
    height: 14px;
    width: 14px;
  }

  .toggle-splash section.fourth .color-picker img {
    height: 80px;
  }

  .toggle-splash section.fourth .car-model-shading {
    opacity: 1;
    width: 120vw !important;
    height: 76vw !important;
    left: -10vw !important;
    transform: none !important;
    top: 20vh !important;
    display: block;
    position: absolute;
  }

  .toggle-splash section.fourth .car-model-shading img {
    width: 120vw !important;
    height: 76vw !important;
  }

  .toggle-splash section.fifth .slider {
    bottom: auto !important;
    top: 16vh !important;
    width: 30vw !important;
    right: auto !important;
    left: 10vw !important;
    z-index: 3;
  }

  .toggle-splash section.fifth .lighting {
    width: 35vw !important;
    opacity: 1;
    top: 23vh !important;
    left: auto !important;
    right: 10vw !important;
    z-index: 3;
  }

  .toggle-splash section.fifth .car-model-rendered {
    opacity: 1;
    width: 120vw !important;
    height: 76vw !important;
    transform: none !important;
    top: 20vh !important;
    display: block;
    position: absolute;
    left: -10vw !important;
  }

  .toggle-splash section.fifth .car-model-rendered img {
    width: 120vw !important;
    height: 76vw !important;
  }

  .toggle-splash .fourth-splash-content {
    width: 100vw;
    flex-wrap: wrap;
    height: auto;
    overflow: visible;
  }

  .toggle-splash .fourth-splash-content .circles-grid {
    display: none;
  }

  .toggle-splash .fourth-splash-content .car-model-rendered {
    width: 120vw !important;
    height: 76vw !important;
    transform: none !important;
    top: 20vh !important;
    left: -10vw !important;
  }

  .toggle-splash .fourth-splash-content .car-model-rendered img {
    width: 120vw !important;
    height: 76vw !important;
  }

  .toggle-splash section.six {
    position: relative;
    height: 100vh;
  }

  .toggle-splash section.six .ar-icon {
    top: 10vh !important;
    right: 50% !important;
    transform: translateX(50%) !important;
  }

  .toggle-splash section.six .street {
    height: 53vh !important;
    transform: none !important;
    top: 13vh !important;
    right: 0 !important;
    left: auto !important;
    bottom: auto !important;
  }

  .toggle-splash section.seven {
    position: relative;
    transform: none;
    height: 100vh;
  }

  .toggle-splash section.seven .bubble {
    opacity: 1;
    transform: none;
    height: 40px;
    z-index: 3;
  }

  .toggle-splash section.seven .bubble-1 {
    top: 15vh !important;
  }

  .toggle-splash section.seven .bubble-2 {
    top: 32vh !important;
    right: 10vw !important;
  }

  .toggle-splash section.seven .car-model-rendered {
    display: block;
    top: 18vh;
  }

  .toggle-splash section.seven .publish-btn {
    opacity: 1;
    transform: none !important;
    z-index: 3;
    font-size: 1.1rem;
    right: 50% !important;
    transform: translateX(50%) !important;
    top: 60vh !important;
    bottom: auto !important;
  }

  .toggle-splash section.eight {
    position: relative;
    transform: none;
  }

  .toggle-splash section.eight .ecomm {
    top: 18vh !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    width: 90% !important;
    height: auto !important;
  }

  .toggle-splash section.eight .car-model-rendered {
    display: block;
    top: 15vh !important;
    transform: scale(0.5) !important;
  }

  .toggle-splash .eight-text {
    text-align: center;
    bottom: 17vmin !important;
  }

  .toggle-splash .fourth-splash-content .primary-btn:not(.desktop-only) {
    opacity: 1;
    margin: 20px auto 0 !important;
    transform: none !important;
    position: static !important;
    white-space: nowrap;
    font-size: 1.1rem !important;
    display: inline-block !important;
  }

  .toggle-splash footer {
    margin: 0;
  }

  .toggle-splash footer div {
    display: flex;
    flex-direction: column;
    transform: none;
  }
}

/* Mobile: Landscape */
@media screen and (max-height: 420px) and (orientation: landscape) {

  .toggle-splash .sign-up-btn-container{
    position: absolute;
    text-align: left;
  }

  .toggle-splash .sign-up-btn-container .primary-btn{
    bottom: 0px !important;
    left: 0px !important;
    margin: 10px 0 0 !important;
  }

  .toggle-splash .mobile-only {
    display: block;
  }

  .toggle-splash .desktop-only {
    display: none;
  }

  .toggle-splash header {
    gap: 30px;
  }

  .toggle-splash header img {
    height: 25px;
    margin: 0 0 0 5vw;
  } 

  .toggle-splash header ul li {
    font-size: 1rem;
    padding: 0 15px;
  }

  .toggle-splash header ul li.sign-up {
    font-size: 1rem;
    margin: 0 5vw 0 0;
  }
  
  .toggle-splash header ul li.login {
    padding: 0;
    font-size: 1rem;
  }

  .toggle-splash .first-splash-content section:nth-child(2) {
    left: 0;
  }

  .toggle-splash section.first .car-model {
    position: absolute;
    top: 45% !important;
    bottom: auto !important;
    right: 5vw !important;
    width: 40vw !important;
    height: 25vw !important;
    z-index: 1;
    transform: translateY(-50%);
  }

  .toggle-splash section.first h1 {
    top: 20vmin !important;
  }

  .toggle-splash section.first p {
    max-width: 30%;
  }

  .toggle-splash section.first .car-model img {
    width: 40vw !important;
    height: 25vw  !important;
    position: absolute;
    top: 0;
    right: 0;
  }

  .toggle-splash .first-splash-content {
    width: 100vw;
    height: 80vh;
  }

  .toggle-splash section h1 {
    font-size: 2.2rem !important;
    top: 17vh !important;
    left: 5vw !important;
    max-width: none;
    width: 100%;
    padding: 0;
    z-index: 4;
  }

  .toggle-splash section p {
    font-size: 1rem !important;
    bottom: 28vh;
    left: 5vw !important;
    text-align: left;
  }

  .toggle-splash section.first .primary-btn {
    margin: 10px 0 0;
    padding: 10px 30px;
    font-size: .9rem !important;
    color: var(--primaryColor);
  }

  .toggle-splash .sign-up-btn-white{
    background-color: #fff !important;
    color: #000 !important;
    padding: 12px 15px !important;
    width: 20vw !important;
  }

  .toggle-splash .sign-up-btn-white .fill{
    background-color: #fff !important;
  }

  .toggle-splash section.first .primary-btn .fill {
    display: none;
  }

  .toggle-splash section.first .circles {
    position: absolute;
    top: auto !important;
    bottom: 27% !important;
    right: 7vw !important;
    height: auto !important;
    width: 36vmax !important;
    filter: invert(1);
  }

  .toggle-splash .second-splash-content {
    height: auto;
  }

  .toggle-splash .second-splash-content ul {
    justify-content: flex-start;
    align-items: center;
    height: auto;
    padding: 10vh 5vw !important;
    gap: 20px !important;
  }

  .toggle-splash .second-splash-content ul li {
    text-align: center !important;
  }

  .toggle-splash .second-splash-content ul li p {
    font-size: 1.1rem !important;
  }

  .toggle-splash .second-splash-content ul li img {
    height: auto;
    width: 50%;
    max-width: none;
  }

  .toggle-splash .third-splash-content {
    width: 100vw;
    flex-wrap: wrap;
    height: auto;
    overflow: visible;
  }

  .toggle-splash .third-splash-content .circles-grid {
    display: none;
  }

  .toggle-splash section.third {
    transform: none;
    opacity: 1;
    z-index: 1;
    position: relative;
    height: 100vh;
  }

  .toggle-splash section.third h1, 
  .toggle-splash section.fourth h1, 
  .toggle-splash section.fifth h1, 
  .toggle-splash section.six h1, 
  .toggle-splash section.seven h1, 
  .toggle-splash section.eight h1 {
    max-width: none;
    transform: none;
    font-size: 2.2rem !important;
    text-align: left;
    padding: 0 !important;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative;
    transform: none;
  }

  .toggle-splash section.third h1 br {
    content: ""; 
    display: none;
  }

  .toggle-splash section.third p, 
  .toggle-splash section.fourth p, 
  .toggle-splash section.fifth p, 
  .toggle-splash section.six p, 
  .toggle-splash section.seven p, 
  .toggle-splash section.eight p {
    font-size: 1.2rem !important;
    text-align: left;
    width: 100%;
    padding: 0 !important;
    margin: 7px 0 0 !important;
    max-width: none;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative;
    transform: none;
  }

  .toggle-splash .third-text, 
  .toggle-splash .fourth-text, 
  .toggle-splash .fifth-text, 
  .toggle-splash .six-text, 
  .toggle-splash .seven-text, 
  .toggle-splash .eight-text {
    position: absolute;
    top: 50%;
    right: 5vw;
    width: 45vw;
    transform: translateY(-50%);
  }

  .toggle-splash section.third .load-btn {
    display: none;
  }

  .toggle-splash section.third ul {
    right: auto !important;
    left: 18vw !important;
    bottom: auto !important;
    top: 75vh !important;
    transform: translateX(50%) !important;
  }

  .toggle-splash section.third ul li img {
    height: 30px;
  }

  .toggle-splash section.third .car-model-cad {
    opacity: 1;
    width: 40vw !important;
    height: 25vw !important;
    transform: none !important;
    top: 20vh !important;
    left: 11vw !important;
  }

  .toggle-splash section.third .car-model-cad img {
    width: 40vw !important;
    height: 25vw !important;
  }

  .toggle-splash section.fourth, 
  .toggle-splash section.fifth {
    position: relative;
    transform: none;
    height: 100vh;
  }

  .toggle-splash section.fourth ul {
    top: 27vh !important;
    right: auto !important;
    transform: none !important;
    left: 10vw !important;
  }

  .toggle-splash section.fourth ul li img {
    height: 24px;
  }

  .toggle-splash section.fourth .color-picker {
    bottom: auto !important;
    top: 30vh !important;
    right: auto !important;
    left: 36vw !important;
  }

  .toggle-splash section.fourth .color-picker .color-picker-pointer {
    height: 14px;
    width: 14px;
  }

  .toggle-splash section.fourth .color-picker img {
    height: 80px;
  }

  .toggle-splash section.fourth .car-model-shading {
    opacity: 1;
    width: 40vw !important;
    height: 25vw !important;
    left: 11vw !important;
    transform: none !important;
    top: 34vh !important;
    display: block;
    position: absolute;
  }

  .toggle-splash section.fourth .car-model-shading img {
    width: 40vw !important;
    height: 25vw !important;
  }

  .toggle-splash section.fifth .slider {
    bottom: auto !important;
    top: 22vh !important;
    width: 16vw !important;
    right: auto !important;
    left: 7vw !important;
    z-index: 3;
  }

  .toggle-splash section.fifth .lighting {
    width: 17vw !important;
    opacity: 1;
    top: 30vh !important;
    left: 29vw !important;
    right: auto !important;
    z-index: 3;
  }

  .toggle-splash section.fifth .car-model-rendered {
    opacity: 1;
    width: 40vw !important;
    height: 25vw !important;
    transform: none !important;
    top: 34vh !important;
    display: block;
    position: absolute;
    left: 11vw !important;
  }

  .toggle-splash section.fifth .car-model-rendered img {
    width: 40vw !important;
    height: 25vw !important;
  }

  .toggle-splash .fourth-splash-content {
    width: 100vw;
    flex-wrap: wrap;
    height: auto;
    overflow: visible;
  }

  .toggle-splash .fourth-splash-content .circles-grid {
    display: none;
  }

  .toggle-splash .fourth-splash-content .car-model-rendered {
    width: 40vw !important;
    height: 25vw !important;
    transform: none !important;
    top: 28vh !important;
    left: 11vw !important;
  }

  .toggle-splash .fourth-splash-content .car-model-rendered img {
    width: 40vw !important;
    height: 25vw !important;
  }

  .toggle-splash section.six {
    position: relative;
    height: 100vh;
  }

  .toggle-splash section.six .ar-icon {
    top: 10vh !important;
    right: auto !important;
    left: 24vw !important;
    transform: none !important;
    height: 40px !important;
  }

  .toggle-splash section.six .street {
    height: 52vmax !important;
    top: 52% !important;
    right: auto !important;
    left: 0 !important;
    bottom: auto !important;
    transform: translate(-24vmax, -50%) !important;
  }

  .toggle-splash section.seven {
    position: relative;
    transform: none;
    height: 100vh;
  }

  .toggle-splash section.seven .bubble {
    opacity: 1;
    transform: none;
    height: 35px;
    z-index: 3;
  }

  .toggle-splash section.seven .bubble-1 {
    top: 22vh !important;
    left: 5vw !important;
  }

  .toggle-splash section.seven .bubble-2 {
    top: 37vh !important;
    right: 54vw !important;
  }

  .toggle-splash section.seven .car-model-rendered {
    display: block;
    top: 24vh !important;
  }

  .toggle-splash section.seven .publish-btn {
    opacity: 1;
    transform: none;
    z-index: 3;
    font-size: .8rem;
    right: 65vw;
    transform: none;
    top: auto;
    bottom: 15vh !important;
  }

  .toggle-splash section.eight {
    position: relative;
    transform: none;
  }

  .toggle-splash section.eight .ecomm {
    top: 17.5vh !important;
    left: 6vw !important;
    transform: none !important;
    width: auto !important;
    height: 65vh !important;
    max-width: 45%;
    object-fit: contain;
  }

  .toggle-splash section.eight .car-model-rendered {
    display: block;
    top: 22vh !important;
    transform: scale(0.7) !important;
    left: 7vw !important;
  }

  .toggle-splash .fourth-splash-content .primary-btn:not(.desktop-only) {
    opacity: 1;
    margin: 15px 0 0 !important;
    transform: none !important;
    position: static !important;
    white-space: nowrap;
    font-size: 1rem !important;
    display: inline-block !important;
  }

  .toggle-splash footer {
    margin: 40px 0 0;
  }

  .toggle-splash footer div {
    display: flex;
    flex-direction: column;
    transform: none;
  }
}

/* Disable animations -- pretty much same as mobile disabling of transforms/opacity */
@media screen {

  .toggle-splash.no-animations {
    background-color: var(--lightBackgroundColor);
  }

  .toggle-splash.no-animations .sign-up-btn-container{
    position: absolute;
    text-align: left;
  }
  
  .toggle-splash.no-animations .sign-up-btn-container .primary-btn{
    bottom: 0px !important;
    left: 0px !important;
  }
  
  .toggle-splash.no-animations .mobile-only {
    display: block;
  }
  
  .toggle-splash.no-animations .desktop-only {
    display: none;
  }
  
  .toggle-splash.no-animations .first-splash-content section:nth-child(2) {
    left: 0;
  }
  
  .toggle-splash.no-animations section.first .car-model {
    position: absolute;
    top: auto;
    bottom: 28vh;
  }
  
  .toggle-splash.no-animations .first-splash-content {
    width: 100vw;
    height: 80vh;
  }
  
  .toggle-splash.no-animations section h1 {
    top: 20vh;
    left: 0;
    max-width: 98vmin;
    width: 100%;
    padding: 0 5vmin;
    text-align: center;
    z-index: 4;
  }
  
  .toggle-splash.no-animations section p {
    bottom: 28vh;
    left: 0;
    text-align: left;
    padding: 0 5vmin;
    max-width: 75vmin;
  }
  
  .toggle-splash.no-animations section.first h1 {
    text-align: left;
  }
  
  .toggle-splash.no-animations section.first .primary-btn {
    margin: 20px 0 0;
    padding: 15px 30px;
    color: var(--primaryColor);
  }
  
  .toggle-splash.no-animations section.first .primary-btn .fill {
    display: none;
  }
  
  .toggle-splash.no-animations section.first .circles {
    position: absolute;
    bottom: 25vh;
    right: 5vw;
    filter: invert(1);
  }
  
  .toggle-splash.no-animations .second-splash-content {
    height: auto;
  }
  
  .toggle-splash.no-animations .second-splash-content ul {
    justify-content: flex-start;
    align-items: center;
    height: auto;
    padding: 10vh 5vw;
    gap: 20px;
  }
  
  .toggle-splash.no-animations .second-splash-content ul li {
    text-align: center !important;
  }

  .toggle-splash.no-animations .second-splash-content ul li img {
    height: auto;
    width: 50%;
    max-width: none;
  }
  
  .toggle-splash.no-animations .third-splash-content {
    width: 100vw;
    flex-wrap: wrap;
    height: auto;
    overflow: visible;
  }
  
  .toggle-splash.no-animations .third-splash-content .circles-grid {
    display: none;
  }
  
  .toggle-splash.no-animations section.third {
    transform: none;
    opacity: 1;
    z-index: 1;
    position: relative;
    height: 100vh;
  }
  
  .toggle-splash.no-animations section.third h1, .toggle-splash.no-animations section.fourth h1, .toggle-splash.no-animations section.fifth h1, .toggle-splash.no-animations section.six h1, .toggle-splash.no-animations section.seven h1, .toggle-splash.no-animations section.eight h1 {
    max-width: none;
    transform: none;
    text-align: left;
    padding: 0;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative;
    transform: none;
  }
  
  .toggle-splash.no-animations section.third h1 br {
    content: ""; 
    display: none;
  }
  
  .toggle-splash.no-animations section.third p, .toggle-splash.no-animations section.fourth p, .toggle-splash.no-animations section.fifth p, .toggle-splash.no-animations section.six p, .toggle-splash.no-animations section.seven p, .toggle-splash.no-animations section.eight p {
    text-align: left;
    width: 100%;
    padding: 0;
    margin: 15px 0 0;
    max-width: 700px;
    top: auto !important;
    left: auto !important;
    right: auto !important;
    bottom: auto !important;
    position: relative;
    transform: none;
  }
  
  .toggle-splash.no-animations .third-text, 
  .toggle-splash.no-animations .fourth-text, 
  .toggle-splash.no-animations .fifth-text, 
  .toggle-splash.no-animations .six-text, 
  .toggle-splash.no-animations .seven-text, 
  .toggle-splash.no-animations .eight-text {
    position: absolute;
    top: 50%;
    right: 5vw;
    width: 38vw;
    transform: translateY(-50%);
  }
  
  .toggle-splash.no-animations section.third .load-btn {
    display: none;
  }
  
  .toggle-splash.no-animations section.third ul {
    right: auto;
    bottom: auto;
    top: 67vmin;
    left: 25%;
    transform: translate(-50%, 100%);
  }

  .toggle-splash.no-animations section.third .car-model-cad {
    opacity: 1;
    width: 40vw;
    height: 25vw;
    transform: translateY(-50%);
    top: 50%;
    left: 5vw;
  }
  
  .toggle-splash.no-animations section.third .car-model-cad img {
    width: 40vw;
    height: 25vw;
  }
  
  .toggle-splash.no-animations section.fourth, .toggle-splash.no-animations section.fifth {
    position: relative;
    transform: none;
    height: 100vh;
  }
  
  .toggle-splash.no-animations section.fourth ul {
    top: 28vh;
    right: auto;
    transform: none;
    left: 5vw;
  }

  .toggle-splash.no-animations section.fourth .color-picker {
    bottom: auto;
    top: 55vh;
    right: auto;
    left: 35%;
    transform: translate(-50%, 2%);
  }

  .toggle-splash.no-animations section.fourth .car-model-shading {
    opacity: 1;
    width: 40vw;
    height: 25vw;
    left: 5vw;
    transform: translateY(-50%);
    top: 52%;
    display: block;
    position: absolute;
  }
  
  .toggle-splash.no-animations section.fourth .car-model-shading img {
    width: 40vw;
    height: 25vw;
  }
  
  .toggle-splash.no-animations section.fifth .slider {
    bottom: auto;
    top: 31vh;
    width: 16vw;
    right: auto;
    left: 7vw;
    z-index: 3;
  }
  
  .toggle-splash.no-animations section.fifth .lighting {
    opacity: 1;
    top: 58vh;
    left: 30vw;
    right: auto;
    z-index: 3;
  }
  
  .toggle-splash.no-animations section.fifth .car-model-rendered {
    opacity: 1;
    width: 40vw;
    height: 25vw;
    transform: translateY(-50%);
    top: 52%;
    display: block;
    position: absolute;
    left: 5vw;
  }
  
  .toggle-splash.no-animations section.fifth .car-model-rendered img {
    width: 40vw;
    height: 25vw;
  }
  
  .toggle-splash.no-animations .fourth-splash-content {
    width: 100vw;
    flex-wrap: wrap;
    height: auto;
    overflow: visible;
  }
  
  .toggle-splash.no-animations .fourth-splash-content .circles-grid {
    display: none;
  }
  
  .toggle-splash.no-animations .fourth-splash-content .car-model-rendered {
    width: 40vw;
    height: 25vw;
    transform: translateY(-50%);
    top: 50vh;
    left: 5vw;
  }
  
  .toggle-splash.no-animations .fourth-splash-content .car-model-rendered img {
    width: 40vw;
    height: 25vw;
  }
  
  .toggle-splash.no-animations section.six {
    position: relative;
    height: 100vh;
  }
  
  .toggle-splash.no-animations section.six .ar-icon {
    top: 24vh;
    right: auto;
    left: 24vw;
    transform: translateY(-100%);
  }
  
  .toggle-splash.no-animations section.six .street {
    height: 58vw;
    top: 50%;
    right: auto;
    left: -38vw;
    bottom: auto;
    transform: translateY(-50%);
  }
  
  .toggle-splash.no-animations section.seven {
    position: relative;
    transform: none;
    height: 100vh;
  }
  
  .toggle-splash.no-animations section.seven .bubble {
    opacity: 1;
    transform: none;
    z-index: 3;
  }
  
  .toggle-splash.no-animations section.seven .bubble-1 {
    top: 32vh;
    left: 5vw;
  }
  
  .toggle-splash.no-animations section.seven .bubble-2 {
    top: 37vh;
    right: 54vw;
  }
  
  .toggle-splash.no-animations section.seven .car-model-rendered {
    display: block;
    top: 50vh;
    left: 5vw;
  }
  
  .toggle-splash.no-animations section.seven .publish-btn {
    opacity: 1;
    transform: none;
    z-index: 3;
    right: 72vw;
    transform: none;
    top: auto;
    bottom: 25vh;
  }
  
  .toggle-splash.no-animations section.eight {
    position: relative;
    transform: none;
    height: 100vh;
  }
  
  .toggle-splash.no-animations section.eight .ecomm {
    top: 53vh;
    left: 6vw;
    transform: translateY(-50%);
    width: 40%;
    height: auto;
  }
  
  .toggle-splash.no-animations section.eight .car-model-rendered {
    display: block;
    top: 50vh;
    transform: scale(0.7) translateY(-70%);
    left: 7vw;
  }
  
  .toggle-splash.no-animations .fourth-splash-content .primary-btn {
    opacity: 1;
    position: static;
    display: inline-block;
    margin: 20px 0 0;
    transform: none;
  }
  
  .toggle-splash.no-animations footer div {
    display: flex;
    flex-direction: column;
    transform: none;
  }
}