.toggle-sharing-modal-content {
  border: 1px solid var(--lightBorderColor);
  border-radius: 10px;
  padding: var(--halfPadding);
  margin: 0 0 var(--halfPadding);
}

.toggle-sharing-value {
  display: flex;
  align-items: center;
  gap: var(--standardPadding);
  margin: var(--halfPadding) 0;
}

.toggle-sharing-value input {
  flex: 1;
}

.toggle-sharing-value button {
  flex: initial;
  width: 12%;
}

.toggle-sharing-modal-content .toggle-outline-btn {
  margin: var(--quarterPadding) 0 var(--standardPadding);
}