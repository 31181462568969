.wrap-circles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.circle {
    position: relative;
    width: 150px;
    height: 150px;
    margin: 0 0 5px 0;
    border-radius: 50%;
    overflow: hidden;
}

.circle .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 115px;
    height: 115px;
    background: var(--pageColor);
    border-radius: 50%;
    font-size: 1.85em;
    font-weight: 300;
    color: var(--brandPrimaryColor);
    z-index: 99999999;
}