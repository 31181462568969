.files-list,
.error-messages {
    padding: 5px 10px;

    letter-spacing: normal;
}

.files-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid var(--lightBorderColor);
}

.divider {
    border: 1px dashed var(--lightOutlineColor);
    margin-top: 20px;
    margin-bottom: 20px;
}

.files-list .valid {
    color: var(--primaryColor)
}

.files-list .invalid {
    color: var(--orangeColor)
}

.files-list .valid,
.files-list .invalid {
    display: block;
    text-overflow: ellipsis;
    white-space: pre;
    /* width: 200px; */
    overflow: hidden;
}