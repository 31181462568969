.threedy-lab-spinner-wrapper {
  position: relative;
  height: 30vh;
}

.threedy-lab-spinner-wrapper.absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.threedy-lab-spinner-wrapper.full-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.threedy-lab-spinner-wrapper.small {
  height: 5vw;
}

.threedy-lab-spinner-wrapper.fixed-size {
  height: 100%;
  width: 100%;
  max-height: 106px;
  max-width: 106px;
  margin: 0 auto;
}

.threedy-lab-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(255,255,255,.9);
  background-color: rgba(0, 3, 51, .8);
}

.threedy-lab-spinner-container.inline {
  position: relative;
  background-color: var(--pageColor);
  z-index: 3;
}

.threedy-lab-spinner-wrapper.full-cover .threedy-lab-spinner-container.inline svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.threedy-lab-spinner-wrapper.fixed-size .threedy-lab-spinner-container.inline svg { 
  width: 90px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.threedy-lab-spinner-wrapper.small .threedy-lab-spinner-container.inline svg {
  width: 8vw;
}

.threedy-lab-spinner-wrapper.medium .threedy-lab-spinner-container.inline svg {
  width: 70%;
}

.threedy-lab-spinner-container.inline svg {
  position: relative;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 20vw;
}

.threedy-lab-spinner-container svg {
  width: 40vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.svg-edge {
  fill: none;
  stroke-dasharray: 250;
  stroke-dashoffset: 250;
}

.right-main, .left-tail, .right-tail {
  stroke: #A12AFC;
}

.left-main, .top-tail {
  stroke: #571FFF; 
}

@keyframes textSheen {
  0% {
    color: var(--primaryColor);
  }
  50% {
    color: #A12AFC;
  }
  100% {  
    color: var(--primaryColor);
  }
}

.threedy-lab-spinner-copy {
  color: var(--primaryColor);
  text-align: center;
  padding: 0 5px;
  position: absolute;
  bottom: 5px;
  left: 0;
  width: 100%;
  z-index: 4;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  animation: textSheen 3.5s infinite ease forwards;
}