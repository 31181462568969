.toggle-dropdown-component {
  position: relative;
  width: 100%;
  margin: var(--doublePadding) 0 var(--standardPadding);
  max-width: 300px;
}

.toggle-dropdown-component.disabled {
  pointer-events: none;
  opacity: .4;
}

.toggle-dropdown-component:not(.expanded):hover {
  cursor: pointer;
}

.toggle-dropdown-component-header {
  display: flex;
  align-items: center;
  gap: var(--doublePadding);
}

.toggle-dropdown-component-header .toggle-dropdown-component-header-label {
  margin: 0;
}

.toggle-dropdown-component-header h6:not(.toggle-dropdown-component-header-label) {
  flex: 1;
  margin: 0 0 0 auto;
  background-color: var(--lightBackgroundColor);
  border-radius: 10px;
  padding: var(--quarterPadding) var(--quarterPadding) var(--quarterPadding) var(--halfPadding);
  text-align: left;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}

.toggle-dropdown-component:not(.expanded):hover .toggle-dropdown-component-header h6:not(.toggle-dropdown-component-header-label) { 
  border-color: var(--brandPrimaryColor);
}

.toggle-dropdown-component-header h6.no-margin {
  margin: 0;
}

.toggle-dropdown-component-header h6 span {
  margin: 0 0 0 auto;
  display: block;
}

.toggle-dropdown-component-expanded {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--pageColor);
  width: 65%;
  border-radius: 6px;
  z-index: 444;
  box-shadow: 0px 8px 8px rgba(111,111,111,.1);
  overflow: hidden;
}

.toggle-dropdown-component-expanded.left {
  right: auto;
  left: 0;
}

.toggle-dropdown-component-expanded ul {
  flex-direction: column;
}

.toggle-dropdown-component-expanded ul li {
  width: 100%;
  padding: var(--halfPadding);
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
  overflow: hidden;
}

.toggle-dropdown-component-expanded ul li h6 {
  margin: 0;
}

.toggle-dropdown-component-expanded ul li.disabled {
  pointer-events: none;
  opacity: .4;
}

.toggle-dropdown-component-expanded ul li .option-thumb {
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 100%;
  background-color: var(--backgroundColor);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 200%;
  display: block;
}

.toggle-dropdown-component-expanded ul li:hover {
  cursor: pointer;
  color: var(--brandPrimaryColor);
  background-color: var(--brandTertiaryColor);
}

.toggle-dropdown-component-expanded button {
  border-top: 1px solid var(--lightestTextColor);
  width: 100%;
  padding: 15px;
  margin: 10px 0 0;
  color: var(--lightestTextColor);
}