.toggle-material-library-categories-dropdown {
  width: 10vw;
  border-right: 1px solid var(--lightBorderColor);
  padding: calc(var(--quarterPadding)*1.5) calc(var(--quarterPadding)*2);
  position: relative;
}

.toggle-material-library-categories-dropdown-btn {
  width: 100%;
}

.toggle-material-library-categories-dropdown-btn h5 {
  font-size: 1rem;
  margin: 0;
  display: flex;
  align-items: center;
}

.toggle-material-library-categories-dropdown-btn h5 span {
  display: block;
  margin: 0 0 0 auto;
}

.toggle-material-library-categories-expanded {
  top: calc(-2 * var(--quarterPadding));
  width: 20vw;
  position: absolute;
  left: var(--quarterPadding);
  transform: translateY(-100%);
  background-color: var(--pageColor);
  border-radius: 8px;
  box-shadow: 0 4px 5px rgba(111,111,111,.1);
}

.toggle-material-library-categories-expanded-header {
  background-color: var(--lightBlueBackgroundColor);
  display: flex;
  align-items: center;
  padding: var(--halfPadding);
}

.toggle-material-library-categories-expanded-header h5 {
  font-size: 1rem;
  margin: 0;
}

.toggle-material-library-categories-expanded-header button {
  margin: 0 0 0 auto;
  display: block;
  font-size: 1.25rem;
}

.toggle-material-library-categories-expanded ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: var(--quarterPadding);
}

.toggle-material-library-categories-expanded ul li button {
  padding: var(--quarterPadding) var(--halfPadding);
  border-radius: 8px;
}

.toggle-material-library-categories-expanded ul li.selected button {
  background-color: var(--brandLightColor);
  font-weight: 500;
}