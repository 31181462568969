.toggle-sidebar {
  width: 12.5rem;
  width: clamp(12.5rem, 16vw + 1rem, 15.625rem);
  background-color: var(--pageColor);
  border-radius: 10px;
  transition: all .3s ease;
  margin: var(--standardPadding);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.toggle-sidebar.collapsed {
  width: clamp(5.938rem, 0.25vw, 6.875rem);
}

.sidebar-menu-header {
  display: flex;
  justify-content: space-between;
  padding: var(--halfPadding);
  background-color: var(--sidebarHeaderBg);
  align-items: center;
}

.sidebar-menu-header h6 {
  margin: 0;
  font-weight: 400;
}

.toggle-sidebar.collapsed .sidebar-menu-header {
  justify-content: center;
  transform: rotateY(180deg);
}

.sidebar-menu-header .icon-contract-menu:hover {
  cursor: pointer;
  opacity: 0.9;
}

:is(.toggle-sidebar.collapsed) :is(.sidebar-text-label, .sidebar-menu-header>h6) {
  display: none;
}

.toggle-sidebar.collapsed .toggle-sidebar-menu li a, 
.toggle-sidebar.collapsed .toggle-sidebar-menu li button,
.toggle-sidebar.collapsed .toggle-sidebar-lower-menu li {
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  justify-content: center;
}

.toggle-sidebar button {
  font-size: 1rem;
  white-space: nowrap;
}

.toggle-sidebar-menu {
  padding: 0 var(--halfPadding);
  margin: 0;
}

.toggle-sidebar-menu li {
  font-size: .9rem;
  white-space: nowrap;
  padding: 0;
  margin: var(--halfPadding) 0;
  position: relative;
  list-style: none;
}

.toggle-sidebar-menu li a {
  display: flex;
  gap: var(--quarterPadding);
  padding: var(--halfPadding);
  border-radius: 6px;
  width: 100%;
  text-align: left;
  align-items: center;
}

.toggle-sidebar.collapsed .toggle-sidebar-menu li a {
  display: block;
}

.toggle-sidebar-menu li:not(.selected):hover {
  background-color: var(--lightBackgroundColor);
  border-radius: 8px;
}

.toggle-sidebar-menu li:not(.selected):hover a {
  color: var(--brandPrimaryColor);
}

.toggle-sidebar-menu li.selected {
  background-color:var(--highlightColor);
  color: var(--textColor);
  border-radius: 8px;
}

.toggle-sidebar-menu li.selected a, .toggle-sidebar-menu li.selected button {
  pointer-events: none;
  font-weight: 600;
}

.menu-item-without-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
  font-size: .9rem;
  white-space: nowrap;
  padding: var(--halfPadding);
  margin: var(--halfPadding) 0;
  list-style: none;
}

.menu-item-without-link:hover {
  background-color: var(--lightBackgroundColor);
  border-radius: 8px;
  color: var(--brandPrimaryColor);
}

.toggle-sidebar-lower-menu {
  padding: 0 var(--halfPadding);
}

.toggle-sidebar-menu li span:not(.sidebar-text-label) {
  font-size: 1.1rem;
}