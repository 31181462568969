.toggle-editor-tools {
  position: absolute;
  top: var(--standardPadding);
  left: var(--standardPadding);
  z-index: 3;
  display: flex;
  gap: var(--halfPadding);
  align-items: flex-start;
}

.toggle-editor-tools-menu {
  display: flex;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 100px 100px;
  background-color: var(--pageColor);
  overflow: hidden;
  flex-direction: column;
}

.toggle-editor-tools-menu li button {
  padding: var(--halfPadding);
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.toggle-editor-tools-menu li button:hover,
.toggle-editor-tools-menu li button.selected {
  background-color: var(--brandTertiaryColor);
  color: var(--brandPrimaryColor);
}

.toggle-editor-tools-panel {
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04);
  background-color: var(--pageColor);
  border-radius: 8px;
  width: 16rem;
  width: clamp(16rem, 12vw + 1rem, 18.75rem);
}

.toggle-editor-tools-panel-header {
  border-bottom: 1px solid var(--lightBorderColor);
  padding: var(--halfPadding);
  display: flex;
  align-items: center;
}

.toggle-editor-tools-panel-header h6 {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
}

.toggle-editor-tools-panel-header h6 span {
  font-size: 1.5rem;
}

.toggle-editor-tools-panel-header .close-btn {
  margin: 0 0 0 auto;
  font-size: 1.25rem;
  color: var(--lightestTextColor);
}

.toggle-editor-tools-panel-content {
  max-height: 80vh;
  overflow: auto;
}