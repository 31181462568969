@font-face {
    font-family: 'icomoon';
    src:  url('../public/fonts/icomoon.eot?k180nw');
    src:  url('../public/fonts/icomoon.eot?k180nw#iefix') format('embedded-opentype'),
      url('../public/fonts/icomoon.ttf?k180nw') format('truetype'),
      url('../public/fonts/icomoon.woff?k180nw') format('woff'),
      url('../public/fonts/icomoon.svg?k180nw#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-users:before {
    content: "\e94f";
  }
  .icon-backup:before {
    content: "\e900";
  }
  .icon-add:before {
    content: "\e901";
  }
  .icon-apps:before {
    content: "\e902";
  }
  .icon-arrow_back_ios_new:before {
    content: "\e903";
  }
  .icon-arrow_drop_down:before {
    content: "\e904";
  }
  .icon-arrow_drop_up:before {
    content: "\e905";
  }
  .icon-arrow_forward:before {
    content: "\e906";
  }
  .icon-arrow_forward_ios:before {
    content: "\e907";
  }
  .icon-arrow_left:before {
    content: "\e908";
  }
  .icon-arrow_right:before {
    content: "\e909";
  }
  .icon-check_box:before {
    content: "\e90a";
  }
  .icon-check_small:before {
    content: "\e90b";
  }
  .icon-cloud_upload_drop:before {
    content: "\e90c";
  }
  .icon-contract-menu:before {
    content: "\e90d";
  }
  .icon-dashboard_customize:before {
    content: "\e90e";
  }
  .icon-deployed_code:before {
    content: "\e90f";
  }
  .icon-download:before {
    content: "\e910";
  }
  .icon-draft:before {
    content: "\e911";
  }
  .icon-drive_folder_upload:before {
    content: "\e912";
  }
  .icon-edit:before {
    content: "\e913";
  }
  .icon-error:before {
    content: "\e914";
  }
  .icon-exit_to_app:before {
    content: "\e915";
  }
  .icon-favorite:before {
    content: "\e916";
  }
  .icon-favorite-1:before {
    content: "\e917";
  }
  .icon-folder:before {
    content: "\e918";
  }
  .icon-ilustration-folder .path1:before {
    content: "\e919";
  }
  .icon-ilustration-folder .path2:before {
    content: "\e91a";
    margin-left: -1em;
  }
  .icon-ilustration-folder .path3:before {
    content: "\e91b";
    margin-left: -1em;
  }
  .icon-info:before {
    content: "\e91c";
  }
  .icon-ios_share:before {
    content: "\e91d";
  }
  .icon-light_mode:before {
    content: "\e91e";
  }
  .icon-local_library:before {
    content: "\e91f";
  }
  .icon-mail:before {
    content: "\e920";
  }
  .icon-notifications:before {
    content: "\e921";
  }
  .icon-open_in_full:before {
    content: "\e922";
  }
  .icon-play_circle:before {
    content: "\e923";
  }
  .icon-redo:before {
    content: "\e924";
  }
  .icon-remove:before {
    content: "\e925";
  }
  .icon-settings:before {
    content: "\e926";
  }
  .icon-share_windows:before {
    content: "\e927";
  }
  .icon-sort_by_alpha:before {
    content: "\e928";
  }
  .icon-start:before {
    content: "\e929";
  }
  .icon-stroke_partial:before {
    content: "\e92a";
  }
  .icon-undo:before {
    content: "\e92b";
  }
  .icon-upload:before {
    content: "\e92c";
  }
  .icon-view_comfy_alt:before {
    content: "\e92d";
  }
  .icon-view_compact:before {
    content: "\e92e";
  }
  .icon-arrow_back:before {
    content: "\e92f";
  }
  .icon-cancel:before {
    content: "\e930";
  }
  .icon-chevron_left:before {
    content: "\e931";
  }
  .icon-chevron_right:before {
    content: "\e932";
  }
  .icon-close:before {
    content: "\e933";
  }
  .icon-cloud_done:before {
    content: "\e934";
  }
  .icon-cloud_off:before {
    content: "\e935";
  }
  .icon-cloud_upload:before {
    content: "\e936";
  }
  .icon-colorize:before {
    content: "\e937";
  }
  .icon-create_new_folder:before {
    content: "\e938";
  }
  .icon-dashboard:before {
    content: "\e939";
  }
  .icon-drag_handle:before {
    content: "\e93a";
  }
  .icon-edit_square:before {
    content: "\e93b";
  }
  .icon-expand_less:before {
    content: "\e93c";
  }
  .icon-expand_more:before {
    content: "\e93d";
  }
  .icon-file_copy:before {
    content: "\e93e";
  }
  .icon-folder_open:before {
    content: "\e93f";
  }
  .icon-grid_view:before {
    content: "\e940";
  }
  .icon-help:before {
    content: "\e941";
  }
  .icon-home:before {
    content: "\e942";
  }
  .icon-humidity_low:before {
    content: "\e943";
  }
  .icon-layers:before {
    content: "\e944";
  }
  .icon-menu:before {
    content: "\e945";
  }
  .icon-more_vert:before {
    content: "\e946";
  }
  .icon-open_in_new:before {
    content: "\e947";
  }
  .icon-photo_camera:before {
    content: "\e948";
  }
  .icon-search:before {
    content: "\e949";
  }
  .icon-stroke_full:before {
    content: "\e94a";
  }
  .icon-system_update_alt:before {
    content: "\e94b";
  }
  .icon-tune:before {
    content: "\e94c";
  }
  .icon-visibility:before {
    content: "\e94d";
  }
  .icon-visibility_off:before {
    content: "\e94e";
  }
  .icon-google3:before {
    content: "\ea8a";
  }