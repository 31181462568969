.threedy-toaster {
  position: fixed;
  top: 10px;
  left: calc(50% - 150px);
  width: 300px;
  background-color: var(--greenColor);
  color: #333;
  z-index: 99999;
  border-radius: 6px;
  padding: 15px;
  box-shadow: 3px 6px 12px rgba(1,1,1,.1);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  opacity: 0;
  transform-origin: 50% 0%;
  transform: scaleY(0.2) translateY(-50%);
  transition: all .2s ease;
}

.threedy-toaster.error {
  background-color: var(--redColor);
  color: #fff;
}

.threedy-toaster.show {
  transform: none;
  opacity: 1;
}