.toggle-ar-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #111;
}

.toggle-ar-loader-content {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
  max-width: 80%;
}

.toggle-ar-loader .logo {
  width: 50%;
  margin: 0 auto 20px;
}

.toggle-ar-loader h2, .toggle-ar-loader h3 {
  color: #fff;
  margin: 10px 0;
  text-align: center;
}

.toggle-ar-loader .ar-icon {
  filter: grayscale(1) invert(1);
  height: 30px;
  display: inline-block;
}

.toggle-ar-loader svg {
  width: 100%;
  margin: 0 auto;
}