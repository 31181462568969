.toggle-editor {
  flex: 1;
  background: var(--backgroundColor);
  position: relative;
  height: 100%;
}

.toggle-editor-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.threedy-lab-workspace-special-action {
  position: absolute;
  left: 25px;
  bottom: 25px;
  background: #222;
  border-radius: 6px;
  padding: 5px;
  color: #fff;
  font-size: .8rem;
}

.threedy-lab-workspace-menu {
  position: absolute;
  left: 50%;
  bottom: 25px;
  border: 1px solid var(--lightBorderColor);
  border-radius: 10px;
  padding: 10px;
  display: flex;
  gap: 10px;
  transform: translateX(-50%);
  background-color: var(--pageColor);
  align-items: center;
  color: var(--textColor);
}

.threedy-lab-workspace-menu .zoom-percentage {
  font-size: 12px;
  font-weight: 500;
  background-color: var(--lightBorderColor);
  padding: 4.75px 7px;
  border-radius: 10px;
  user-select: none;
}

.threedy-lab-workspace-menu .zoom-btn{
  padding: 4px;
}

.threedy-lab-workspace-menu .menu-btn {
  padding: 3px;
  margin: 0;
  font-size: 1.5rem;
  color: var(--textColor);
}

.threedy-lab-workspace-menu.settings-menu {
  right: 25px;
  left: auto;
  transform: none;
}

.threedy-lab-workspace-menu.actions-menu {
  position: fixed;
  left: auto;
  right: 25px;
  top: 80px;
  bottom: auto;
  transform: none;
  flex-direction: column;
  background: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 0;
  gap: 20px;
  z-index: 2;
}

.threedy-lab-workspace-menu.actions-menu li {
  background: var(--pageColor);
  border: 1px solid var(--lightBorderColor);
  border-radius: 10px;
  font-size: 1.4rem;
}

.threedy-lab-workspace-menu.actions-menu li .threedy-menu-tip {
  padding-inline: 8px;
  right: 100%;
  top: 50%;
  left: auto;
  transform: translate(-30%, -50%);
}

.threedy-lab-workspace-menu li {
  font-size: 1.2rem;
}

.threedy-lab-workspace-menu li button {
  color: var(--textColor);
  display: block;
  padding: 8px 12px;
  border-radius: 10px;
  position: relative;
  font-size: inherit;
}

.threedy-lab-workspace-menu li button:hover {
  color: #999;
}

.threedy-lab-workspace-menu li button:hover .threedy-menu-tip {
  display: block;
}

.threedy-menu-tip {
  display: none;
  background: #222;
  position: absolute;
  top: -3.2vmin;
  left: 50%;
  transform: translateX(-50%);
  font-size: .7rem;
  color: #fff;
  border-radius: 6px;
  padding: 3px;
  white-space: nowrap;
  font-weight: 600;
  pointer-events: none;
  user-select: none;
}

.threedy-lab-workspace-menu li.separator {
  background: #ddd;
  padding: 0;
  width: 1px;
}

.threedy-lab-workspace-menu li.zoom-level {
  font-size: .8rem;
  color: #999;
  padding: 10px 0;
  pointer-events: none;
}

.threedy-lab-workspace-menu li button.selected {
  background-color: var(--primaryColor);
  color: #fff;
}

.threedy-lab-workspace-menu li button svg path {
  fill: var(--textColor);
}

.threedy-lab-workspace-menu li button.selected svg path {
  fill: #fff;
}

#scene {
  flex: 1;
  display: block;
  outline: none;
  cursor: default !important;
  width: 100%;
  height: 100%;
}

.threedy-lab-workspace-list {
  position: fixed;
  top: 80px;
  right: 100px;
  width: 325px;
  border-radius: 10px;
  background-color: var(--pageColor);
  user-select: none;
  max-width: 30%;
  max-height: 85vh;
  overflow: auto;
  z-index: 3;
}

.threedy-lab-workspace-list.builder {
  left: 20px;
  padding-inline: 10px;
}

.threedy-lab-workspace-list .builder-options button {
  margin: 0;
  padding: 0;
  display: flex;
}

.threedy-lab-workspace-list .builder-options img {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin: 0 10px 0 0;
}

.threedy-lab-workspace-list .outline-btn {
  margin: 10px auto;
}

.threedy-lab-workspace-list ul {
  max-height: 60vh;
  overflow: auto;
  margin: 10px 0 0;
}

.threedy-lab-workspace-list ul.subparts {
  max-height: none;
  overflow: unset;
  margin: 0 0 0 20px;
}

.threedy-lab-workspace-list li {
  padding: 0;
  margin: 10px 0;
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: left;
}

.threedy-lab-workspace-list li input {
  font-size: 1rem;
  background-color: var(--backgroundColor);
  display: inline-block;
  padding: 6px;
}

.threedy-lab-workspace-list li .variant-group li img {
  width: 25px;
  height: 25px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 20px 0 0;
  padding: 0;
}

.threedy-lab-workspace-list li .variant-group li .icon-layers {
  font-size: 2.2vmin;
  margin: 0 1vmin 0 0;
  color: #ccc;
}

.threedy-lab-workspace-list li .variant-group li.selected  {
  border-color: rgb(255, 18, 141);
  font-weight: 600;
}

.threedy-lab-workspace-list li .variant-group li.selected .icon-layers {
  color: rgb(255, 18, 141);
}

.threedy-lab-workspace-list li .variant-group li:not(.selected):hover {
  cursor: pointer;
  color: #999;
}

.threedy-lab-workspace-list li .group-expanded {
  width: 100%;
}

.threedy-lab-workspace-list li .group-expanded h3 {
  color: rgb(255, 18, 141);
  font-size: 1.75vmin;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 1vmin 0 0 2vmin;
}

.threedy-lab-workspace-list li button {
  font-size: inherit;
  flex: initial;
  text-align: left;
  color: var(--textColor);
}

.threedy-lab-workspace-list li button.name-button {
  width: 80%;
  overflow: hidden;
  line-height: 1rem !important;
}

.threedy-lab-workspace-list li button.name-button.locked {
  width: 60%;
}

.threedy-lab-workspace-list li button.name-button.smaller {
  width: 77%;
}

.threedy-lab-workspace-list li button.name-button .name-label {
  max-width: 100%;
}

.threedy-lab-workspace-list li .name-span {
  display: flex;
}

.threedy-lab-workspace-list li .name-label {
  padding: 3px;
  border-radius: 6px;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 60%;
  color: var(--textColor);
}

.threedy-lab-workspace-list li:not(.selected):hover {
  cursor: pointer;  
}

.threedy-lab-workspace-list li:not(.selected):hover .name-label {
  color: var(--primaryColor);
}

.threedy-lab-workspace-list li.selected .name-label {
  color: #fff !important;
}

.threedy-lab-workspace-list li .name-label.extended {
  max-width: 80%;
}

.threedy-lab-workspace-list li .name-label img {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  display: inline-block;
  margin: 0 2px 0 5px;
}

.threedy-lab-workspace-list li .name-label i {
  font-style: normal;
  font-weight: 700;
  font-size: .8rem;
}

.threedy-lab-workspace-list li .name-label.part-view-element-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.button-group {
  display: flex;
  margin: 0 0 0 auto;
  gap: 10px;
  align-items: center;
  flex: initial;
  width: 20%;
  justify-content: flex-end;
}

.threedy-lab-workspace ::-webkit-scrollbar {
  width: 20px;
}

.threedy-lab-workspace ::-webkit-scrollbar-track {
  background: var(--backgroundColor);
  border-left: 15px solid var(--pageColor);
}

.threedy-lab-workspace ::-webkit-scrollbar-thumb {
  background: var(--darkerBackgroundColor);
  border-left: 15px solid var(--pageColor);
}

.button-group button {
  color: #999;
  position: relative;
  padding: 2px;
}

.select-all-btn {
  margin: 0 0 0 auto;
  border: 1px solid var(--lightBorderColor);
  padding: 3px 6px;
  border-radius: 4px;
  color: var(--lightestTextColor);
  display: flex;
  align-items: center;
}

.select-all-btn.left {
  margin: 20px 0 0 0;
}

.select-all-btn span {
  display: inline-block;
  height: 10px;
  width: 10px;
  border: 1px solid var(--lightBorderColor);
  margin: 0 6px 0 0;
}

.select-all-btn.selected {
  border-color: var(--primaryColor);
  color: var(--primaryColor);
}

.select-all-btn.selected span {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

.part-view-element-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.threedy-lab-workspace-list li button.selected {
  color: rgb(255, 18, 141);
  font-weight: 600;
}

.threedy-lab-workspace-list li.hovering .name-label {
  color: var(--primaryColor);
}

.threedy-lab-workspace-list li.selected .name-label {
  background: var(--primaryColor);
  color: #fff;
}

.threedy-lab-workspace-list li.selected.hovering .name-label {
  background:rgb(194, 44, 231);
  color: #eee;
}

.threedy-lab-workspace-list li ul {
  margin: 0 0 0 10px;
  width: 100%;
}

.threedy-lab-workspace-list li .primary-btn {
  margin: 0 auto 1vmin;
  font-size: 1.5vmin;
  padding: 0.25vmin 0.5vmin;
}

.threedy-lab-workspace-list li .group-variants li {
  display: flex;
  gap: 1vmin;
  align-items: center;
}

.threedy-lab-workspace-list li .group-variants li div {
  flex: 1;
}

.threedy-lab-workspace-list li .group-variants li div:nth-of-type(1) {
  flex: initial;
  width: 25%;
}

.threedy-lab-workspace-list li .group-variants li div:nth-of-type(3) {
  flex: initial;
}

.icon-move {
  flex: initial;
  color: #fff;
}

.threedy-lab-workspace-list li .group-variants li:hover .icon-move {
  color: #666;
}

.threedy-lab-workspace-list li .group-variants .drag-over {
  background: #eee !important;
}

.threedy-lab-workspace-list h5 {
  font-size: 1rem;
  text-align: center;
  color: var(--lightestTextColor);
  margin: 20px 0;
}

.threedy-lab-workspace-list-menu {
  display: flex;
  align-items: center;
  white-space: nowrap;
  border-bottom: 1px solid var(--lightBorderColor);
  padding: 10px 20px;
}

.threedy-lab-workspace-list .threedy-tab-bar {
  margin: 5px 0;
}

.threedy-lab-workspace-list .threedy-tab-bar li {
  font-size: .9rem;
}

.threedy-lab-workspace-list-menu button {
  color: var(--tabTextColor);
  margin: 0 0 0 auto;
  font-size: 1.5rem;
}

.threedy-lab-workspace-list-menu h4 {
  display: flex;
  align-items: center;
  color: var(--textColor);
  font-weight: 600;
  font-size: 1.1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.threedy-lab-workspace-list-menu h4 span {
  display: inline-block;
  margin: 0 5px 0 0;
}

.threedy-lab-workspace-list-menu h4 svg path {
  fill: var(--textColor);
}

.threedy-lab-workspace-list-menu.actions {
  position: fixed;
  left: 280px;
  top: 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--pageColor);
  border-radius: 10px;
  transform: translateX(calc(100% + 15px));
  padding: 15px;
}

.threedy-lab-workspace-list-menu.actions .primary-tag {
  margin: 0;
  border-radius: 6px;
}

.threedy-lab-workspace-list-menu.actions button {
  font-size: 1rem;
  margin: 10px 0 0;
}

.threedy-lab-zoom-menu {
  position: absolute;
  bottom: -2px;
  background-color: #fff;
  width: 102%;
  left: -1%;
  border: 1px solid var(--outlineColor);
  font-size: .9rem;
  border-radius: 10px;
}

#scene-explorer-host {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 8888;
}

#inspector-host {
  position: fixed !important;
  top: 0;
  right: 0;
  z-index: 8888;
}

.lighting-settings-container {
  padding: 20px 10px 20px 15px;
}

.lighting-settings-container .toggle-popup-setting {
  margin: 0;
}

.lighting-settings-container ul {
  max-height: 300px;
}

.zoom-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #000;
}

.zoom-label {
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.variations-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 0 0 4px;
}

.variations-btn {
  font-size: 12px;
  border-radius: 10px;
  padding: 4.75px 7px;
}

.zoom-label .icon-help-circle {
  display: inline-block;
  margin: 0 0 0 4px;
}

.zoom-label .icon-help-circle:hover {
  cursor: pointer;
  color: var(--primaryColor);
}

.threedy-lab-workspace-menu .threedy-toggle {
  padding: 2.25px 0;
}

.part-viewer-container, .variations-configure-container {
  position: relative;
  padding: 10px 10px 50px;
  min-height: 300px;
}

.part-view-footer, .configurator-viewer-footer {
  display: flex;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid var(--lightBorderColor);
  cursor: pointer;
}

.part-view-footer-item {
  display: flex;
  flex: 50%;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-block: 10px;
  color: var(--textColor);
}

.part-view-footer-item svg path, .configurator-viewer-footer svg path {
  fill: var(--textColor);
}

.part-view-footer-item.disabled, .configurator-viewer-footer.disabled {
  pointer-events: none;
  color: var(--lightBorderColor);
}

.part-view-footer-item.disabled svg path, .configurator-viewer-footer.disabled svg path {
  fill: var(--lightBorderColor);
}

.part-view-footer-item svg, .configurator-viewer-footer svg {
  width: 14px;
}

.part-view-footer-item:first-child {
  border-right: 1px solid var(--lightBorderColor);
}

.configurator-viewer-footer {
  padding-block: 10px;
  justify-content: center;
  gap: 5px;
  align-items: center;
}

.top-145 {
  top: 145px;
}

.top-200 {
  top: 200px;
}

.part-viewer-container li {
  padding-inline: 10px;
  padding-block: 1px;
}

.part-viewer-container li.selected {
  background-color: var(--primaryColor);
  border-radius: 15px;
}
.part-viewer-container li.ungrouped-part.selected {
  border-radius: 1px;
}

.part-viewer-container li.selected.hovering .name-label {
  background-color: var(--primaryColor);
}

.part-viewer-container li.selected svg path {
  fill: #fff;
}
.part-viewer-container .subparts li.selected {
  border-radius: 2px;
}

.part-viewer-container .subparts li.selected span.delete-btn {
  color: var(--highlightColor) !important
}

.configurator-builder.threedy-lab-workspace-list.builder {
  left: 10px;
  padding-inline: 0;
  position: absolute;
  width: 300px;
  z-index: 3334;
}

.configurator-builder .threedy-lab-workspace-list-menu {
  padding-inline: 10px;
}

.configurator-builder .material-actions {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
}

.configurator-builder .material-actions button {
  margin: 0;
}

.configurator-builder .material-actions button svg path {
  fill: var(--textColor);
}

.add-option {
  display: none;
  cursor: pointer;
}

.add-option.disabled {
  pointer-events: none;
  color: var(--lightBorderColor);
}

.add-option.disabled svg path {
  fill: var(--lightBorderColor);
}

.configurator-builder .add-option {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top: 1px solid var(--lightBorderColor);
  justify-content: center;
  gap: 5px;
  align-items: center;
  color: var(--textColor);
}

.configurator-builder .add-option svg path {
  fill: var(--textColor);
}

.configurator-builder .add-option:hover, .configurator-builder .add-option:hover svg path {
  color: var(--primaryColor);
  fill: var(--primaryColor);
}

.configurator-builder .threedy-material-material-selector {
  padding-inline: 10px;
  padding-top: 10px;
}

.replay-tour-btn {
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 10px;
  padding-block: 6px;
  font-weight: bold;
  font-size: 1rem;
  border-radius: 8px;
}

.replay-tour-btn svg {
  width: 11px;
  transform: rotateY(180deg) rotate(-25deg);
}

.pseudo-model-wrapper {
  position: absolute;
  inset: 80px auto 20px 50%;
  /* border: 1px solid red; */
  /* z-index: 9; */
  transform: translateX(-50%);
  width: 550px;
}

.workspace-left-panels {
  position: fixed;
  width: 325px;
  border-radius: 8px;
  max-width: 30%;
  max-height: 85vh;
  overflow: auto;
  background-color: var(--pageColor);
}