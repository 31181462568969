.toggle-part-editing-label {
  position: absolute;
  z-index: 3333;
  background-color: var(--pageColor);
  border-radius: 6px;
  padding: 10px 15px;
  top: 82px;
  right: 100px;
  max-width: 400px;
  color: var(--primaryColor);
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}

.toggle-part-count {
  position: relative;
  font-size: .9rem;
  margin: 0 10px 0 0;
}

.toggle-part-count i {
  background-color: var(--primaryColor);
  color: #fff;
  height: 12px;
  width: 12px;
  line-height: 12px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 0;
  position: absolute;
  top: -7px;
  right: -12px;
  font-style: normal;
}

.toggle-part-editing-label strong {
  max-width: 60%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}