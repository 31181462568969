.tile-component-wrapper {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid transparent;
    height: var(--_grid-item-height);
    display: flex;
    flex-direction: column;
    background-color: var(--pageColor);
}

.tile-component-wrapper.with-shadow {
    box-shadow: -2px 3px 8px rgba(100, 100, 100, .1);
}

.tile-component-wrapper-clickable:hover {
    cursor: pointer;
    border-color: var(--brandPrimaryColor);
}

.tile-img-container {
    height: 75%;
    padding: var(--standardPadding);
    overflow: hidden;
    background-color: var(--pageColor);
    position: relative;
}

.tile-img-container > * {
    width: 100%;
    height: 100%;
}

.tile-img-container-project-image {
    height: 63%;
}

.tile-img-container-project-image > img {
    object-fit: cover;
    transform-origin: 50% 50%;
    transform: scale(2.8);
}

.tile-img-container-tutorial-image {
    height: 60%;
}

.tile-img-container-tutorial-image > * {
    transform: scale(0.5);
}

.tile-img-container-tutorial-image svg path {
    fill: var(--brandPrimaryColor);
}

.tile-img-container-grey {
    background-color: var(--backgroundColor);
}

.tile-lower-section {
    padding: var(--halfPadding);
    flex: 1;
}

.tile-lower-section-blue {
    background-color: var(--brandPrimaryColor);
    display: grid;
    place-content: center;
}

.tile-header-only {
    color: var(--pageColor);
}

.tile-header-and-actions, .tile-desc {
    display: flex;
    justify-content: space-between;
}

.tile-header-and-actions > h6 {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.tile-header-and-actions > span {
    cursor: pointer;
}

.tile-desc > p {
    font-size: .8rem;
    color: var(--lightTextColor);
    text-transform: capitalize;
    margin: .5rem 0 .25rem 0;
}

.tile-status {
    display: grid;
    place-items: center;
    margin-top: 0.5rem;
}

.tile-img-no-image {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: var(--brandSecondaryColor);
    border-radius: 100%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2rem;
    text-align: center;
    line-height: 4rem;
    box-shadow: 2px 8px 8px rgba(1,1,1,.1);
}