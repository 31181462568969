/* .pricing-component.embedded {
  max-width: 1000px;
  margin: 0 auto;
} */

.plan-selector {
  margin: 20px 0;
  display: flex;
  align-items: flex-end;
  max-height: none !important;
}

.plan-selector.vertical {
  flex-direction: column;
  align-items: center;
}

.plan-selector.vertical .plan-item {
  width: 100%;
  display: flex;
  align-items: center;
}

.plan-selector.vertical .plan-item input {
  width: 50%;
}

.plan-selector.vertical .plan-item h3 {
  margin: 0;
  flex: initial;
  width: 20%;
}

.plan-selector.vertical .plan-item button {
  margin: 0 0 0 auto;
}

.plan-selector li {
  flex: 1;
  text-align: left;
}

.plan-selector li.empty {
  flex: 0.4;
  padding: 15px;
}

.plan-selector li.plan-item {
  border: 1px solid var(--brandPrimaryColor);
  padding: 1.5rem;
  border-radius: 8px;
  margin: 1.5rem 2.5rem;
  max-width: 24rem;
  background-position-x: center;
  background-position-y: bottom;
  background-size: initial;
  background-repeat: no-repeat;
}

.plan-selector li.selected {
  /* background-color: var(--backgroundColor); */
  border-color: var(--borderColor);
}

.plan-selector li.selected ul li .icon-check {
  color: var(--brandPrimaryColor);
}

.plan-selector li.selected ul li {
  font-weight: 500;
  color: var(--brandPrimaryColor);
}

.plan-selector li h2 {
  text-align: left;
  margin: 0;
}

.plan-selector li h2 {
  font-size: 2.1rem;
  font-weight: 600;
}

.plan-selector li h3 {
  font-weight: 700;
  margin: 0px;
  text-align: right;
  font-size: 2.5rem;
}

.plan-selector li button {
  margin-bottom: 10px;
  margin-top: 5rem;
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  color: var(--brandPrimaryColor);
}

.plan-selector li.selected button {
  background-color: var(--brandPrimaryColor);
  color: #fff;
}

.plan-selector li input {
  width: 100%;
  margin: 20px 0;
}

/* .plan-selector li ul li:first-child {
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
} */

.plan-selector li ul li:last-child {
  border: none;
}

.plan-selector li ul li {
  padding: 8px;
  /* border-bottom: 1px solid var(--lightBorderColor); */
  font-size: 0.8rem;
  font-weight: 400;
}

.plan-selector li ul li .icon-check {
  display: inline-block;
  margin: 0 5px 0 0;
  color: var(--lightestTextColor);
}

.plan-selector hr {
  border-top: 1px solid var(--brandPrimaryColor);
  margin-block: 2rem;
}

.per-month {
  font-size: 1rem;
  text-align: right;
}

.per-month.free {
  color: var(--lightTextColor);
}

.per-month.premium {
  color: var(--brandTertiaryColor);
}

.plan-item p {
  font-size: 0.9rem;
}

.plan-item {
  background-color: var(--brandPrimaryColor);
  color: #fff;
}

.plan-item h2,
.plan-item h3 {
  color: #fff;
}

.plan-item.selected {
  background-color: #fff;
}

.plan-item.selected h2,
.plan-item.selected h3,
.plan-item.selected p {
  color: var(--brandPrimaryColor);
}

.manage-subscription h5, .upcoming-invoice h5 {
  text-align: center;
  color: var(--textColor);
  font-weight: 600;
}

.upcoming-invoice {
  text-align: center;
}

.upcoming-invoice .invoice-details {
  display: inline-block;
  text-align: left;
}

.upcoming-invoice .invoice-details .heading, .upcoming-invoice .invoice-details p {
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: var(--textColor) !important;
}

.upcoming-invoice .invoice-details .sub-heading {
  font-weight: 400 !important;
  font-size: 1rem !important;
  color: var(--textColor) !important;
}