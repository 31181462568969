.toggle-material-viewer {
  max-height: 36vh;
  overflow: auto;
  min-height: 250px;
  margin-block: 10px;
  margin-right: 5px;
  padding: 0 0px 0 10px;
}

.toggle-material-viewer ul {
  max-height: none;
  margin: 0;
}

.toggle-material-viewer ul li:not(.selected):hover .name-label {
  color: var(--primaryColor);
}

.toggle-material-viewer .variation-item {
  cursor: pointer;
  padding-inline: 10px;
  background-color: transparent;
}

.toggle-material-viewer .variation-item.selected {
  background-color: var(--primaryColor);
  color: #fff;
}

.toggle-material-viewer .variation-item.selected svg path {
  fill: #fff;
}

.toggle-material-viewer .variation-item > span {
  margin-right: 8px;
}

.sorting-handle {
  cursor: move;
}

.sorting-handle svg path {
  fill: var(--textColor);
}

.no-materials {
  display: grid;
  place-items: center;
  min-height: 250px;
}

.context-btn {
  width: 10px;
  display: grid;
  place-items: center;
  transform: translateX(5px);
}

.toggle-material-viewer li .button-group button {
  width: 15px;
  display: flex;
  justify-content: center;
  transform: translateX(6px);
}

.button-group button svg path {
  fill: var(--textColor);
}