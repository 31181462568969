#help-chat-window-container {
    position: fixed;
    z-index: 10000;
    bottom: 0px;
    right: 0px;
    width: 375px;
    height: 535px;
}

#help-chat-window-container .window-area {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1rem;
    display: inline-block;
}

#help-chat-window-container .chat-window {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    color: rgb(47, 57, 65);
    height: 100%;
    width: 100%;
    max-width: 100vw;
    position: relative;
    overflow: hidden;
    background-color: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 20%) 0px 0px 0.428571rem 0px;
    border-radius: 0.571429rem;
}

#help-chat-window-container .chat-window .header {
    padding: 0.571429rem 1rem;
    background: rgb(75, 69, 221) !important;
    color: rgb(255, 255, 255) !important;
}

.chat-window .header .header-row {
    display: flex;
    align-items: center;
    min-height: 2rem;
    justify-content: space-between;
    flex: 1;
}

.chat-window .header .title {
    color: rgb(255, 255, 255) !important;
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.0214286rem;
    line-height: 1.5;
    font-size: 1.07143rem;
    -webkit-box-flex: 1;
}

.chat-window .header .minimize-button {
    flex-shrink: 0;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: border-color 0.25s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s, background-color 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;
    margin: 0px;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: inherit;
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
    box-sizing: border-box;
    user-select: none;
    line-height: 30px;
    font-size: 12px;
    background-color: transparent;
    border: none;
    padding: 0px;
    color: rgb(104, 115, 125);
    -webkit-appearance: button;
    width: 2rem;
    height: 2rem;
}

.chat-window .header .minimize-button-icon {
    font-feature-settings: "kern", "kern";
    font-kerning: normal;
    -webkit-text-size-adjust: 100%;
    font: inherit;
    text-transform: none;
    cursor: pointer;
    white-space: nowrap;
    font-family: inherit;
    -webkit-font-smoothing: subpixel-antialiased;
    user-select: none;
    line-height: 30px;
    font-size: 12px;
    font-weight: inherit;
    box-sizing: inherit;
    max-height: 100%;
    transition: transform 0.25s ease-in-out 0s;
    width: 1rem !important;
    height: 1rem !important;
    fill: rgb(255, 255, 255) !important;
    color: rgb(255, 255, 255) !important;
    overflow: hidden;
    min-width: 12px;
}

.chat-window .chat-content {
    padding: 10px;
    margin: 10px;
    flex-grow: 2;
    overflow: hidden auto;
}

.chat-window .chat-content::-webkit-scrollbar-track {
    margin: 1.07143px 0px;
}

.chat-window .chat-content::-webkit-scrollbar-thumb {
    background: rgb(216, 220, 222);
    border-radius: 0.214286rem;
}

.chat-window .chat-content::-webkit-scrollbar {
    box-shadow: none;
    width: 0.428571rem;
}

.chat-content .chat-content .help-chat-form {
    padding-bottom: 2.14286rem;
    -webkit-box-flex: 2;
    flex-grow: 2;
    padding-top: 1.07143rem;
    padding-left: 0.714286rem;
    padding-right: 1.07143rem;
    margin-left: 0.714286rem;
    margin-right: 0.357143rem;
    overflow: hidden auto;
    height: 100%;
}

.chat-window .footer-row {
    padding: 1.07143rem 1.42857rem;
    border-top: 0.0785714rem solid rgb(233, 235, 237);
    box-shadow: rgb(0 0 0 / 8%) 0px -0.0714286rem 0.857143rem;
}

.chat-window .footer-row .footer-row-content {
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.chat-window .footer-row .footer-row-content .submit-button {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    transition: border-color 0.25s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s, background-color 0.25s ease-in-out 0s, color 0.25s ease-in-out 0s;
    margin: 0px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-family: inherit;
    font-weight: 400;
    -webkit-font-smoothing: subpixel-antialiased;
    box-sizing: border-box;
    user-select: none;
    padding: 0px 1.07143em;
    font-size: 14px;
    height: 2.71429rem !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    line-height: 2.57143rem !important;
    background-color: rgb(99, 94, 225) !important;
    color: rgb(255, 255, 255) !important;
}