.variation-item .variation-more-btn {
  color: var(--outlineColor) !important;
  font-size: 1.1rem;
}

.variation-item.open .variation-more-btn {
  color: var(--primaryColor) !important;
}

.variation-item input {
  color: var(--textColor);
}

.variant-options {
  display: block;
  margin: 0 !important;
  padding: 0;
}

.variant-options li {
  display: flex;
  align-items: center;
  gap: 5px;
}

.variant-options li .sorting-handle {
  margin-right: 5px;
}

.variant-options li.selected {
  background-color: var(--primaryColor);
}

.variant-options li.selected .variant-name, .variant-options li.selected .variant-options-buttons span {
  color: #fff;
}

.variant-thumb {
  height: 20px;
  width: 20px !important;
  border-radius: 100%;
  margin: 0 5px 0 0;
  background-color: var(--backgroundColor);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 200%;
  display: inline-block;
  vertical-align: middle;
}

.variant-options li .variant-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 70%;
  padding: 0;
  color: var(--textColor);
  cursor: pointer;
}

.variant-options-buttons {
  margin: 0 0 0 auto;
  display: flex;
  gap: 10px;
  align-items: center;
}

.variant-options-buttons button {
  font-size: .8rem;
}

.variant-preview-btn {
  height: 20px;
  width: 20px;
  display: inline-block;
  max-width: none;
  text-align: center !important;
  border: 1px solid var(--outlineColor);
  border-radius: 100%;
  color: var(--outlineColor) !important;
}

.variant-preview-btn.selected {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: #fff !important;
}

.variations-configure-container {
  padding: 10px;
}

.part-or-group-for-option {
  width: 100%;
  display: flex;
  border: 1px solid var(--textColor);
  border-radius: 15px;
  padding: 2px 7px;
}

.part-or-group-for-option .name-button {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--textColor);
}

.variations-configure-container > ul {
  max-height: 300px;
  margin: 0;
}