.toggle-header-group {
  display: flex;
  align-items: center;
  padding: var(--doublePadding);
}

.toggle-header-group > * {
  margin: 0;
}

.toggle-header-group button {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  gap: 5px;
}

.toggle-header-group .header-span {
  display: inline-block;
  background-color: var(--brandSecondaryColor);
  color: #fff;
  border-radius: 20px;
  padding: 0.25rem 0.5rem;
  padding: var(--quarterPadding) var(--halfPadding);
  margin: 0 0.5rem 0 0;
  margin: 0 var(--halfPadding) 0 0;
}

.toggle-header-group.darkerText{
  color: var(--textColorDisabled);
}