.toggle-image-input {
    position: relative;
    border: 2px dashed #ddd;
    border-radius: 6px;
    height: 300px;
    margin: 0.2rem auto;
    width: 300px;
}

.toggle-image-input:hover {
    border-color: var(--brandPrimaryColor);
    cursor: pointer;
}

.toggle-image-input input {
    height: 0;
    width: 0;
    left: 0;
    position: absolute;
    top: 0;
    border: 1px solid transparent;
    border-radius: 6px;
}

.toggle-image-input label {
    color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.toggle-image-input img {
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
}

.toggle-image-input p {
    color: var(--tabTextColor);
    left: 0;
    pointer-events: none;
    position: absolute;
    text-align: center;
    top: 45%;
    width: 100%;
}