.toggle-material-library {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 16vh;
  background-color: var(--pageColor);
  display: flex;
  flex-direction: column;
  gap: var(--quarterPadding);
}

.toggle-material-library-top-bar {
  display: flex;
  height: 4vh;
  flex: initial;
  align-items: center;
  border-bottom: 1px solid var(--lightBorderColor);
}

.toggle-material-library-top-bar-left {
  display: flex;
  align-items: center;
}

.toggle-material-library-top-bar-right {
  margin: 0 0 0 auto;
}

.toggle-material-library-top-bar-right button {
  font-size: 1.25rem;
  padding: 0 calc(var(--quarterPadding)*2);
}

.toggle-material-library-materials {
  display: flex;
  gap: var(--quarterPadding);
  flex-wrap: nowrap;
  overflow-x: auto;
  padding: 0 var(--quarterPadding);
  position: relative;
  flex: initial;
  height: 12vh;
}

.toggle-material-library .toggle-no-items {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toggle-material-library-materials li button {
  display: flex;
  flex-direction: column;
  gap: var(--quarterPadding);
  width: 7vw;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid transparent;
  padding: 0 var(--quarterPadding) calc(var(--quarterPadding)/2);
  border-radius: 10px;
  color: var(--lightTextColor);
  position: relative;
}

.toggle-material-library-materials li button:after {
  content: "";
  height: 5.5vh;
  width: 5.5vh;
  border-radius: 100%;
  background-color: var(--backgroundColor);
  border: 2px solid var(--borderColor);
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}

.toggle-material-library-materials li button h6 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: .85rem;
  font-weight: 600;
  color: inherit;
}

.toggle-material-library-materials li button img {
  height: 7vh;
  width: 7vh;
  flex: initial;
  display: block;
  margin: 0 auto;
  user-select: none;
}

.toggle-material-library-materials li button:hover,
.toggle-material-library-materials li.selected button {
  border-color: var(--brandPrimaryColor);
  box-shadow: 0 8px 8px rgba(1,1,1,.1);
  color: var(--brandPrimaryColor);
}

.toggle-material-library-materials li.loading {
  pointer-events: none;
}

.toggle-material-library-materials li.loading h6 {
  animation: flicker 2s infinite ease;
}

.toggle-material-library-materials li.loading button:after {
  display: block;
  z-index: 3;
  animation: glow 4s infinite ease;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes glow {
  0% {
    background-color: var(--backgroundColor);
  }
  50% {
    background-color: var(--brandTertiaryColor);
    border-color: var(--brandTertiaryColor);
  }
  100% {
    background-color: var(--backgroundColor);
  }
}