.tutorial-container .search-input-container {
    width: 20vw;
    border: 1px solid var(--textColorDisabled);
    padding: calc(var(--halfPadding) + var(--quarterPadding) - 0.2rem);
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    border: 1px solid var(--ultraLightGrayColor);
    transition: 0.2s border ease-in;
}

.tutorial-container .search-filter-container .icon-search {
    position: absolute;
    top: 5px;
    left: 8px;
    color: var(--textColorDisabled);
    font-size: 16px;
}

.tutorial-container .search-filter-container .search-input-container {
    width: 20vw;
    border: 1px solid var(--ultraLightGrayColor);
    padding: calc(var(--halfPadding) + var(--quarterPadding) - 0.3rem);
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
}

.tutorial-container .search-filter-container .close-btn {
    position: absolute;
    top: 0px;
    right: 3px;
    color: var(--textColorDisabled);
    padding: var(--quarterPadding);
    cursor: pointer;
}

.tutorial-container .search-filter-container .search-input-element {
    border: 0px solid transparent;
    bottom: 0;
    position: absolute;
    left: 30px;
    top: 0;
    right: 2%;
    color: var(--lighterTextColor);
    letter-spacing: normal;
    font-size: var(--halfPadding);
}

.tutorial-container .search-filter-container {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: var(--quarterPadding) var(--doublePadding);
}

.tutorial-container .search-filter-container .sort-btn {
    font-weight: 500;
    font-size: 0.8rem;
    display: flex;
    gap: 0.3rem;
    padding: 0.2rem 0.7rem;
}

.tutorial-container .videos-container {
    display: flex;
    gap: var(--quarterPadding);
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: var(--doublePadding);
}

.each-video-block {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    letter-spacing: normal;
    border: 1px solid transparent;
}

.each-video-block:hover {
    cursor: pointer;
    opacity: .95;
    border-color: var(--brandPrimaryColor);
}

.each-video-block .upper-container {
    display: flex;
    flex-direction: column;
    min-height: 8rem;
    height: 55%;
    align-items: center;
    justify-content: center;
    padding: var(--doublePadding) var(--quarterPadding);
    background: var(--brandPrimaryColor);
    border-radius: 8px 8px 0px 0px;
}

.each-video-block .lower-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--halfPadding);
    gap: var(--quarterPadding);
    border-radius: 0px 0px 8px 8px;
    border: 1px solid var(--highlightColor);
    background-color: var(--pageColor);
}

.each-video-block .upper-container .play-button {
    color: #fff;
    font-size: 3.15rem;
}

.each-video-block .lower-container .tile-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    color: var(--textColorDisabled);
    text-transform: capitalize;
}

.each-video-block .lower-container .tile-subtitle {
    font-weight: 400;
    font-size: 0.85rem;
    color: var(--lightTextColor)
}

.each-video-block .lower-container .tile-link {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 0.7rem;
    color: var(--lightTextColor);
    cursor: pointer;
}

.video-wrapper {
    height: 18rem;
    position: relative;
}