.toggle-processing-file-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backgroundColor);
  z-index: 999;
  color: var(--textColor);
}

.toggle-processing-file {
  background: var(--pageColor);
  width: 350px;
  padding: 25px;
  border: 1px solid var(--outlineColor);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 55;
  border-radius: 20px;
  text-align: center;
}

.toggle-processing-file h2 {
  margin: 10px 0 0;
}

.toggle-processing-file-icons {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  position: relative;
}

.toggle-processing-file-icons .span-to-from {
  display: block;
  flex: initial;
  border: 1px solid var(--outlineColor);
  border-radius: 4px;
  padding: 15px;
  font-size: 3rem;
  color: var(--primaryColor);
}

.toggle-processing-file-icons .span-arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%) scaleX(-1);
  color: #fff;
  width: 50px;
  height: 50px;
  font-size: 2rem;
  line-height: 50px;
  border: 1px solid var(--outlineColor);
  text-align: center;
  border-radius: 100%;
  box-shadow: 3px 6px 12px rgba(1,1,1,.1);
  background: var(--primaryColor);
  background: linear-gradient(34deg, var(--purpleColor), var(--primaryColor));
}