.no-projects {
    padding: 0 var(--doublePadding) 0 var(--doublePadding);
    text-align: center;
}

.empty-project-selections {
    display: flex;
    justify-content: center;
}

.empty-project-selections > * {
    flex-basis: 20%;
}