.toggle-perspective-correction {
  width: 80%;
  height: 80%;
  position: relative;
  text-align: center;
  margin: 20px auto;
}

.toggle-perspective-correction img {
  width: 80%;
  display: block;
  margin: 0 auto;
}

.toggle-perspective-correction-container {
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px dashed var(--primaryColor);
  border-radius: 6px;
}

.toggle-perspective-correction canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.toggle-perspective-correction svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.toggle-perspective-control-line {
  stroke: var(--primaryColor);
  stroke-width: 3px;
  pointer-events: none;
  stroke-linecap: square;
  shape-rendering: geometricPrecision;
}

.toggle-perspective-control-pt {
  position: absolute;
  width:  20px !important;
  height: 20px !important;
  border-radius: 100%;
  background: var(--primaryColor);
  touch-action: none;
  user-select: none;
}

.toggle-perspective-control-pt:hover {
  cursor: pointer !important;
  opacity: .9;
}

.toggle-perspective-control-pts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.toggle-perspective-correction-container svg line {
  stroke: var(--primaryColor);
  stroke-width: 2px;
  opacity: 0.5;
}