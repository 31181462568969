.toggle-color-picker {
  position: relative;
}

.toggle-color-picker:before {
  content: "";
  position: absolute;
  top: calc(-1.25 * var(--halfPadding));
  left: -10%;
  background-color: var(--lightBorderColor);
  width: 120%;
  height: 1px;
}

.toggle-color-picker-saturation {
  position: relative;
  width: 100%;
  padding-top: 100%;
  margin: var(--standardPadding) 0 var(--halfPadding);
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  overflow: hidden;
}

.toggle-color-picker-hue {
  position: relative;
  width: 100%;
  height: 14px;
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  overflow: hidden;
} 

.toggle-color-picker-tools {
  display: flex;
  gap: var(--quarterPadding);
  margin: var(--halfPadding) 0 0;
}

.toggle-color-picker-swatch {
  position: relative;
  width: 18%;
  height: 0;
  padding-top: 12%;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid var(--lightBorderColor);
}

.toggle-color-dropper {
  position: relative;
  margin: 0;
  height: 0;
  width: 18%;
  padding-top: 12%;
  border: 1px solid var(--lightBorderColor);
  border-radius: 8px;
  position: relative;
}

.toggle-color-dropper:hover {
  background-color: var(--lightBorderColor);
}

.toggle-color-dropper:hover svg path {
  fill: var(--brandPrimaryColor);
}

.toggle-color-dropper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70%;
  height: 70%;
}

.toggle-color-picker-tools div {
  flex: initial;
  width: 12%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: var(--quarterPadding);
  font-size: .8rem;
}

.toggle-color-picker-tools div:first-of-type {
  width: 28%;
}

.toggle-color-picker-tools div input {
  border: 1px solid var(--lightBorderColor);
  padding: var(--quarterPadding) calc(var(--quarterPadding)/2);
  border-radius: 8px;
  font-size: .8rem;
}