.threedy-card-container {
    letter-spacing: normal;
    --_cols: max(1, var(--cols, 4)); 
	--_gap: var(--gap, 25px);
	--_min: var(--min, 190px);
	--_max: var(--max, 220px); 
    margin: 0;
    display: grid;
    gap: var(--_gap);
    grid-template-columns: repeat(auto-fill, minmax(max(var(--_min), calc((100% - var(--_gap) * (var(--_cols) - 1)) / var(--_cols))), 1fr));
}

.threedy-card-container .threedy-card {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    border: 1px solid transparent;
    border-radius: 9px;
}

.threedy-card p {
    color: inherit;
}

.threedy-card-container .threedy-card.highlight .threedy-card-header {
    align-items: center;
}

.threedy-card-container .threedy-card.highlight .threedy-card-header svg {
    width: 50px;
    height: 50px;
    max-width: 100%;
}

.threedy-card-container .threedy-card.highlight .threedy-card-header svg path {
    fill: var(--primaryColor);
}

.threedy-card-container .threedy-card:hover {
    cursor: pointer;
    border: 1px solid var(--primaryColor);
    transition: all 0.2s ease-out;
}
.threedy-card-container .threedy-card .threedy-card-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-evenly;
    flex-grow: 2;
}

.threedy-card-container .threedy-card .threedy-card-content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    min-height: 80px;
    color: var(--textColor);
}

.threedy-card-container .threedy-card .flex-image-holder {
    display: flex;
    align-items: stretch;
}

.threedy-card-container .threedy-card .flex-image-holder img.v-middle {
    width: 100%;
}

.threedy-card-container .threedy-card a.underlined {
    text-decoration: underline;
}
.video-wrapper {
    height: 45vh;
    position: relative;
}

.video-wrapper video {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-height: 100%;
}