.table-row {
    display: flex;
    justify-content: space-between;
    padding: var(--halfPadding);
    align-items: center;
}

.table-cell-content-flex {
    display: flex;
    align-items: center;
    gap: var(--quarterPadding);
}

.table-cell-content .cell-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.table-cell-content .cell-text a {
    display: flex;
    gap: var(--quarterPadding);
    align-items: center;
}

.table-cell-content .cell-text a span {
    display: none;
}

.table-cell-content .cell-text a:hover {
    color: var(--brandPrimaryColor);
}

.table-cell-content .cell-text a:hover span {
    display: inline;
}

.table-cell-content .cell-image {
    width: 3rem;
    height: 2.5rem;
    margin: 0 var(--halfPadding) 0 0;
    border-radius: 8px;
    overflow: hidden;
}

.table-cell-content :is(svg, img) {
    transform: scale(2.2);
    transform-origin: 50% 55%;
    width: 100%;
}