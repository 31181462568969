.list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--standardPadding);
}

.list-header-right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--standardPadding);
}

.list-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-ops {
    position: relative;
    width: 7rem;
    border: 1px solid var(--ultraLightGrayColor);
    border-radius: 20px;
    padding-block: var(--quarterPadding);
    padding-inline: var(--halfPadding);
}

.list-ops-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding-inline: var(--quarterPadding);
}

.list-ops-content > h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.list-ops-active {
    background-color: var(--brandPrimaryColor);
}

.list-ops-active .list-ops-content > :is(h6, span) {
    color: var(--pageColor);
}

.list-ops-active .dropdown-items {
    display: block;
}

.dropdown-items {
    position: absolute;
    margin: .8rem 0 0 0;
    top: 100%;
    left: 0;
    background-color: var(--pageColor);
    padding: var(--quarterPadding);
    border-radius: 8px;
    width: 100%;
    display: none;
    z-index: 1;
}

.dropdown-items::before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: var(--pageColor);
    border-width: 10px;
    transform: translateX(-50%);
}

.dropdown-items li {
    list-style-type: none;
    padding: var(--quarterPadding);
    cursor: pointer;
    font-size: 1rem;
    border-radius: 8px;
    margin-block: var(--quarterPadding);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list-ops-content > h6 .ellipsis-container, .dropdown-items li .ellipsis-container {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


.dropdown-items li:not(.selected-item):hover {
    background-color: var(--darkerBackgroundColor);
}

.selected-item {
    background-color: var(--brandPrimaryColor);
    color: var(--pageColor);
}

.table-ui {
    margin: var(--standardPadding) auto;
    border-radius: 8px;
    overflow: hidden;
    background-color: var(--pageColor);
    width: calc(100% - calc(2*var(--doublePadding)));
}

.table-header {
    display: flex;
    justify-content: space-between;
    background-color: var(--brandTertiaryColor);
    padding: var(--halfPadding);
    border-radius: 8px 8px 0 0;
}

.table-header .table-cell {
    font-weight: 500;
}

.table-cell {
    width: 10rem;
    flex-grow: 1;
    padding-inline: var(--quarterPadding);
}