.toggle-public-configurator-options {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: var(--pageColor);
  border-top: 1px solid var(--lightBorderColor);
}

.toggle-public-configurator-options ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
}

.toggle-public-configurator-options ul .toggle-option {
  flex: initial;
  flex-grow: 1;
  width: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.toggle-public-configurator-options ul .toggle-option ul {
  gap: 15px;
}

.toggle-public-configurator-options ul .toggle-option ul li {
  border: 1px solid var(--lightBorderColor);
  border-radius: 3px;
  padding: 5px;
  flex: initial;
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: all .3s ease;
}

.toggle-public-configurator-options ul .toggle-option ul li .option-thumb {
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  opacity: .6;
}

.toggle-public-configurator-options ul .toggle-option ul li .option-thumb.matlib {
  background-size: 200%;
}

.toggle-public-configurator-options ul .toggle-option ul li .option-thumb.no-thumb {
  border: 1px solid var(--lightOutlineColor);
}

.toggle-public-configurator-options ul .toggle-option ul li .option-thumb.no-thumb:after {
  content: "";
  height: 100%;
  width: 1px;
  background-color: var(--lightOutlineColor);
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(45deg);
}

.toggle-public-configurator-options ul .toggle-option ul li h5 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  color: var(--lightestTextColor);
}

.toggle-public-configurator-options ul .toggle-option ul li:not(.selected):hover {
  cursor: pointer;
  opacity: .8;
  transform: translateY(-2px);
  border-color: var(--lightOutlineColor);
}

.toggle-public-configurator-options ul .toggle-option ul li.selected {
  border-color: var(--outlineColor);
}

.toggle-public-configurator-options ul .toggle-option ul li.selected h5 {
  color: var(--textColor);
}

.toggle-public-configurator-options ul .toggle-option ul li.selected .option-thumb {
  opacity: 1;
}

.toggle-public-configurator-options .toggle-expand-btn {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 1rem;
  font-weight: 500;
  color: var(--lightestTextColor);
}

.toggle-public-configurator-options .toggle-expand-btn span {
  font-size: 1.7rem;
}

.toggle-public-configurator-options .toggle-expand-btn:hover {
  opacity: .7;
}

.toggle-public-configurator-options .toggle-expand-btn .icon-chevron-down {
  display: inline-block;
  transform: scaleY(-1);
}

.toggle-public-configurator-options .toggle-expand-btn.expand-btn {
  position: fixed;
  top: auto;
  bottom: 25px;
  right: 38px;
  background-color: var(--pageColor);
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  padding: 10px;
  color: var(--primaryColor);
}

/* Experimental logic below */

.toggle-public-configurator-options.experimental {
  position: absolute;
  bottom: 50%;
  left: auto;
  right: 0;
  width: 300px;
  height: 500px;
  background-color: transparent;
  padding: 0;
  transform: translateY(50%);
}

.toggle-public-configurator-options.experimental:before {
  position: absolute;
  content: "";
  width: 500px;
  height: 500px;
  border: 5px solid var(--primaryColor);
  border-radius: 100%;
  right: -260px;
  top: 0;
}

.toggle-public-configurator-options.experimental .toggle-expand-btn {
  display: none;
}

.toggle-public-configurator-options.experimental ul {
  justify-content: center;
  align-items: center;
  height: 100%;
}

.toggle-public-configurator-options.experimental ul .toggle-option {
  flex: initial;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  align-items: center;
}

.toggle-public-configurator-options.experimental ul .toggle-option h4 {
  margin: 0 50px 0 90px;
  color: var(--primaryColor);
  font-size: 1.5rem;
}

.toggle-public-configurator-options.experimental ul .toggle-option ul {
  flex-direction: column;
}

.toggle-public-configurator-options.experimental ul .toggle-option ul li {
  flex-direction: row-reverse;
  border: none;
  width: auto;
  height: auto;
  position: relative;
  opacity: 1;
}

.toggle-public-configurator-options.experimental ul .toggle-option ul li .option-thumb {
  border-radius: 100%;
  height: 50px;
  width: 50px;
  opacity: 1;
}

.toggle-public-configurator-options.experimental ul .toggle-option ul li .option-thumb.no-thumb {
  background-color: #eee;
}

.toggle-public-configurator-options.experimental ul .toggle-option:not(:nth-of-type(1)) {
  display: none;
}

.toggle-public-configurator-options.experimental ul .toggle-option ul li:not(.selected):hover {
  cursor: pointer;
  opacity: 1;
}

.toggle-public-configurator-options.experimental ul .toggle-option ul li.selected h5 {
  color: var(--primaryColor);
  font-weight: bold;
}