.toggle-image-zoom {
  position: relative;
  text-align: center;
  padding: 5px;
}

.toggle-image-zoom .zoom-image {
  position: absolute;
  top: 10px;
  left: 15%;
  width: 70%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: 50% 50%;
  border: 1px solid var(--outlineColor);
  border-radius: 6px;
  z-index: 8888;
  pointer-events: none;
}

.toggle-image-zoom .zoom-image.grayscaled {
  filter: grayscale(1);
}