.toggle-reference-photo {
  background-color: var(--pageColor);
  color: var(--textColor);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 260px;
  min-width: 325px;
  gap: 10px;
  border: 2px solid transparent;
}

.toggle-reference-photo.fixed-layout {
  width: 300px !important;
  height: 260px !important;
}

.toggle-reference-photo-help {
  padding: 5px 15px;
  position: relative;
  display: flex;
  align-items: center;
}

.toggle-reference-photo-help span {
  font-size: 1.2rem;
  display: inline-block;
}

.toggle-reference-photo-help span:hover {
  color: var(--primaryColor);
}

.toggle-reference-photo-help-tip {
  background-color: var(--darkerBackgroundColor);
  padding: 4px;
  font-size: .9rem;
  border-radius: 6px;
  position: absolute;
  top: 0;
  left: 0;
  width: 200px;
  pointer-events: none;
}

.toggle-reference-photo-header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.toggle-reference-photo-header .update-photo-btn {
  margin: 0 0 0 auto;
  color: var(--primaryColor);
}

.toggle-reference-photo-header .close-btn {
  color: var(--lightestTextColor);
  font-size: 1.5rem;
  margin: 0 0 0 20px;
}

.toggle-reference-photo .expand-collapse-btn {
  color: inherit;
  padding: 3px;
  font-weight: bold;
}

.toggle-reference-photo .expand-collapse-btn:hover {
  color: var(--primaryColor);
}

.toggle-reference-photo.collapsed {
  min-height: 0;
  min-width: 0;
  background-color: var(--lightBorderColor);
  color: #000;
}

.toggle-reference-photo.collapsed .toggle-reference-photo-header {
  padding: 2px;
}

.toggle-reference-photo-content {
  flex: 1;
  min-height: 100px;
}

.toggle-reference-photo-content.upload-content {
  text-align: center;
}

.toggle-reference-photo-content .image-holder {
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 0;
  min-height: 100px;
}

.toggle-reference-photo.is-editing {
  border-color: var(--primaryColor);
}

.toggle-reference-photo .upload-image-section {
  padding: 0 15px 5px;
  margin: 0 10px;
}

.toggle-reference-photo .upload-image-section p {
  margin: 5px 0;
}

.toggle-reference-photo-edit-content {
  position: relative;
}

.toggle-reference-photo-edit-content ul {
  padding: 0 15px;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.toggle-reference-photo-edit-content ul li {
  display: flex;
}

.toggle-reference-photo-edit-content ul li h5 {
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.toggle-reference-photo-edit-content ul li button {
  margin: 0 0 0 auto;
}

.toggle-reference-photo-edit-content .reference-button-group {
  border-top: 1px solid var(--lightOutlineColor);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 10px;
}

.toggle-reference-photo-edit-content .reference-button-group button {
  padding: 10px;
  text-align: center;
  flex: initial;
  width: 50%;
}

.toggle-reference-photo-edit-content .reference-button-group button:hover {
  color: var(--primaryColor);
}

.toggle-reference-photo-edit-content .reference-button-group button:nth-of-type(1) {
  border-right: 1px solid var(--lightOutlineColor);
}

.toggle-reference-photo-pagination {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 0 15px 5px;
  align-items: center;
  justify-content: center;
}

.toggle-reference-photo-pagination button {
  display: block;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  border: 2px solid var(--lightOutlineColor);
}

.toggle-reference-photo-pagination button.selected {
  border-color: var(--primaryColor);
  background-color: var(--primaryColor);
}

.toggle-reference-photo-pagination button:not(.selected):hover {
  cursor: pointer;
  border-color: var(--primaryColor);
  opacity: 0.8;
  background-color: var(--primaryColor);
}

.image-holder img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.ref-image-wrapper {
  width: 100%;
  height: 100%;
}

.toggle-reference-photo .zoom-controls-wrapper {
  list-style: none;
  display: inline-block;
  flex: 1;
}

.toggle-reference-photo .zoom-percentage {
  font-size: 12px;
  font-weight: 500;
  background-color: var(--lightBorderColor);
  padding: 4.75px 7px;
  border-radius: 10px;
  user-select: none;
}

.toggle-reference-photo .zoom-btn{
  padding: 4px;
}