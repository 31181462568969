.full-width {
    width: 100%;
}

.dropdown-links-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
}

.dropdown-links-container .dropdown-element {
    letter-spacing: 0;
    cursor: pointer;
}

.dropdown-links-container .dropdown-links {
    position: absolute;
    top: 100%;
    background: var(--lightBackgroundColor);
    border-radius: 0px 0px 8px 8px;
    box-shadow: 0px 10px 20px var(--brandDefaultShadow);
}

.dropdown-links-container .dropdown-links.left{
    left: 0;
}

.dropdown-links-container .dropdown-links.right{
    right: 0;
}

.dropdown-links .each-dropdown-link {
    display: flex;
    align-items: center;
    gap: var(--quarterPadding);
    font-weight: 400;
    font-size: 0.9rem;
    padding: calc(var(--halfPadding) - 0.1rem);
    white-space: nowrap;
    transition: all 0.1s linear;
    position: relative;
}

.dropdown-links .each-dropdown-link.highlighted {
    background-color: var(--mediumBackgroundColor);
}

.dropdown-links .each-dropdown-link:hover{
    background: var(--brandLightColor);
    cursor: pointer;
}

.dropdown-links .each-dropdown-link:last-child{
    border-radius: 0px 0px 8px 8px;
}

.dropdown-link-icon {
    font-size: .8rem;
}

.dropdown-link-icon.right-icon {
    margin: 0 0 0 auto;
    font-size: .6rem;
}

.dropdown-links .each-dropdown-link ul {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    transform: translateX(100%);
    display: none;
    background: var(--lightBackgroundColor);
    border-radius: 8px;
    box-shadow: 0px 10px 20px var(--brandDefaultShadow);
    overflow: hidden;
}

.dropdown-links .each-dropdown-link:hover ul {
    display: block;
}