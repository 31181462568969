.orDivider {
    position: relative;
}

.orDivider hr {
    width: 80%;
    border: 1px solid #eee;
}
.orDivider.small hr {
    width: 50%;
}
.orDivider .divider-text {
    background-color: var(--backgroundColor);
    position: absolute;
    bottom: -10px;
    left: 45%;
    padding: 5px 10px;
    color: #ccc;
}