.toggle-lighting-panel {
    padding: var(--halfPadding);
}

.toggle-lighting-panel ul {
    display: flex;
    flex-direction: column;
    gap: var(--quarterPadding);
}

.toggle-lighting-panel ul li {
    border-radius: 20px;
    padding: var(--quarterPadding);
    text-align: center;
    border: 1px solid var(--lightBorderColor);
}

.toggle-lighting-panel ul li:not(.selected):hover {   
    cursor: pointer;
    background-color: var(--brandTertiaryColor);
}

.toggle-lighting-panel ul li.selected {
    background-color: var(--brandPrimaryColorDisabled);
    color: var(--textColorDisabled);
    border-color: var(--brandPrimaryColorDisabled);
}