.toggle-material-builder .back-btn {
  color: var(--textColor);
  margin: 0;
}

.material-selection-container {
  padding: 25px 20px 25px 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.material-builder-header {
  display: flex;
  padding: 10px 15px 0;
}

.build-material-option {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--lightBorderColor);
  border-radius: 5px;
  text-align: center;
  padding: 20px;
  max-height: 170px;
  min-height: 170px;
  color: var(--textColor);
}

.build-material-option h4 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.build-material-option hr {
  border-bottom: 2px;
  margin-block: 10px;
  width: 85%;
}

.build-material-option svg path {
  fill: var(--textColor);
}

.build-material-option:hover, .build-material-option:hover h4, .build-material-option:hover svg path {
  cursor: pointer;
  opacity: .8;
  color: var(--primaryColor);
  fill: var(--primaryColor);
  border-color: var(--primaryColor);
}

.material-color-create-footer {
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0;
  border-top: 1px solid var(--lightBorderColor);
  bottom: 0;
  color: var(--textColor);
  background-color: var(--pageColor);
}

.material-color-create-footer button {
  color: inherit;
  padding: 15px;
  text-align: center;
  flex: initial;
  width: 50%;
}

.material-color-create-footer button:first-of-type {
  border-right: 1px solid var(--lightBorderColor);
}

.selected-material-name {
  text-transform: capitalize;
}

.material-adjuster-reset {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  letter-spacing: 0;
}

.material-adjuster-reset h6 {
  font-size: 10px;
  font-weight: bold;
  color: var(--textColor);
}

.material-adjuster-reset h6:last-child {
  color: var(--primaryColor);
  cursor: pointer;
}

.material-adjuster-settings .toggle-popup-setting h5 {
  font-size: 15px;
  font-weight: bold;
  color: var(--textColor);
}

.material-adjuster-settings .slider {
  height: 8px;
}

.slider-percentage {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  font-weight: bold;
  margin-top: 5px;
  color: var(--textColor);
}

.material-adjuster-input h4 {
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0;
}

.material-adjuster-input input {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0;
}

.color-step-container {
  /* padding: 10px 40px 0; */
  /* height: calc(100% - 50px); */
  /* overflow: auto; */
}

.color-step-container ul, .toggle-material-image-create ul {
  min-height: 0px;
}

.color-step-container .material-adjuster-settings {
  max-height: none;
}

.color-step-container .material-adjuster-settings * {
  font-size: .9rem;
}

.color-step-container .material-adjuster-input h4 {
  font-size: 1.1rem;
}

.color-step-container .material-adjuster-input input {
  font-size: 1rem;
}

.color-step-container .threedy-color-picker .sketch-picker {
  width: 100% !important;
  padding: 20px 0 !important;
}

.material-builder-swatch {
  position: absolute;
  bottom: 20px;
  left: 12.5%;
}

.color-step-container .material-builder-swatch {
  position: relative;
  top: 0;
  left: 0;
  transform: translateY(-200%);
}