.toggle-element-configuration {
  position: absolute;
  top: var(--standardPadding);
  right: var(--standardPadding);
  z-index: 8;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.04);
  background-color: var(--pageColor);
  border-radius: 8px;
  width: 16rem;
  width: clamp(16rem, 12vw + 1rem, 18.75rem);
  overflow: hidden;
}

.toggle-element-configuration-menu {
  border-bottom: 1px solid var(--lightBorderColor);
  display: flex;
  align-items: center;
}

.toggle-element-configuration-menu li {
  flex: 1;
  text-align: center;
  background-color: var(--sidebarHeaderBg);
  padding: calc(1.5 * var(--quarterPadding)) calc(var(--quarterPadding) * 1.75);
}

.toggle-element-configuration-menu li:nth-child(2) {
  border-left: 1px solid var(--lightBorderColor);
  border-right: 1px solid var(--lightBorderColor);
}

.toggle-element-configuration-menu li.selected {
  background-color: var(--brandPrimaryColor);
  color: #fff;
  border-radius: 8px 8px 0 0;
}

.toggle-element-configuration-menu li:not(.selected):hover {
  cursor: pointer;
  background-color: var(--brandTertiaryColor);
  color: var(--brandPrimaryColor);
}

.toggle-element-configuration-menu li h6 {
  font-size: .85rem;
}

.toggle-element-configuration h6 {
  margin: 0;
  padding: 0;
}

.toggle-element-configuration-section {
  padding: var(--halfPadding);
}

.toggle-element-configuration-section:not(:last-of-type) {
  border-bottom: 1px solid var(--lightBorderColor);
}

.toggle-element-configuration-section .section-btn {
  width: 100%;
}

.toggle-element-configuration-section h6 {
  color: var(--textColorDisabled);
  display: flex;
  align-items: center;
}

.toggle-element-configuration-section h6 span {
  margin: 0 0 0 auto;
  display: inline-block;
}

.toggle-element-configuration-section .toggle-outline-btn {
  margin: var(--halfPadding) 0 0;
}

.toggle-element-applied-texture {
  display: flex;
  align-items: center;
  gap: var(--halfPadding);
  margin: var(--halfPadding) 0 0;
}

.toggle-element-applied-texture-thumb-container {
  flex: initial;
  width: 20%;
  background-color: var(--backgroundColor);
  height: 0;
  padding-bottom: 19%;
  border-radius: 8px;
  position: relative;
}

.toggle-element-applied-texture-thumb-container img {
  width: 70%;
  height: 70%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  border-radius: 100%;
}

.toggle-element-applied-texture span {
  width: 80%;
  flex: initial;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}