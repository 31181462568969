.toggle-toggle {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: var(--standardPadding);
    color: var(--textColor);
    justify-content: flex-end;
}

.toggle-toggle.disabled {
    pointer-events: none;
    opacity: 0.4;
}

.toggle-toggle:hover {
    cursor: pointer;
    opacity: 0.8;
}

.toggle-toggle h6 {
    margin: 0;
}

.toggle-toggle span {
    width: 50px;
    height: 24px;
    background: var(--lightBorderColor);
    border-radius: 20px;
    position: relative;
    display: grid;
    place-items: center;
    color: var(--textColor);
}

.toggle-toggle span:before {
    content: "";
    display: block;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background: var(--pageColor);
    position: absolute;
    top: 2px;
    left: 2px;
}

.toggle-toggle span b, .toggle-toggle span i {
    font-size: 11px;
    font-style: normal;
}

.toggle-toggle span i {
    position: absolute;
    top: 6px;
    right: 10px;
}

.toggle-toggle span b:first-child {
    opacity: 0;
}

.toggle-toggle.selected {
    color: var(--brandPrimaryColor);
}

.toggle-toggle.selected span {
    background: var(--brandPrimaryColor);
}

.toggle-toggle.selected span:before {
    left: auto;
    right: 2px;
}

.toggle-toggle.selected span i {
    right: auto;
    left: 10px;
    color: #fff;
}

.toggle-toggle.selected span b:first-child {
    opacity: 1;
}

.toggle-toggle.selected span b:last-child {
    opacity: 0;
}