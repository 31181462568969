.toggle-admin-user-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 40vw;
  background-color: var(--pageColor);
  box-shadow: -10px 2px 12px rgba(100,100,100,.1);
  z-index: 888;
  overflow-y: auto;
}

.toggle-admin-user-sidebar-header {
  border-bottom: 1px solid var(--lightBorderColor);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.toggle-admin-user-sidebar-header .close-btn {
  font-size: 1.5rem;
  color: var(--lighterTextColor);
}

.toggle-admin-user-sidebar-info {
  border-bottom: 1px solid var(--primaryColor);
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
}

.toggle-admin-user-sidebar-info.no-border {
  border: none;
}

.toggle-admin-user-sidebar-info .col {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.toggle-admin-user-sidebar-info h5 {
  color: var(--textColor);
}

.toggle-admin-user-sidebar-info h5 span {
  font-size: .8rem;
  display: block;
  text-transform: uppercase;
  color: var(--lighterTextColor);
}

.toggle-admin-user-sidebar-models-tabs {
  background-color: var(--backgroundColor);
  display: flex;
  gap: 10px;
}

.toggle-admin-user-sidebar-models-tabs li {
  padding: 15px;
}

.toggle-admin-user-sidebar-models-tabs li:not(.selected):hover {
  cursor: pointer;
  opacity: .7;
}

.toggle-admin-user-sidebar-models-tabs li.selected {
  background-color: var(--pageColor);
  border-top: 1px solid var(--lightBorderColor);
  border-right: 1px solid var(--lightBorderColor);
}

.toggle-admin-user-sidebar-models-tabs li h5 {
  color: var(--lighterTextColor);
  text-transform: uppercase;
  font-size: .9rem;
}

.toggle-admin-user-sidebar-models-tabs li.delete h5, .toggle-admin-user-sidebar-models-tabs li.delete h4 {
  color: var(--deleteColor);
}

.toggle-admin-user-sidebar-models-tabs li h4 {
  color: var(--textColor);
  font-size: 1.4rem;
  font-weight: 500;
}

.toggle-admin-user-sidebar-models-list li {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
}

.toggle-admin-user-sidebar-models-list li:not(:last-child) {
  border-bottom: 1px solid var(--lightBorderColor);
}

.toggle-admin-user-sidebar-models-list li:nth-of-type(1) {
  font-weight: 500;
}

.toggle-admin-user-sidebar-models-list li .col {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toggle-admin-user-sidebar-models-list li .col a {
  color: var(--primaryColor);
  font-weight: 500;
}