.toggle-sign-up-external {
  background-color: var(--lightBackgroundColor);
}

.toggle-sign-up-content {
  display: flex;
  margin: 0 auto;
  flex-direction: row;
  position: relative;
}

.toggle-sign-up-feature {
  flex: initial;
  padding: 82px 0 10px;
  width: 50%;
  background: var(--primaryColor);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 40px;
}

.toggle-sign-up-feature img {
  width: 80%;
}

.toggle-sign-up-feature h1 {
  color: #fff;
  padding: 0 10%;
}

.toggle-sign-up-div {
  margin: 0 auto;
  margin-top: 60px;
  padding: 5vh 5vw;
}

.toggle-sign-up-div.singular {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toggle-sign-up-div .heading,.toggle-sign-up-form .sub-heading{
  letter-spacing: -1px;
  text-align: center;
}
.toggle-sign-up-div .heading{
  font-weight: 700;
}
.toggle-sign-up-form .sub-heading{
  font-weight: 400;
}
.toggle-sign-up-div.singular .toggle-sign-up-form {
  max-width: 600px;
}
.threedy-lab-text-input h6{
  font-weight: 600;
  color: var(--subheaderTextColor);
  display: flex;
  gap: 0.2rem;
  margin: 0 0 var(--quarterPadding);
}
.threedy-lab-text-input h6 a {
  color: var(--brandPrimaryColor);
  text-decoration: underline;
}
.threedy-lab-text-input{
  display: flex;
  flex-direction: column;
  margin: 0.8rem 0;
  padding: 0;
}
.threedy-lab-text-input .terms-conditions{
  font-size: 0.8rem;
  margin: 0.7rem 0;
}
.threedy-lab-text-input input[type=text],
.threedy-lab-text-input input[type=email],
.threedy-lab-text-input input[type=password]{
  display: block;
  background-color: var(--pageColor);
  border-color: var(--outlineColor);
  border-radius: 6px;
  color: var(--textColor);
  font-family: inherit;
  outline: none;
  padding: 10px;
  width: 100%;
  border: 1px solid var(--outlineColor);
}
.toggle-sign-up-div.singular .toggle-sign-up-form, .toggle-sign-up-div.singular .toggle-sign-up-actions {
  margin: 0 auto;
}

.toggle-sign-up-form:not(.plan .toggle-sign-up-form), .toggle-sign-up-actions {
  max-width: 500px;
}
.large-button{
  padding: var(--halfPadding);
}
.medium-button{
  padding: 0.5rem var(--halfPadding);
}
.error{
  color: var(--redColor);
}
.toggle-sign-up-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.toggle-sign-up-div .section {
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 0 0 40px;
}

.toggle-sign-up-div .section-text {
  flex: initial;
  width: 50%;
}

.toggle-sign-up-div .section-text h2 {
  font-weight: 500;
}

.toggle-sign-up-div .section img {
  flex: initial;
  width: 45%;
}

.toggle-sign-up-div .plan-selector {
  gap: 20px;
  align-items: stretch;
}

.toggle-sign-up-div .plan-selector .plan-item {
  padding: 20px;
}

.toggle-splash.toggle-sign-up header .icon-menu {
  font-size: 1.5rem;
}

.toggle-mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--pageColor);
  z-index: 9999;
}

.toggle-mobile-menu ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.toggle-mobile-menu ul li button,
.toggle-mobile-menu ul li a {
  font-size: 1.5rem;
}

.mobile-close-btn {
  position: absolute;
  top: 1.25rem;
  right: 1.1rem;
  font-size: 1.5rem;
}

@media screen and (max-width: 900px) and (orientation: portrait) {
  .toggle-sign-up-div .plan-selector, .toggle-sign-up-content {
    flex-direction: column;
  }

  .toggle-sign-up-feature {
    width: 100%;
  }

  .toggle-sign-up-div {
    margin: 0;
  }

  .toggle-splash.toggle-sign-up header {
    position: static; 
    padding: 18px 0;
  }
}
