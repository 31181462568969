.toggle-popup-settings-menu {
  position: absolute;
  top: 255px;
  left: 25px;
  background: var(--pageColor);
  padding: 15px;
  border-radius: 10px;
  width: 275px;
  color: var(--textColor);
  max-height: calc(100vh - 275px);
  overflow: auto;
}

.toggle-popup-settings-menu.fixed {
  position: fixed;
  top: 80px;
  left: 440px;
  z-index: 9998;
}

.toggle-popup-settings-menu.fixed h5 {
  margin: 0;
}

.toggle-popup-settings-menu.fixed .toggle-popup-setting {
  margin: 0 0 20px;
}

.toggle-popup-settings-menu .close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: var(--lightestTextColor);
  font-size: 1.5rem;
}

.toggle-popup-settings-menu h4 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.toggle-popup-setting {
  margin: calc(var(--quarterPadding)*2) 0;
}

.material-adjuster-settings .toggle-popup-setting h6 {
  text-align: left;
  display: flex;
  align-items: center;
  font-size: .85rem;
}

.toggle-popup-setting .slider {
  -webkit-appearance: none;  
  appearance: none;
  width: 100%;  
  height: 15px;  
  border: none;
  background: var(--backgroundColor);
  outline: none;  
  margin: var(--halfPadding) 0 var(--quarterPadding);
  border-radius: 15px;
}

.toggle-popup-setting .slider::-webkit-slider-thumb {
  -webkit-appearance: none;  
  appearance: none;
  width: 12px;  
  height: 12px;  
  background: var(--brandPrimaryColor);  
  cursor: pointer;  
  border-radius: 100%;
}

.toggle-popup-setting .slider::-moz-range-thumb {
  width: 12px;  
  height: 12px;  
  background: var(--brandPrimaryColor);  
  cursor: pointer;  
  border-radius: 100%;
}

.toggle-popup-actions {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.toggle-popup-actions .primary-btn {
  margin: 0 0 0 20px;
}

.toggle-popup-setting ul {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 0;
}

.toggle-popup-setting ul li {
  flex: initial;
  width: 100%;
  display: block;
  font-size: 1rem;
  color: var(--textColor);
  padding: 10px 7px;
  border: 1px solid var(--outlineColor);
  border-radius: 4px;
  margin: 0;
  text-align: center;
}

.toggle-popup-setting ul li.selected {
  font-weight: bold;
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: #fff;
}

.toggle-popup-setting ul li:not(.selected):hover {
  cursor: pointer;
  color: var(--lightestTextColor);
  border-color: var(--lightestTextColor);
}

.uv-settings-input {
  background-color: var(--backgroundColor);
  width: 4rem;
  border-radius: 8px;
  margin-left: var(--quarterPadding);
  font-size: .9rem;
  padding: calc(var(--quarterPadding)/3);
}

.pattern-value {
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
  padding: 1px 6px 1px 4px;
  color: var(--lighterTextColor);
  margin: 0 0 0 var(--quarterPadding);
}

.pattern-value span {
  display: none !important;
}

.pattern-value:hover span {
  display: block !important;
}

.pattern-value:hover span:hover {
  color: var(--brandPrimaryColor);
  cursor: pointer;
}