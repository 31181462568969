.toggle-empty-label {
    background-color: var(--backgroundColor);
    border-radius: 20px;
    padding: var(--standardPadding);
}

.toggle-empty-label > :is(h5, h6) {
    text-align: center;
    margin-bottom: var(--standardPadding);
}

.empty-content-blocks {
    display: flex;
    justify-content: center;
}

.empty-content-blocks > * {
    flex-basis: 20%;
}

.empty-white-wrapper {
    padding: 3rem;
    background-color: var(--pageColor);
    border-radius: 20px;
}