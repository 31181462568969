.threedy-predictive-search {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 230px;
  padding: 10px 15px;
  z-index: 888;
  background-color: #fff;
  box-shadow: -4px 3px 3px rgb(1 1 1 / 10%);
  border-radius: 6px;
  transform: translateX(-50%);
  overflow: auto;
}

.threedy-predictive-search input {
  border: 1px solid var(--lightBorderColor);
  width: 100%;
  margin: 0 0 10px;
  padding: 5px;
  color: var(--textColor);
}

.threedy-predictive-search .btn-default {
  margin: 10px auto 0;
  border-top: 1px solid var(--lightBorderColor);
  display: block;
  width: 100%;
  padding: 14px 10px;
  color: var(--textColor);
}

.threedy-predictive-search ul li {
  margin: 0;
  padding: 0;
  border: none !important;
}

.threedy-predictive-search ul li button {
  padding: 2px 5px !important;
  width: 100% !important;
  max-width: none !important;
  border-radius: 6px !important;
  transition: none;
  color: var(--textColor) !important;
  word-break: break-all;
}

.threedy-predictive-search ul li button span {
  color: var(--primaryColor);
  font-weight: bold;
}

.threedy-predictive-search ul li:hover button {
  color: var(--primaryColor) !important;
}

.threedy-predictive-search h4 {
  text-align: center;
}