.toggle-login-page {
  position: relative;
  width: 100%;
  height: 100vh;
}

.toggle-login-page-content {
  border: 1px solid var(--outlineColor);
  border-radius: 6px;
  box-shadow: 3px 5px 13px rgba(1,1,1,.1);
  left: 50%;
  padding: 40px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  width: 500px;
}
.toggle-login-page-content input[type=email],
.toggle-login-page-content input[type=password] {
  border-radius: 0.4rem;
  display: block;
}
.toggle-login-page img {
  height: 80px;
  display: block;
  margin: 0 auto 20px;
}

.toggle-login-page-content button {
  margin: 20px auto 0;
  min-width: 230px;
  padding: var(--halfPadding);
  display: block;
}

.toggle-login-page-content input {
  border: 1px solid var(--outlineColor);
  padding: 10px;
  margin: 20px auto;
}

.toggle-login-page-content .action-link {
  color: var(--brandPrimaryColor);
  border-bottom: 1px solid;
  margin: 0 auto;
  width: auto;
  min-width: 0;
}

@media screen and (max-width: 900px) and (orientation: landscape) {

  .toggle-login-page {
    height: auto;
  }

  .toggle-login-page-content {
    position: static;
    left: auto;
    top: auto;
    transform: none;
    width: 70%;
    margin: 2rem auto;
    padding: 1rem;
  }

  .toggle-login-page img {
    padding: 10px;
    max-width: 50%;
    height: auto;
  }
}

@media screen and (max-width: 900px) and (orientation: portrait) {

  .toggle-login-page-content {
    width: 70%;
    padding: 1rem;
  }

  .toggle-login-page img {
    padding: 10px;
    max-width: 50%;
    height: auto;
  }
}