.toggle-part-viewer-item {
    padding: calc(var(--quarterPadding)/2) calc(var(--quarterPadding) * 1.75);
    display: flex;
    align-items: center;
}

.toggle-part-viewer-item.subitem {
    padding: calc(var(--quarterPadding)/2) calc(var(--quarterPadding) * 1.75) calc(var(--quarterPadding)/2) calc(var(--standardPadding)*2);
}

.toggle-part-viewer-list li:last-child {
    border-radius: 0 0 8px 8px;
}

.toggle-part-viewer-list li.selected {
    background-color: var(--brandPrimaryColor);
    color: #fff;
}

.toggle-part-viewer-list li.selected button:hover {
    color: #efefef;
}

.toggle-part-viewer-list li:not(.selected).hovering {
    background-color: var(--brandTertiaryColor);
}

.toggle-part-viewer-name {
    width: 80%;
    flex: initial;
    display: flex;
    align-items: center;
}

.toggle-part-viewer-name button:hover {
    color: var(--brandPrimaryColor);
}

.toggle-part-viewer-name button, .toggle-part-viewer-name input {
    padding: var(--quarterPadding);
    border: 1px solid transparent;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.toggle-part-viewer-name input {
    border: 1px solid var(--lightBorderColor);
    border-radius: 8px;
}

.toggle-part-viewer-item .sorting-handle,
.toggle-part-viewer-item .visibility-btn {
    visibility: hidden;
}

.toggle-part-viewer-item:hover .sorting-handle,
.toggle-part-viewer-item:hover .visibility-btn {
    visibility: visible;
}

.toggle-part-viewer-actions {
    flex: initial;
    width: 20%;
    display: flex;
    align-items: center;
    gap: var(--quarterPadding);
    justify-content: flex-end;
}

.toggle-part-viewer-actions .icon-visibility_off {
    color: var(--brandPrimaryColor);
}

.toggle-part-viewer-actions .icon-visibility {
    color: var(--lightestTextColor);
}

.toggle-part-viewer-list li.selected .toggle-part-viewer-actions .icon-visibility_off {
    color: #efefef;
}