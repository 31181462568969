
  
  .upgrade-card {
    border: 1px solid var(--brandPrimaryColor);
    padding: 1.5rem;
    margin: 0 var(--halfPadding);
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    letter-spacing: normal;
  }
  
  .upgrade-card .title {
    font-size: 0.9rem;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
  }
  
  .upgrade-card .text {
    font-size: 0.8rem;
    font-weight: 400;
    text-align: left;
  }

  .upgrade-card a {
    font-size: .9rem;
    text-align: center;
  }