.toggle-decals-list {
  position: relative;
  margin: var(--standardPadding) 0 0;
}

.toggle-decals-list:before {
  content: "";
  position: absolute;
  top: calc(-1.25 * var(--halfPadding));
  left: -10%;
  background-color: var(--lightBorderColor);
  width: 120%;
  height: 1px;
}

.toggle-decals-list .toggle-content-list div:first-of-type {
  width: 80%;
  flex: initial;
  display: flex;
}

.toggle-decals-list .toggle-content-list h6 {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
}

.toggle-decals-list .toggle-content-list button {
  color: var(--lightTextColor);
  padding: calc(var(--quarterPadding)/2);
}

.toggle-decal-img-container {
  flex: initial;
  flex-shrink: 0;
  height: 2rem;
  width: 2rem;
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--backgroundColor);
  position: relative;
}

.toggle-decal-img-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 70%;
  height: 70%;
  object-fit: cover;
  transform: translate(-50%,-50%);
}