.tour-step-content-wrapper svg,
.tour-step-content-wrapper svg path {
    display: inline;
    fill: #fff;
}

.reactTour-skip-btn {
    background-color: transparent;
    border: 0px;
    border-radius: 0px;
    color: rgb(51, 51, 51);
    cursor: pointer;
    font-size: 13px;
    line-height: 1;
    padding: 8px 0;
    appearance: none;
}

.reactTour-next-btn {
    background-color: var(--primaryColor);
    border: 1px solid #fff;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    font-size: 16px;
    line-height: 1;
    padding: 8px;
    appearance: none;
}

.reactTour-skip-btn:hover,
.reactTour-skip-btn:hover {
    opacity: .8;
}

.text-white,
.tour-step-title-wrapper.text-white span {
    color: #fff;
}

.tour-step-title-wrapper.text-white span:first-child {
    font-size: 18px;
}



.tour-step-content-wrapper.text-white {
    font-size: 14px;
    margin-bottom: 5px;
}

.reactTour-skip-btn.text-white {
    color: var(--lightGrayedColor);
}

.reactour__popover:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: var(--rtp-arrow-top, auto);
    right: var(--rtp-arrow-right, auto);
    bottom: var(--rtp-arrow-bottom, auto);
    left: var(--rtp-arrow-left, auto);
    border-top: var(--rtp-arrow-border-top);
    border-right: var(--rtp-arrow-border-right);
    border-bottom: var(--rtp-arrow-border-bottom);
    border-left: var(--rtp-arrow-border-left);
    /* background-color: var(--primaryColor); */
    /* border-color: var(--primaryColor); */
}