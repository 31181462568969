.toggle-integrations-list {
  margin: 20px 0 0;
}

.toggle-integrations-list li {
  display: flex;
  gap: 10px;
  align-items: center;
}

.toggle-integrations-list li img {
  height: 30px;
  flex: initial;
}

.toggle-integrations-list li h5 {
  flex: initial;
}

.toggle-integrations-list li button {
  margin: 0 0 0 auto;
  padding: 5px 10px;
  display: block;
  flex: initial;
}

.toggle-authorizer-modal iframe {
  border: none;
  width: 100%;
  height: 70vh;
}

.calculating {
  color: var(--lighterTextColor);
  clip-path: inset(0 6ch 0 0);
  animation: cIng 1s steps(4) infinite;
}

@keyframes cIng {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}

.usage-text {
  text-align: center;
  color: var(--lighterTextColor);
  font-size: 12px;
}

.settings-wrapper {
  display: flex;
  flex-direction: row;
  padding: 3rem 4rem;
  background: var(--pageColor);
  border-radius: 8px;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: flex-start;
}

.settings-wrapper .left-container, .settings-wrapper .right-container, .settings-wrapper .full-container {
  display: flex;
  padding: 0 var(--quarterPadding);
  flex-direction: column;
  gap: 0.8rem;
}

.settings-wrapper .settings-card {
  padding: 2rem;
  border: 1px solid var(--lighterBorderColor);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.settings-wrapper .settings-card.no-border {
  border-color: transparent;
}

.settings-wrapper .settings-card .heading {
  font-weight: 700;
  font-size: 0.8rem;
  color: var(--lightTextColor);
}

.settings-wrapper .settings-card .sub-heading {
  font-weight: 400;
  color: var(--lightTextColor);
  font-size: 0.8rem;
  padding: 0.1rem;
}

.settings-wrapper .settings-card .each-setting {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.settings-wrapper .settings-card .each-setting.inline {
  flex-direction: row;
  align-items: flex-end;
}

.settings-wrapper .settings-card .each-setting .form-input {
  display: flex;
  flex-direction: column;
  position: relative;
}

.settings-wrapper .settings-card .each-setting .form-input input {
  border: 1px solid var(--ultraLightGrayColor);
  padding: 0.6rem;
  border-radius: 8px;
  margin: 0.3rem 0;
}

.settings-wrapper .settings-card .each-setting .form-input button {
  align-self: flex-end;
}

.settings-wrapper .settings-card .each-setting .last-updated-text {
  color: var(--lightestTextColor);
  text-align: right;
  padding-right: var(--halfPadding);
}

.primary-tag, .outline-tag {
  font-weight: 600;
  font-size: 1.2rem;
  border-radius: 50%;
  padding: 3px;
}

.primary-tag {
  background: var(--brandPrimaryColor);
  color: #fff;
}

.outline-tag {
  background-color: #eee;
  color: #333;
}

.settings-wrapper .settings-interface ul li {
  margin-bottom: 0.8rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.settings-wrapper .cross-svg-container {
  bottom: 10px;
  position: absolute;
  right: 10px;
}

.verify-loader {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 255, 255) 33%, var(--brandPrimaryColor) 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  right: -30px;
  bottom: 10px;
}

.verify-loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}