.threedy-lab-workspace-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0,0,0,.2);
}

.threedy-lab-workspace-modal-container.real-slim .threedy-lab-workspace-modal  {
  width: 40%;
}

.threedy-lab-workspace-modal-container.slim .threedy-lab-workspace-modal  {
  width: 60%;
}

.threedy-lab-workspace-modal {
  position: absolute;
  top: 5%;
  left: 50%;
  width: 60%;
  background: var(--pageColor);
  border-radius: 6px;
  padding: 20px;
  box-shadow: 4px 10px 20px rgba(1,1,1,.2);
  max-width: 1000px;
  transform: translateX(-50%);
  /* max-height: 492px; */
  color: var(--textColor);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.threedy-lab-workspace-modal.full-screen {
  width: 80%;
  left: 10%;
  height: 80vh;
  top: 10vh;
  transform: none;
  max-width: none;
  max-height: none;
  padding: 0;
}

.threedy-lab-workspace-modal.full-screen .threedy-lab-workspace-modal-menu {
  padding: 20px;
  margin: 0;
}

.threedy-lab-workspace-modal-content {
  flex: 1;
  height: 73.4vh;
}

@media screen and (max-height: 1000px) and (max-width: 1280px) {
  .threedy-lab-workspace-modal {
    width: 80%;
  }

  .threedy-lab-workspace-modal-container.real-slim .threedy-lab-workspace-modal  {
    width: 50%;
  }

  .threedy-lab-workspace-modal-container.slim .threedy-lab-workspace-modal  {
    width: 80%;
  }
}

.threedy-lab-workspace-modal ul {
  max-height: 20vh;
  min-height: 100%;
  overflow: auto;
}

.threedy-lab-workspace-modal-container.sidebar .threedy-lab-workspace-modal {
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: none;
  border-radius: 0;
  box-shadow: none;
}

.threedy-lab-workspace-modal-menu {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}

.threedy-lab-workspace-modal-menu h2 span {
  font-weight: normal;
}

.threedy-lab-workspace-modal-menu button {
  margin: 0 0 0 auto;
  font-size: 1.75rem;
  color: var(--lightGrayedColor);
}

.threedy-lab-workspace-modal-actions {
  display: flex;
  margin: 20px 0 0;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.modal-page {
  max-height: 80vh;
  overflow: scroll;
}

.threedy-lab-workspace-modal video {
  width: 100%;
  margin: 0;
  padding: 0;
}

.small .threedy-lab-workspace-modal {
  width: 25%;
}

.small .threedy-lab-workspace-modal-content ul.threedy-list {
  max-height: unset;
}

.small .threedy-lab-workspace-modal-actions {
  margin-top: 10px;
}

.medium .threedy-lab-workspace-modal-content {
  max-height: 30vh;
  overflow-y: auto;
}

.medium .threedy-lab-workspace-modal {
  width: 35%;
}

.threedy-lab-workspace-modal-content .content-text{
  font-weight: 500;
}