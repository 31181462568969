.toggle-preview-dropdown {
  position: absolute;
  bottom: 12px;
  left: -110px;
}

.toggle-preview-dropdown .toggle-dropdown-component {
  width: 100px;
}

.toggle-preview-dropdown .toggle-dropdown-component:hover {
  background-color: transparent;
}

.toggle-preview-dropdown .toggle-dropdown-component:hover .toggle-dropdown-component-header h5 {
  color: var(--primaryColor);
}

.toggle-preview-dropdown .toggle-dropdown-component .toggle-dropdown-component-header {
  gap: 0;
}

.toggle-preview-dropdown .toggle-dropdown-component .toggle-dropdown-component-header h5 {
  background-color: var(--pageColor);
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
  padding: 4.75px 7px;
  width: 100%;
  font-size: 12px;
  color: var(--textColor);
  text-transform: uppercase;
}

.toggle-preview-dropdown .toggle-dropdown-component .toggle-dropdown-component-expanded {
  width: 100px;
  top: auto;
  bottom: 0;
  background-color: var(--pageColor);
  border: 1px solid var(--primaryColor);
  border-radius: 10px;
}

.toggle-preview-dropdown .toggle-dropdown-component .toggle-dropdown-component-expanded li {
  font-size: 12px;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}