.toggle-material-image-create .step-container {
  max-width: 800px;
  /* height: calc(100% - 50px) !important; */
  margin: 0 !important;
  /* overflow: auto; */
}

.material-texture-expand .step-container {
  margin: 20px auto 0;
  max-width: 800px;
  height: 100%;
}

.toggle-canvas-holder {
  display: flex;
  gap: 25px;
  position: sticky;
  top: 25px;
  background-color: var(--pageColor);
}

.toggle-canvas-holder .input-canvas, .toggle-canvas-holder .output-canvas {
  display: none;
}

.toggle-canvas-holder .preview-canvas {
  border: 2px solid var(--primaryColor);
  border-radius: 6px;
  width: 48%;
  height: 300px;
  flex: initial;
}

.toggle-canvas-holder .heatmap-canvas {
  border: 2px solid var(--greenColor);
  border-radius: 6px;
  height: 300px;
  flex: initial;
  width: 48%;
}

.material-image-create-header {
  display: flex;
  align-items: center;
  gap: 9%;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.material-texture-expand .upload-image-section {
  width: 500px;
  padding: 30px 0 40px;
  margin: 40px auto;
}

.material-texture-expand .uploaded-image-section {
  margin-block: 30px;
}

.material-texture-expand .toggle-popup-setting h5 {
  color: var(--textColor);
  font-weight: bold;
}

.material-texture-expand .toggle-popup-setting input {
  height: 8px;
}

.toggle-pattern-holder {
  width: 200px;
  height: 200px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  margin: 0 auto;
  border-radius: 6px;
  overflow: hidden;
}

.toggle-pattern-visual {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-radius: 6px;
  border: 2px solid var(--primaryColor);
  transform: translate(-50%,-50%);
}

.material-texture-expand p {
  margin: 0 0 .5rem;
}

.material-texture-expand .crop-tips {
  display: inline-flex;
  margin: 0 0 0 0.5rem;
  gap: 0.5rem;
  max-height: none;
  min-height: 0;
  flex-wrap: wrap;
  overflow: hidden;
}

.material-texture-expand .crop-tips li {
  margin: 0;
  padding: 0;
  font-size: .85rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.material-texture-expand .crop-tips li:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  background-color: var(--lightOutlineColor);
  border-radius: 100%;
}

.crop-container {
  width: 80%;
  margin: 40px auto 0;
  border: 1px dashed var(--primaryColor);
  text-align: center;
}

.step-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 20px;
}

.step-header h5 {
  display: flex;
  margin: 0;
  padding: 0;
  gap: 5px;
  align-items: center;
}

.material-texture-lighting-adjust {
  margin: 20px 0 0;
}