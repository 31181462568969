/* Customize the label (the custom-container-input) */
.custom-container-input {
    display: inline-block;
    position: relative;
    height: 1rem;
    width: 1rem;
    margin: 0;
    cursor: pointer;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
}

/* Hide the browser's default checkbox */
.custom-container-input input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-container-input.disabled {
    pointer-events: none;
    opacity: 0.2;
}

/* Create a custom checkbox */
.custom-container-input .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: .9rem;
    width: .9rem;
    border: 0.5px solid var(--borderColor);
    border-radius: 2px;
}

/* On mouse-over, add a grey background color */
.custom-container-input:hover input~.checkmark {
    background-color: var(--backgroundColor);
}

/* When the checkbox is checked, add a blue background */
.custom-container-input input:checked~.checkmark {
    background: var(--brandPrimaryColor);
    border: 0.5px solid var(--brandPrimaryColor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-container-input input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-container-input .checkmark:after {
    left: 4px;
    top: 0px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0px 1px 1px 0;
    transform: rotate(45deg);
}