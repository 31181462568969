@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import "./icons.css";

* {
  box-sizing: border-box;
}

#toggle-root,
.toggle-root-container,
body,
html {
  height: 100%;
}

.toggle-root-container {
  --brandPrimaryColor: #513ad1;
  --brandPrimaryColorDisabled: #EEEDF8;
  --brandSecondaryColor: #542aae;
  --brandTertiaryColor: #E2DBF0;
  --brandLightColor: #F1F0F9;
  --brandPinkColor: #D535BB;
  --purpleColor: #8005ff;
  --darkPurpleColor: #0e0450;
  --highlightColor: #f3f4fa;
  --lightBorderColor: #efefef;
  --lighterBorderColor: #f2f2f2;
  --borderColor: #DFDFDF;
  --outlineColor: #000;
  --lightOutlineColor: #ccc;
  --backgroundColor: #f5f5f5;
  --lightBackgroundColor: #fafafa;
  --mediumBackgroundColor: #F7F6FB;
  --darkerBackgroundColor: #e6e5e7;
  --lightBlueBackgroundColor: #FAFAFD;
  --pageColor: #fff;
  --headerTextColor: #000;
  --tabTextColor: #666;
  --subheaderTextColor: #333;
  --textColor: #0F1348;
  --textColorDisabled: #4d4d4d;
  --darkTextColor: #000;
  --lightTextColor: #767676;
  --lightestTextColor: #999;
  --readOnlyBackgroundColor: #fafafa;
  --readOnlyTextColor: #999;
  --statusBlueBackgroundColor: #F3F5FD;
  --statusBlueTextColor: #594AB5;
  --statusGreenBackgroundColor: #F1FBF3;
  --statusGreenTextColor: #077D21;
  --statusRedTextColor: #9C001C;
  --statusRedBackgroundColor: #FFF6F5;
  --greenColor: rgb(7, 233, 124);
  --redColor: rgb(255, 49, 97);
  --orangeColor: rgb(240, 124, 16);
  --yellowColor: rgb(255, 250, 200);
  --ultraLightGrayColor: #E6E6E6;
  --darkGrayedColor: #DBDBDB;
  --lightGrayedColor: #B7B7B7;
  --darkBorderColor: #000;
  --deleteColor: #ff0066;
  --sidebarHeaderBg: #f9f9f9;
  --doublePadding: clamp(1rem, 0.75vw + 1rem, 3rem);
  --standardPadding: clamp(0.5rem, 0.75vw + 1rem, 1.5rem);
  --halfPadding: clamp(0.25rem, 0.37vw + 1rem, 0.75rem);
  --quarterPadding: clamp(0.125rem, 0.17vw + 1rem, 0.375rem);
  --brandDefaultShadow: rgba(34, 34, 34, 0.1);
  --headerHeight: 3.75rem;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  color: var(--textColor);
  overflow: hidden;
  flex-wrap: nowrap;
  align-items: stretch;
}

.toggle-root-container.white-bg .toggle-content-group {
  background-color: var(--lightBackgroundColor);
  margin: 0 0 var(--standardPadding);
}

.toggle-root-container.dark {
  --brandPrimaryColor: rgb(105, 56, 250);
  --highlightColor: #292d45;
  --darkBorderColor: #555;
  --outlineColor: #aaaaaa;
  --lightOutlineColor: #666;
  --lightBackgroundColor: #292c3f;
  --backgroundColor: #292c3f;
  --darkerBackgroundColor: #1e2031;
  --pageColor: #363e52;
  --headerTextColor: #fff;
  --tabTextColor: #aaa;
  --subheaderTextColor: #ccc;
  --textColor: #fff;
  --lightTextColor: #eee;
  --lightestTextColor: #aaa;
  --readOnlyBackgroundColor: #292d45;
  --readOnlyTextColor: #888;
  --borderColor: #ccc;
  --darkGrayedColor: #333545;
  --lightBorderColor: #8f8f8f;
}

body {
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-size: .875rem;
  font-weight: 400;
  letter-spacing: -1px;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Resets */
a {
  text-decoration: none;
  color: inherit;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

button,
textarea,
input {
  text-rendering: inherit;
  font: inherit;
  letter-spacing: inherit;
  color: inherit;
  background-color: transparent;
  outline: none;
  padding: 0;
  margin: 0;
  border: none;
  max-width: 100%;
  white-space: nowrap;
}

input:not(:read-only):focus {
  border-color: var(--brandPrimaryColor) !important;
}

button:disabled {
  pointer-events: none;
  opacity: .4;
}

button:hover {
  cursor: pointer;
  opacity: .8;
}

/* End resets */

/* Header font sizing */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: 700;
  letter-spacing: 0.01rem;
}

h1 {
  font-size: 1.53rem;
  font-size: clamp(1.853rem, 2vw + 1rem, 2.5rem);
  margin: 0 0 var(--doublePadding);
}

h2 {
  font-size: 1.56rem;
  font-size: clamp(1.56rem, 1.75vw + 1rem, 2.14rem);
  margin: 0 0 var(--doublePadding);
}

h3 {
  font-size: 1.34rem;
  font-size: clamp(1.34rem, 1.5vw + 1rem, 1.78rem);
  margin: 0 0 var(--standardPadding);
}

h4 {
  font-size: 1.12rem;
  font-size: clamp(1.12rem, 1.25vw + 1rem, 1.48rem);
  margin: 0 0 var(--standardPadding);
}

h5 {
  font-size: 1rem;
  font-size: clamp(1rem, 1vw + 1rem, 1.22rem);
  margin: 0 0 var(--halfPadding);
}

h6 {
  font-weight: 500;
  font-size: .8rem;
  font-size: clamp(.8rem, 0.5vw + 1rem, .95rem);
  margin: 0 0 var(--halfPadding);
}

/* End font sizing */

/* Colors */
.toggle-color-dark-text {
  color: var(--darkTextColor);
}

.toggle-color-light-text {
  color: var(--lightTextColor);
}

.toggle-color-brand-primary {
  color: var(--brandPrimaryColor);
}

.toggle-color-brand-secondary {
  color: var(--brandSecondaryColor);
}

.toggle-color-brand-tertiary {
  color: var(--brandTertiaryColor);
}

.toggle-color-brand-light {
  color: var(--brandLightColor);
}

.toggle-color-brand-pink {
  color: var(--brandPinkColor);
}

.toggle-color-delete {
  color: var(--deleteColor);
}

/* End colors */

/* Buttons */

.toggle-btn-group {
  display: flex;
  gap: var(--halfPadding);
  align-items: center;
}

.toggle-primary-btn {
  background-color: var(--brandPrimaryColor);
  color: #fff;
  border-radius: 20px;
  padding: var(--quarterPadding) var(--standardPadding);
  margin: var(--quarterPadding) 0;
}

.toggle-primary-btn.rectangular {
  border-radius: 6px;
}

.toggle-primary-btn:disabled {
  background-color: var(--brandPrimaryColorDisabled);
  color: var(--textColorDisabled);
}

.toggle-secondary-btn {
  border-radius: 20px;
  padding: var(--quarterPadding) var(--halfPadding);
  border: 1px solid var(--lightBorderColor);
  color: #fff;
  background-color: var(--brandSecondaryColor);
  margin: var(--quarterPadding) 0;
}

.toggle-secondary-btn:disabled {
  color: var(--lightTextColor);
}

.toggle-outline-btn {
  border-radius: 20px;
  padding: var(--quarterPadding) var(--halfPadding);
  border: 1px solid var(--borderColor);
  color: var(--textColor);
  font-weight: 500;
  display: block;
  width: 100%;
}

.rectangular-btn {
  border-radius: 6px !important;
}

.auto-width {
  width: auto !important;
}

.toggle-outline-btn.primary-btn {
  border: 1px solid var(--brandPrimaryColor);
  color: var(--brandPrimaryColor);
  transition: 0.2s background ease-in;
}

.toggle-outline-btn.primary-btn:hover {
  color: var(--brandLightColor);
  background-color: var(--brandPrimaryColor);
}

.toggle-outline-btn.delete-btn {
  border: 1px solid var(--redColor);
  color: var(--deleteColor);
  transition: 0.2s background ease-in;
}

.toggle-outline-btn.delete-btn:hover {
  color: var(--brandLightColor);
  background-color: var(--redColor);
}

.toggle-outline-filled-btn {
  border-radius: 20px;
  padding: var(--quarterPadding) var(--halfPadding);
  border: 1px solid var(--borderColor);
  color: var(--textColor);
  font-weight: 500;
  background-color: var(--lightBackgroundColor);
}

.toggle-outline-btn:hover,
.toggle-outline-filled-btn:hover {
  cursor: pointer;
  color: var(--brandPrimaryColor);
}

.toggle-default-btn {
  font-weight: 500;
  border-radius: 20px;
  padding: 0.25rem 1rem;
  padding: var(--quarterPadding) var(--standardPadding);
  border: 1px solid var(--lightBorderColor);
  margin: var(--quarterPadding) 0;
  color: var(--textColor);
  background-color: var(--pageColor);
}

.hover-to-primary:hover {
  color: var(--brandPrimaryColor) !important;
}

.hover-to-primary:hover>span {
  color: var(--textColor);
}

.toggle-default-btn:hover {
  color: var(--brandPrimaryColor);
  background-color: var(--darkerBackgroundColor);
  border-color: var(--darkerBackgroundColor);
}

/* End buttons */

/* Input */
.toggle-text-input {
  padding: var(--quarterPadding) var(--standardPadding);
  border: 1px solid var(--lightBorderColor);
  margin: var(--quarterPadding) 0;
  color: var(--textColor);
  border-radius: 8px;
}

/* Status labels */
.toggle-label {
  display: inline-block;
  background-color: var(--statusBlueBackgroundColor);
  color: var(--statusBlueTextColor);
  border-radius: 20px;
  padding: 0.25rem 1rem;
  padding: var(--quarterPadding) var(--standardPadding);
}

.toggle-label.green {
  background-color: var(--statusGreenBackgroundColor);
  color: var(--statusGreenTextColor);
}

.toggle-label.red {
  background-color: var(--statusRedBackgroundColor);
  color: var(--statusRedTextColor);
}

.toggle-label.exporting {
  color: var(--textColor);
}

.toggle-label.exported {
  background-color: var(--brandPrimaryColor);
  color: var(--pageColor);
  cursor: pointer;
}

.toggle-label.failed {
  border: 1px solid var(--borderColor);
  color: var(--lightTextColor);
  cursor: pointer;
}

/* End status labels */

/* Page container */
.toggle-page-container {
  flex: 1;
  background-color: var(--backgroundColor);
  display: flex;
  overflow: hidden;
}

/* Page content */
.toggle-page-content-wrapper {
  overflow-y: auto;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
}

.toggle-page-content {
  border-radius: 10px;
  flex: 1;
}

.toggle-page-content.editor {
  margin: 0;
  border-radius: 0;
  height: 100%;
  overflow: hidden;
}

/* Content group */
.toggle-content-group {
  width: 100%;
  border-radius: 10px;
}

/* Content blocks */
.toggle-content-blocks {
  --_cols: 8;
  --_gap: var(--gap, var(--standardPadding));
  --_min: var(--min, 200px);
  --_gap-count: calc(var(--_cols) - 1);
  --_total-gap-width: calc(var(--_gap-count) * var(--_gap));
  --_grid-item--max-width: calc((100% - var(--_total-gap-width)) / var(--_cols));
  --_grid-item-height: 14rem;
  --_grid-item-height: clamp(12.5rem, 16vw + 1rem, 14.375rem);
  display: grid;
  grid-gap: var(--_gap);
  grid-template-columns: repeat(auto-fill, minmax(max(var(--_min), var(--_grid-item--max-width)), 1fr));
  grid-template-rows: auto;
  grid-auto-rows: 0;
  height: calc(var(--_grid-item-height) + var(--quarterPadding));
  overflow: hidden;
  padding: 0 var(--doublePadding) 0 var(--doublePadding);
}

.toggle-content-blocks.unlimited-height {
  height: auto;
  grid-auto-rows: auto;
  padding: var(--doublePadding) var(--doublePadding) var(--standardPadding) var(--doublePadding);
}


/* Content block */
.toggle-content-block {
  box-shadow: -4px 8px 20px rgba(1, 1, 1, .1);
  border-radius: 10px;
  padding: 0.5rem;
  padding: var(--standardPadding);
  background-color: var(--pageColor);
}

/* Content list */
.toggle-content-list {
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: var(--halfPadding) 0 0;
}

.toggle-content-list li {
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
  padding: calc(var(--quarterPadding)*1.5) 0;
}

.toggle-content-list li.disabled {
  pointer-events: none;
  opacity: .4;
}

.toggle-content-list li.selected {
  background-color: var(--brandTertiaryColor);
  border-color: transparent;
}

.toggle-content-list li:not(:last-child) {
  border-bottom: 1px solid var(--lightBorderColor);
}

.toggle-content-list li div {
  flex: 1;
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
}

.toggle-content-list li div.initial {
  flex: initial;
}

.toggle-content-list li div:not(.not-justified):last-of-type {
  justify-content: flex-end;
}

.toggle-content-list li.rounded {
  border: 1px solid var(--lightBorderColor);
  border-radius: 20px;
  margin: var(--quarterPadding) 0;
  padding: calc(var(--quarterPadding)/2) var(--quarterPadding);
}

.toggle-content-list li.actionable:hover {
  cursor: pointer;
  opacity: .8;
  color: var(--brandPrimaryColor);
}

/* Scrollbars */
/* sm = small, lg=large, md=medium, xs=xtra small */
.scrollbar-sm::-webkit-scrollbar {
  width: calc(var(--halfPadding) + var(--quarterPadding) + 0.1rem);
}

.scrollbar-sm::-webkit-scrollbar-track {
  background: var(--backgroundColor);
  border-left: 15px solid var(--pageColor);
}

.scrollbar-sm::-webkit-scrollbar-thumb {
  background: var(--darkerBackgroundColor);
  border-left: 15px solid var(--pageColor);
}

/* Flex containers */
.toggle-flex-container {
  display: flex;
  align-items: center;
  gap: var(--standardPadding);
  margin: var(--standardPadding) 0;
}

.toggle-flex-container>* {
  margin: 0;
}

/* Misc */
.toggle-no-items {
  display: block !important;
  text-align: center;
  color: var(--lightestTextColor) !important;
  font-size: .87rem;
  margin: 0;
  padding: 0;
}

.align-center, .align-right, .align-left {
  display: block;
}

.align-center {
  margin: var(--halfPadding) auto 0;
}

.align-right {
  margin: var(--halfPadding) 0 0 auto;
}

.align-left {
  margin: var(--halfPadding) auto 0 0;
}

.capitalize {
  text-transform: capitalize;
}

.toggle-in-page-container {
  border-radius: 6px;
  margin: var(--standardPadding);
  padding: var(--halfPadding);
}

.hidden {
  display: none !important;
}