.editor-component-wrapper .threedy-lab-page-content {
    display: grid;
    justify-content: center;
    align-items: center;
    margin-block: 10rem;
}

.editor-component-wrapper h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 24px;
    color: var(--primaryColor);
}

.templates {
    display: flex;
    gap: 2rem;
}

.editor-template-wrapper h5 {
    text-align: center;
    margin-block: 1rem;
    font-weight: 400;
    letter-spacing: normal;
}

.editor-template {
    position: relative;
    border: 1px solid;
    display: grid;
    place-content: center;
    padding: 2rem;
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
}

.editor-template span svg {
    width: 175px;
    height: 113px;
}

.editor-template span.scale-up {
    transform: scale(1.5);
}

.editor-template.locked span.right-top {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ccc;
    font-size: 15px;
}
.editor-template-wrapper:hover>.editor-template {
    border-color: var(--primaryColor);
    transition: 0.1s all linear;
}

.editor-template-wrapper:hover>.editor-template svg>path {
    fill: var(--primaryColor);
    transition: 0.1s all linear;
}

.editor-template-wrapper:hover>h5 {
    color: var(--primaryColor);
    transition: 0.1s all linear;
}