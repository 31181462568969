.threedy-lab-selection-menu {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--pageColor);
  padding: 5px 7px;
  border-radius: 6px;
  box-shadow: 8px 12px 12px rgba(1,1,1,.1);
  min-width: 175px;
}

.threedy-lab-selection-menu li {
  margin: 2px 0;
  position: relative;
}

.threedy-lab-selection-menu li button:not(.secondary-btn) {
  font-size: 13px;
  font-weight: 400;
  padding: 3px;
  color: #000;
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: left;
}

.threedy-lab-selection-menu li button:hover {
  color: var(--primaryColor);
}

.threedy-lab-selection-menu li button:hover svg>path {
  fill: var(--primaryColor);
}
.threedy-lab-selection-menu li .threedy-predictive-search {
  position: absolute !important;
  top: 0 !important;
  left: auto !important;
  right: 0 !important;
  transform: translateX(97%) !important;
  display: none;
}

.threedy-lab-selection-menu li.has-subitems:hover .threedy-predictive-search {
  display: block;
}
