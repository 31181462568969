/*
 Use below URL to compile and see the output
 https://jsonformatter.org/scss-to-css 
*/
@for $i from 1 through 7 {
    .fw-#{$i * 100} {
        font-weight: ($i * 100);
    }
}

@for $i from 1 through 72 {

    // Font Size
    .fs-#{$i} {
        font-size: #{$i}px;
    }

    // Rounded border top
    .rt-#{$i} {
        border-top-right-radius: #{$i}px;
        border-top-left-radius: #{$i}px;
    }

    // Rounded border bottom
    .rb-#{$i} {
        border-bottom-right-radius: #{$i}px;
        border-bottom-left-radius: #{$i}px;
    }
}

@for $i from 0 through 500 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }

    .pt-#{$i} {
        padding-top: #{$i}px;
    }

    .pl-#{$i} {
        padding-left: #{$i}px;
    }

    .pb-#{$i} {
        padding-bottom: #{$i}px;
    }

    .pr-#{$i} {
        padding-right: #{$i}px;
    }

    .m-#{$i} {
        margin: #{$i}px !important;
    }

    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }

    .ml-#{$i} {
        margin-left: #{$i}px;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}px;
    }

    .mr-#{$i} {
        margin-right: #{$i}px;
    }

    .vh-#{$i} {
        height: #{$i}vh;
    }

    .h-#{$i} {
        height: #{$i}px;
    }
    .w-#{$i} {
        width: #{$i}px;
    }
}
$percentage:'%';
@for $i from 0 through 100 {
    .circle.per-#{$i} {
        background-image: conic-gradient(var(--brandPrimaryColor) #{$i}#{$percentage}, var(--lightOutlineColor) 0);
    }
    .flex-basis-#{$i} {
        flex-basis: #{$i}#{$percentage};
    }
}