.toggle-material-library-search {
  border-right: 1px solid var(--lightBorderColor);
  padding: calc(var(--quarterPadding)*1.5) calc(var(--quarterPadding)*2);
  display: flex;
  align-items: center;
  gap: var(--halfPadding);
}

.toggle-material-library-search-btn {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}

.toggle-material-library-search-btn:disabled {
  pointer-events: none;
  opacity: .3;
}

.toggle-material-library-search-tag {
  background-color: var(--statusBlueBackgroundColor);
  display: flex;
  align-items: center;
  gap: var(--quarterPadding);
  padding: calc(var(--quarterPadding)/4) var(--quarterPadding);
  border-radius: 8px;
}

.toggle-material-library-search input {
  border: 1px solid var(--lightBorderColor);
  padding: calc(var(--quarterPadding)/4) var(--quarterPadding);
  border-radius: 8px;
  font-size: .8rem;
}

.toggle-material-library-search input:disabled {
  opacity: 0.3;
}