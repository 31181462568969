.toggle-context-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  background-color: var(--pageColor);
  padding: 0 10px;
  box-shadow: 2px 5px 8px rgba(1,1,1,.1);
  border: 1px solid var(--lightBorderColor);
  border-radius: 6px;
  z-index: 8888;
}

.toggle-context-menu.wide {
  width: 300px;
  padding: 5px 15px;
}

.toggle-context-menu h4 {
  margin: 10px 0 0;
  font-size: 1rem;
}

.toggle-context-menu ul {
  margin: 0;
  padding: 0;
}

.toggle-context-menu.wide ul li {
  margin: 15px 0;
}

.toggle-context-menu ul li {
  margin: 10px 0;
  padding: 0;
  text-align: left;
  position: relative;
}

.threedy-lab.dark .toggle-context-menu ul li.border-top {
  border-top: 1px solid var(--darkOutlineColor);
}

.toggle-context-menu ul li.border-top {
  border-top: 1px solid #eee;
  padding: 10px 0 0;
}

.toggle-context-menu ul li span {
  display: inline-block;
  margin: 0 5px 0 0;
}

.threedy-lab.dark .toggle-context-menu ul li button {
  color: #fff;
}

.toggle-context-menu ul li button:not(.primary-btn) {
  padding: 0;
  margin: 0;
  font-size: .9rem;
  font-weight: 400;
  color: var(--textColor);
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
}

.toggle-context-menu ul li button svg {
  width: 14px;
  height: 14px;
}

.toggle-context-menu ul li button svg path {
  fill: var(--lighterTextColor);
}

.toggle-context-menu ul li button:not(.primary-btn):hover {
  color: var(--primaryColor);
}

.toggle-context-menu ul li button:hover svg path {
  fill: var(--primaryColor);
}

.toggle-context-menu-input {
  text-align: center;
  padding: 15px 0 0;
  color: var(--textColor);
}

.toggle-context-menu-input .close-btn {
  color: var(--lightestTextColor) !important;
  font-size: 1.5rem !important;
  margin: 0 10px 15px auto !important;
}

.toggle-context-menu-input h2 {
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.toggle-context-menu-input h2 svg {
  height: 20px;
  width: 20px;
}

.toggle-context-menu-input h2 svg path {
  fill: var(--textColor);
}

.toggle-context-menu-input input {
  width: 80%;
  padding: 9px 12px;
  margin: 30px auto 0;
  color: var(--textColor);
  border: 1px solid var(--lightBorderColor);
}

.toggle-context-menu-input-actions {
  border-top: 1px solid var(--lightBorderColor);
  margin: 35px 0 0;
  display: flex;
}

.toggle-context-menu-input-actions button {
  padding: 14px 10px !important;
  font-size: 13px !important;
  display: block !important;
  flex: initial;
  width: 50%;
  text-align: center !important;
}

.toggle-context-menu-input-actions button:first-child {
  border-right: 1px solid var(--lightBorderColor);
}

.toggle-context-menu .threedy-predictive-search {
  transform: none;
  min-width: 0;
  width: 200px;
  background-color: var(--pageColor);
  padding: 0;
  box-shadow: 2px 5px 8px rgba(1,1,1,.1);
  border: 1px solid var(--lightBorderColor);
  border-radius: 6px;
}

.toggle-context-menu .threedy-predictive-search ul li {
  padding: 2px 10px;
  margin: 2px 0;
}

.toggle-context-menu .threedy-predictive-search h4 {
  margin: 10px 0;
}

.toggle-context-menu .threedy-predictive-search input {
  width: 90%;
  margin: 0 auto 10px;
}