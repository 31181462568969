.toggle-material-image-create {
  color: var(--textColor);
  text-align: center;
  padding: 40px 40px 60px;
  border-top: 1px solid var(--lightBorderColor);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.material-texture-expand {
  padding: 20px 40px;
  height: 100%;
}

.material-texture-expand .step-container {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.material-texture-expand .step-container .toggle-perspective-correction {
  flex: 1;
}

.toggle-material-image-create ul {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-height: none;
}

.toggle-material-image-create ul.mh-220 {
  max-height: 220px;
}

.toggle-material-image-create ul li {
  flex: initial;
  width: 48%;
  border: 1px solid var(--outlineColor);
  border-radius: 6px;
  text-align: center;
  display: block;
  margin: 0;
  color: var(--textColor);
}

.toggle-material-image-create ul li.selected {
  border-color: var(--primaryColor);
  background-color: var(--primaryColor);
}

.toggle-material-image-create ul li.full {
  width: 100%;
}

.toggle-material-image-create ul li.full h5 {
  padding: 10px;
}

.toggle-material-image-create ul li.selected h5 {
  color: #fff;
}

.toggle-material-image-create ul li img {
  width: 80%;
  margin: 0 auto;
  filter: grayscale(1);
}

.toggle-material-image-create ul li h5 {
  margin: 0;
  font-weight: 600;
  text-align: center;
  padding: 0 0 5px;
  color: var(--textColor);
}

.toggle-material-image-create ul li:hover {
  cursor: pointer;
  opacity: 0.8;
}

.toggle-material-image-create .threedy-lab-text-input {
  margin: 20px auto;
}

.toggle-material-image-create label img, .preview-image img {
  width: 120px;
  height: 120px;
  margin: 10px auto;
  display: block;
  border-radius: 10px;
  overflow: hidden;
  padding: 4px;
  object-fit: cover;
}

.toggle-material-image-create label img:hover {
  cursor: pointer;
  border-color: var(--primaryColor);
}

.upload-image-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 70px;
  padding-top: 60px;
  margin-top: 5px;
  border: 1px dashed var(--primaryColor);
  border-radius: 10px;
}

.upload-image-section.dragover {
  border-color: var(--primaryColor);
}

.image-upload-container button {
  background-color: #d9d9d9;
  padding: 3px 6px;
  font-weight: 400;
  border-radius: 6px;
}

.uploaded-image-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-block: 60px;
}

.preview-image .icon-x {
  position: absolute;
  top: 2px;
  right: -6px;
  background-color: #d9d9d9;
  border-radius: 50%;
  padding: 3px;
  color: #000;
}

.preview-image-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.preview-image-details p {
  word-break: break-all;
  font-weight: bold;
  margin-top: 0;
  /* Ellipsis after 2 lines */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.material-image-create-header {
  display: flex;
  align-items: center;
  gap: 15%;
  border-bottom: 1px solid;
  padding-bottom: 10px;
}

.material-image-create-header > span {
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.material-image-create-header .icon-arrow-left {
  transform: translateY(1px);
}

span[disabled] {
  color: #d9d9d9;
  opacity: 0.3;
}

.toggle-material-image-create .threedy-lab-spinner-container.inline svg {
  width: 100%;
  transform: unset;
  left: unset;
}